// Generos
export const generos = [
    {value: 10, label: "Hombre"},
    {value: 20, label: "Mujer"},
    {value: 30, label: "Otro"}
];

// Tipo de usuario
export const USER_ADMIN = 10;
export const USER_PROFESOR = 20;
export const USER_ALUMNO = 30;
export const USER_COLABORADOR = 40;

// Tipos de Eventos
export const CLASE_INDIVIDUAL = 1;
export const CLASE_GRUPAL = 5;
export const CLASE_CANCELADA = 10;
export const CLASE_RECUPERACION = 15;
export const TUTORIA = 20;
export const FESTIVOS = 2; //Se dejo asi por que el backend tenia este valor
// Extras
export const RESERVADO = 30;

export const TIPOS_EVENTOS = [
    { value: CLASE_INDIVIDUAL, label: 'Clase Individual', color: "#366AED", texto: "#FFFFFF"},
    { value: CLASE_CANCELADA, label: 'Clase Cancelada', color: "#686A75", texto: "#FFFFFF" },
    { value: CLASE_GRUPAL, label: 'Clase Grupal', color: "#53A513", texto: "#FFFFFF" },
    { value: CLASE_RECUPERACION, label: 'Recuperacion', color: "#E9206A", texto: "#FFFFFF"},
    { value: TUTORIA, label: 'Tutoria', color: "#2CB2E5", texto: "#FFFFFF"},
    // { value: FESTIVOS, label: 'Festivos', color: "#E97821", texto: "#FFFFFF"},
    // { value: RESERVADO, label: 'Reservado', color: "#BABFCE", texto: "#151A34"},
];

// Estados del evento
export const PENDIENTE = 10;
export const PAGADA = 20;
export const FINALIZADA = 30;
export const CANCELADO_ALUMNO = 40;
export const CANCELADO_ADMIN = 50;
export const CANCELADO_PROFESOR = 60;

export const ESTADOS_EVENTOS = [
    { value: PAGADA, label: 'Clase pendiente', color: "#366AED" },
    { value: FINALIZADA, label: 'Clase finalizada', color: "#E9206A" },
    { value: CANCELADO_ALUMNO, label: 'Clase cancelada por el alumno', color: "#686A75" },
    { value: CANCELADO_ADMIN, label: 'Clase cancelada por un admin', color: "#686A75" },
    { value: CANCELADO_PROFESOR, label: 'Clase cancelada por el profesor', color: "#686A75" },
];

// Dias de la semana
export const DIAS_SEMANA = [
    { value: 'Domingo', label: 'Domingo', id: 0},
    { value: 'Lunes', label: 'Lunes', id: 1},
    { value: 'Martes', label: 'Martes', id: 2},
    { value: 'Miércoles', label: 'Miércoles', id: 3},
    { value: 'Jueves', label: 'Jueves', id: 4},
    { value: 'Viernes', label: 'Viernes', id: 5},
    { value: 'Sábado', label: 'Sábado', id: 6},
];
    // Dias
export const dias = [
    {value: 'Lunes', label: "Lunes"},
    {value: 'Martes', label: "Martes"},
    {value: 'Miércoles', label: "Miércoles"},
    {value: 'Jueves', label: "Jueves"},
    {value: 'Viernes', label: "Viernes"},
    {value: 'Sábado', label: "Sábado"},
    {value: 'Domingo', label: "Domingo"}
];

export const ENLACE  = 5;
export const ARCHIVO = 10;

export const TIPOS_ARCHIVOS = [
    {value: 10, label: 'Archivo'},
    {value: 5, label: 'Enlace'},
]

export const EXTENSIONES = [
    {value: 'xlsx', label: 'excel'},
    {value: 'xlsm', label: 'excel'},
    {value: 'xls', label: 'excel'},
    {value: 'pdf', label: 'pdf'},
    {value: 'pptx', label: 'presentacion'},
    {value: 'pptm', label: 'presentacion'},
    {value: 'ppt', label: 'presentacion'},
    {value: 'odp', label: 'presentacion'},
    {value: 'avi', label: 'video'},
    {value: 'mp4', label: 'video'},
    {value: 'm4v', label: 'video'},
    {value: 'mov', label: 'video'},
    {value: 'mpg', label: 'video'},
    {value: 'mpeg', label: 'video'},
    {value: 'wmv', label: 'video'},
    {value: 'docx', label: 'word'},
    {value: 'docm', label: 'word'},
    {value: 'dotx', label: 'word'},
]

export const EJERCICIO = 10;
export const EXAMEN = 20;

export const TIPOS_FORMS = [
    {value: EJERCICIO, label: 'Ejercicio'},
    {value: EXAMEN, label: 'Examen'},
];

export const NORMAL = 10;
export const OPC_MULT = 20;

export const TIPO_PREGUNTA = [
    {value: NORMAL, label: 'Normal'},
    {value: OPC_MULT, label: 'Opción multiple'},
];

// Tipos de preguntas
export const tipos_preguntas = [
    {value: 10, label: "Respuesta breve"},
    {value: 20, label: "Opción multiple"}
];

// --------------- CUPONES ---------------
// Tipos de cupones
export const CUPON_UNICO = 10;
export const CUPON_MULTIPLE = 20;

// Tipos de formación
export const CLASE_INDIVIDUAL_CUPONES = 10;
export const CLASE_GRUPAL_CUPONES = 20;

// Tipos de descuento
export const DINERO = 10;
export const PORCENTAJE = 20;

export const meses = [
    {value: 0, label: "Enero"},
    {value: 1, label: "Febrero"},
    {value: 2, label: "Marzo"},
    {value: 3, label: "Abril"},
    {value: 4, label: "Mayo"},
    {value: 5, label: "Junio"},
    {value: 6, label: "Julio"},
    {value: 7, label: "Agosto"},
    {value: 8, label: "Septiembre"},
    {value: 9, label: "Octubre"},
    {value: 10, label: "Noviembre"},
    {value: 11, label: "Diciembre"},
];

export const TARJETA = 10
export const TRANSFERENCIA = 20

export const METODOS_PAGO = [
    {value: TARJETA, label: 'Tarjeta'},
    {value: TRANSFERENCIA, label: 'Transferencia'},
];

// Tipo de tarjeta
export const tipo_tarjeta = [
    {value: 10, label: "Visa"},
    {value: 20, label: "Mastercard"}
];
