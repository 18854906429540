import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/biblioteca/archivos';
import BibliotecaArchivos from './BibliotecaArchivos';


const ms2p = (state) => {
    return {
        ...state.archivos,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(BibliotecaArchivos);
