import React, { Component, Fragment } from 'react';
import Header from "Utils/Grid/Header";
import Select from 'react-select';
import classNames from 'classnames';
import { CLASE_INDIVIDUAL, CLASE_GRUPAL,
    CLASE_RECUPERACION, TUTORIA } from '../../../../../utility/constants';
import CardEventos from '../../../Utils/Cards/CardEventos';
import FiltroEventos from '../../../Utils/Calendario/FiltroEventos';
import _ from "lodash";
import moment from 'moment';
import LoadMask from '../../../Utils/LoadMask/LoadMask';

const EVENTOS = [
    { value: CLASE_INDIVIDUAL, label: 'Clase Individual', color: "#366AED", texto: "#FFFFFF"},
    { value: CLASE_GRUPAL, label: 'Clase Grupal', color: "#53A513", texto: "#FFFFFF" },
    { value: CLASE_RECUPERACION, label: 'Recuperacion', color: "#E9206A", texto: "#FFFFFF"},
    { value: TUTORIA, label: 'Tutoria', color: "#2CB2E5", texto: "#FFFFFF"},
]


export default class AgendaAlumno extends Component {

    componentWillMount() {
        const { me } = this.props;
        this.props.obtenerAgenda(me.id);
    };

    changeTipoEvento = (value) => {
        console.log("fdfd ", value)
        const { me } = this.props;
        this.props.changeTipoEvento(value, me.id, true);
    }

    render() {
        const { eventos2, loader } = this.props;
        return (
            <LoadMask loading={loader} blur>
                <div className="d-flex flex-column w-100 px-lg-5">
                    <div className="d-flex flex-column mb-3">
                        <Header
                            filtro1={
                                <FiltroEventos
                                    label="Filtrar por estado"
                                    options={EVENTOS}
                                    value={this.props.tipo_evento}
                                    changeValue={this.changeTipoEvento}
                                />
                            }
                        />
                    </div>
                    <div className="d-flex flex-column">
                        {
                            (eventos2.results) && (eventos2.results.length) ? (
                                eventos2.results.map((item, index) => (
                                    <Fragment key={index}>
                                        <CardEventos
                                            index={index}
                                            item={item}
                                            seleccionar={this.props.openModalDetalle}
                                        />
                                    </Fragment>
                                ))
                            ) : (
                                <div></div>
                            )
                        }
                    </div>
                </div>

            </LoadMask>
        )
    }
}
