import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';

const LOADER = 'LOADER_ALUMNOS_PROFESOR';
const DATA = 'DATA_ALUMNOS_PROFESOR';
const DATA2 = 'DATA2_ALUMNOS_PROFESOR';
const ITEM_DATA = 'ITEM_ALUMNOS_PROFESOR';
const PAGE = 'PAGE_ALUMNOS_PROFESOR';
const PAGE2 = 'PAGE2_ALUMNOS_PROFESOR';
const ORDERING = 'ORDERING_ALUMNOS_PROFESOR';
const SEARCH = 'SEARCH_ALUMNOS_PROFESOR';
const SEARCH2 = 'SEARCH2_ALUMNOS_PROFESOR';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = (type, page) => ({
    type,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = (type, search) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().alumnosProfesor;
    const params = { page };
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('user/estudiantes_profesor', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarForms = (page = 1, idAlumno) => (dispatch, getStore) => {
    const me = getStore().login.me;
    const resource = getStore().alumnosProfesor;
    const params = { page, alumno: idAlumno, profesor: me.id};
    params.search = resource.search2;
    dispatch(setLoader(true));
    api.get(`formularios_asignados`, params).then((response) => {
        dispatch(setData(DATA2, response));
        dispatch(setPage(PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`user/${id}`).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const asignarForm = (data, idAula) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('formularios_asignados', data).then(() => {
        NotificationManager.success('Archivo Asignado', 'Éxito', 3000);
        dispatch(push(`/alumnos/${idAula}/`));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarForm = (id, idAlumno) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`formularios_asignados/${id}`).then(() => {
        dispatch(listarForms(1, idAlumno));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(SEARCH, search));
    dispatch(listar());
};

const searchChange2 = (search, idAlumno) => (dispatch) => {
    dispatch(setSearch(SEARCH2, search));
    dispatch(listarForms(1, idAlumno));
};

export const actions = {
    listar,
    listarForms,
    leer,
    asignarForm,
    eliminarForm,
    searchChange,
    searchChange2
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH2]: (state, { search }) => {
        return {
            ...state,
            search2: search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    page2: 1,
    ordering: '',
    search: '',
    search2: '',
};

export default handleActions(reducers, initialState);
