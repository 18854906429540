import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import { OPC_MULT } from '../../../utility/constants';

const LOADER = 'LOADER_FORMS_PROFESOR';
const DATA = 'DATA_FORMS_PROFESOR';
const DATA2 = 'DATA2_FORMS_PROFESOR';
const ITEM = 'ITEM_FORMS_PROFESOR';
const PAGE = 'PAGE_FORMS_PROFESOR';
const PAGE2 = 'PAGE2_FORMS_PROFESOR';
const ORDERING = 'ORDERING_FORMS_PROFESOR';
const SEARCH = 'SEARCH_FORMS_PROFESOR';
const SEARCH2 = 'SEARCH2_FORMS_PROFESOR';
const AULA = 'AULA_FORMS_PROFESOR';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM,
    item,
});

const setPage = (type, page) => ({
    type,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = (type, search) => ({
    type,
    search,
});


// ------------------------------------
// Actions
// ------------------------------------

const listarPendientes = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().formsProfesor;
    const params = { page, enviado: true, calificado: false };
    params.search = resource.search;
    if (resource.aula) {
        params.formulario__aula = resource.aula.id;
    }
    dispatch(setLoader(true));
    api.get('form_respuesta', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarCalificados = (page = 1, idAlumno) => (dispatch, getStore) => {
    const resource = getStore().formsProfesor;
    const params = { page, calificado: true };
    params.search = resource.search2;
    if (resource.aula) {
        params.formulario__aula = resource.aula.id;
    }
    dispatch(setLoader(true));
    api.get('form_respuesta', params).then((response) => {
        dispatch(setData(DATA2, response));
        dispatch(setPage(PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, calificar=true) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`form_respuesta/${id}`).then((response) => {
        dispatch(setItem(response));

        const blocksFromHtml = htmlToDraft(response.formulario.formulario.notas ? response.formulario.formulario.notas : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        const data = {
            notas_form: EditorState.createWithContent(contentState),
            respuestas: response.respuestas,
        }

        if (calificar) {
            data.respuestas = response.respuestas.map( item => {
                item.punteo = 0;
                if (item.pregunta.tipo == OPC_MULT) {
                    const op_elegida = _.find(item.pregunta.respuestas, {id: item.respuesta_multiple});
                    if (op_elegida) {
                        item.punteo = op_elegida.respuesta_correcta ? item.pregunta.puntaje : 0;
                    }
                }
                return item;
            });
        } else {
            const blocksFromHtml2 = htmlToDraft(response.notas ? response.notas : "");
            let { contentBlocks, entityMap } = blocksFromHtml2;
            const contentState2 = ContentState.createFromBlockArray(contentBlocks, entityMap);
            data.notas = EditorState.createWithContent(contentState2);
        }

        dispatch(initializeForm('CalificarForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const calificarForm = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    const draw = data.notas;
    const body = {...data};
    if (draw) {
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        console.log("editor ", html);
        body.notas = html;
    } else {
        body.notas = null;
    }
    api.put(`form_respuesta/${id}/calificar_form`, body).then(() => {
        NotificationManager.success('Formulario calificado', 'Éxito', 3000);
        dispatch(push(`/forms_profesor`));
    }).catch((error) => {
        let mensaje = "Error al calificar el formulario";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const agregarNotaForm = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    const draw = data.notas;
    const body = {...data};
    if (draw) {
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        body.notas = html;
    } else {
        body.notas = null;
    }
    api.put(`form_respuesta/${id}/nota_form`, body).then(() => {
        NotificationManager.success('Nota agregada', 'Éxito', 3000);
        dispatch(push(`/forms_profesor`));
    }).catch((error) => {
        let mensaje = "Error al agregar la nota";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(SEARCH, search));
    dispatch(listarPendientes());
};

const searchChange2 = search => (dispatch) => {
    dispatch(setSearch(SEARCH2, search));
    dispatch(listarCalificados());
};

const changeAula = (value, tipo) => (dispatch) => {
    dispatch(setData(AULA, value));
    if (tipo == 1) {
        dispatch(listarPendientes());
    } else {
        dispatch(listarCalificados());
    }
};

export const actions = {
    listarPendientes,
    listarCalificados,
    leer,
    calificarForm,
    agregarNotaForm,
    searchChange,
    searchChange2,
    changeAula
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH2]: (state, { search }) => {
        return {
            ...state,
            search2: search,
        };
    },
    [AULA]: (state, { data }) => {
        return {
            ...state,
            aula: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    page2: 1,
    ordering: '',
    search: '',
    search2: '',
    aula: null,
};

export default handleActions(reducers, initialState);

