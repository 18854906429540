import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import {renderTextArea, renderField, renderNumber, SelectField, renderMonthPicker, } from '../../Utils/renderField/renderField';
import {RenderCurrency } from '../../Utils/renderField/renderReadField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { tipo_tarjeta } from "../../../../utility/constants";

let PasoPagoForm = props => {
    const { handleSubmit, curso } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 d-flex flex-wrap justify-content-center align-items-center">
                    <h1 className="mr-2">{`Curso: ${curso ? curso.titulo : ''}`}</h1>
                </div>
                <div className="col-12 d-flex flex-wrap justify-content-center align-items-center">
                    <h2 className="mr-2">Pago del curso:</h2>
                    <RenderCurrency value={curso ? curso.precio : 0} className="h2 verde" />
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="numero_tarjeta" className="m-0">Numero de tarjeta *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="numero_tarjeta"
                                component={renderNumber}
                                type="text"
                                className="form-control"
                                numberFormat="#### #### #### ####"
                                placeholder="#### #### #### ####"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="metodo_pago" className="m-0">Nombre de la tarjeta *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="metodo_pago"
                                component={SelectField}
                                options={tipo_tarjeta}
                                placeholder="Seleccione un tipo de tarjeta..."
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mes_anio" className="m-0">Mes/año *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="mes_anio" component={renderMonthPicker} type="text" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="codigo_seguridad" className="m-0">Código de seguridad *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="codigo_seguridad"
                                component={renderNumber}
                                type="number"
                                className="form-control"
                                numberFormat="###"
                                placeholder="CVC"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="buttons-box mt-3">
                    <div className="col-md-8 col-12 row">
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <Link className="btn btn-secondary btn-block" to="/login">Cancelar</Link>
                        </div>
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <button type="submit" className="btn btn-primary btn-block">Pagar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

PasoPagoForm = reduxForm({
    form: 'PasoPagoForm',
    asyncBlurFields: [],
})(PasoPagoForm);

export default PasoPagoForm;
