import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { actions } from '../../../../../redux/modules/alumnos';
import CalendarioAlumno from './Calendario';


const ms2p = (state) => {
  return {
    me: state.login.me,
    ...state.alumnos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(withRouter(CalendarioAlumno));
