import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/profesor/horasTrabajadas';
import ReporteHorasTrabajadas from './ReporteHorasTrabajadas';


const ms2p = (state) => {
    return {
        ...state.horasTrabajadas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteHorasTrabajadas);
