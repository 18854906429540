import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";

const LOADER = 'LOADER_CONFIG_COMERCIAL';
const ITEM_DATA = 'ITEM_CONFIG_COMERCIAL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});


// ------------------------------------
// Actions
// ------------------------------------


const leer = () => (dispatch, getStore) => {
    const me = getStore().login.me;
    dispatch(setLoader(true));
    api.get('conf_comercial').then((response) => {
        const mensaje = _.cloneDeep(response);
        mensaje.bienvenida_titulo = mensaje.bienvenida_titulo.replace("{{Nombre}}", me.nombres);
        console.log("mensaje ", mensaje)
        mensaje.bienvenida_titulo = mensaje.bienvenida_titulo.replace("{{nombre}}", me.nombres);
        console.log("mensaje ", mensaje)
        dispatch(setItem(mensaje));
        // dispatch(initializeForm('ConfigComercialForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('conf_comercial', data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        // dispatch(push('/CONFIG_COMERCIAL'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    leer,
    crear,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
};

export const initialState = {
    loader: false,
    item: {},
};

export default handleActions(reducers, initialState);
