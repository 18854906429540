import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import Card from "Utils/Cards/Card";
import moment from 'moment';


export default class CanjeadosGrid extends Component{

    componentWillMount() {
        const { listarCanjeados } = this.props;
        listarCanjeados();
    }

    render() {
        const { listarCanjeados: onPageChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4 px-0">
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='5%'
                            dataFormat={cell => ""}
                        />
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="encabezado_cupon"
                            dataFormat={cell => cell ? cell.colaborador ? `${cell.colaborador.nombres} ${cell.colaborador.apellidos}` : '----' : '----'}
                        >
                            COLABORADOR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="encabezado_cupon"
                            dataFormat={cell => cell ? cell.recurrente ? 'Recurrente' : 'No recurrente' : ''}
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="cupon"
                            dataFormat={cell => cell ? cell.cupon : ''}
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
