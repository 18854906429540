import React, {Fragment} from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField } from 'Utils/renderField';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {Link} from "react-router-dom";

const ChangeForm = (props) => {
    const { handleSubmit } = props;
    return (
        <CardEmpty>
            <form name="loginForm" className="form-validate col-12 px-0 my-3" onSubmit={handleSubmit}>
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <img src={require('assets/img/logo.png')} alt="Logo" className="img-fluid logo-reset"/>
                </div>
                <div className="col-12 d-flex flex-column align-items-center">
                    <h3 className="mt-4 mb-3 text-center gris-oscuro bold">RECUPERAR CONTRASEÑA</h3>
                    <div className="col-lg-10 col-12 text-center">
                        <p>Ingresa tu correo electronico, se te enviara un correo para poder restablecer tu contraseña</p>
                    </div>
                    <div className="form-group has-feedback col-lg-10 col-12 px-0">
                        <label htmlFor="password">Ingrese correo</label>
                        <Field name="email" component={renderField} type="email" className="form-control" />
                    </div>
                    <div className="col-12 d-flex flex-wrap justify-content-center align-items-center mt-3 mb-3">
                        <div className="col-md-6 col-12">
                            <Link className="btn btn-secondary btn-block" to="/login">Cancelar</Link>
                        </div>
                        <div className="col-md-6 col-12 mt-md-0 mt-3">
                            <button type="submit" className="btn btn-primary btn-block">Recuperar</button>
                        </div>
                    </div>
                </div>
            </form>
        </CardEmpty>
    );
};

export default reduxForm({
    form: 'sendPass',
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('Este campo es requerido'),
        });
    },
})(ChangeForm);
