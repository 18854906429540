import React, { Component, Fragment } from 'react';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Select, { Async } from 'react-select';
import classNames from 'classnames';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import MonthPicker from '../../../Utils/MonthPicker';
import { api } from 'api';

import _ from 'lodash';
import moment from 'moment';


export default class PendientesGrid extends Component {

    componentWillMount() {
        this.props.listarPendientes();
    }

    getAulas = search => {
        const { me } = this.props;
        let params = { search, profesor: me.id }
        return api.get("aula", params).then((data) => {
            if (data) {
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
        });
    }

    render() {
        const { listarPendientes } = this.props; //Funciones
        const { data, loader, page, aula } = this.props; //Constantes
        return (
            <div className="mt-4 px-1">
                <Header
                    {...this.props}
                    filtro1={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={aula}
                            isSearchable={true}
                            loadOptions={this.getAulas}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeAula(e, 1)}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={true}
                            placeholder="Filtrar por curso ..."
                        />
                    }
                    // filtro2={
                    //     <MonthPicker onChange={e => console.log(e)} placeholder="Fecha inicio" />
                    // }
                />
                <Card>
                    <Grid
                        hover striped
                        data={data}
                        loading={loader}
                        onPageChange={listarPendientes}
                        // onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='10%'
                            dataFormat={standardActions({ calificar: '/forms_profesor' })}
                        />
                        <TableHeaderColumn
                            dataField="nombre_form"
                        >
                            EJERCICIO / EXÁMEN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_form"
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="aula"
                        >
                            CURSO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="alumno"
                            dataFormat={cell => `${cell.id} / ${cell.nombres}`}
                        >
                            ALUMNO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_entrega"
                            dataFormat={cell => moment(cell).format("DD/MM/YYYY HH:mm")}
                        >
                            FECHA DE ENVIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="formulario"
                            dataFormat={cell => {
                                let fecha = null;
                                if (cell.fecha_hora_examen) {
                                    fecha = moment(cell.fecha_hora_examen).add(cell.tiempo_maximo, 'h');
                                } else {
                                    fecha = moment(cell.fecha_hora_entrega)
                                }

                                return fecha.format("DD/MM/YYYY HH:mm")
                            }}
                        >
                            FECHA DE ENTREGA
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
