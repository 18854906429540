import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import EditarPerfilForm from './EditarForm';

export default class AlumnoCrear extends Component {
    state = {
        editar: false,
        password: false,
        fotografia: null,
    };

    componentWillMount(){
        this.props.leer();
    }

    actualizar = (data) => {
        if (data.password)
            data.new_password = data.password
        if (this.state.fotografia != null) {
            this.props.editar(data, [{"file": this.state.fotografia, "name": "avatar"}])
        } else {
            console.log("no hay foto")
            this.props.editar(data, [])
        }
    };

    changeVerPassword = () => {
        console.log("dfafdf")
        this.setState({password: !this.state.password});
    };

    setFotografia = (fotografia) => {
        this.setState({fotografia});
    };

    render() {
        const { item, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader} blur>
                        <EditarPerfilForm
                            mostrar_pass={this.state.password}
                            changeVerPassword={this.changeVerPassword}
                            onSubmit={this.actualizar}
                            setFotografia={this.setFotografia}
                            item={item}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
