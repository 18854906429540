import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {Login, Profile, Registro} from './common/components/LoginRegister';
import sendRecoverPassword from './common/components/LoginRegister/RecoverPassword/SendRecovery';
import PassRecover from './common/components/LoginRegister/RecoverPassword/ChangePassword/';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';

import { Home } from './common/components/App/Home';
import { TemarioClase } from './common/components/App/CalendarioProfesor';
import { CursosProfesorGrid, DetalleCurso, FormularioAgregar, RecuperarClase, RecuperacionesProfe, ReporteCreditosAlumno, PuntuacionesClases } from './common/components/App/CursosProfesor';
import { AlumnosProfesorGrid, FormsAlumnosGrid, AsignarFormAlumno } from './common/components/App/AlumnosProfesor';
import { FormsAsignadosGrid, PrecargarExamen, ResolverForm, ResolverEjercicio, VerSolucionForm } from './common/components/App/FormulariosAlumno';
import { CursosAlumno, DetalleCursoAlumno, DetalleClaseAlumno, RecuperacionesAlumno } from './common/components/App/CursosAlumno';
import { FormsProfesorListar, CalificarFormulario, DetalleFormulario } from './common/components/App/FormulariosProfesor';
import { Perfil, MiAvance, EditarPerfil, NotificacionesGrid, FacturacionAlumno, ListarTarjetas } from './common/components/App/Perfil';
import { DetalleHorasTrabajadas, ReporteHorasTrabajadas } from './common/components/App/HorasTrabajadas';
// Area de colaboradores
import { ListarCupones, ReporteCupones, VerCupones } from './common/components/App/Colaboradores';
// BIBLIOTECA
import { ListarNiveles } from './common/components/App/Niveles';
import { BibliotecaArchivos, SubirArchivos } from './common/components/App/Archivos';
import { FormulariosList, FormularioCrear } from './common/components/App/EjerciciosExamenes';
// COMUNICADOS PROFESOR
import { ComunicadoCrear, ComunicadosList, VerComunicado} from './common/components/App/ComunicadosProfesor';
// COMUNICADOS PROFESOR
import { ComunicadosAlumnosList, VerComunicadoAlumno} from './common/components/App/ComunicadosAlumno';
// PASARELA PAGO
import { PasarelaPago, VistaKO, VistaOK } from './common/components/App/PagoCurso';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
require('../style/index.css');

try {require('../assets/firebase-messaging-sw')}catch (e) {
    console.error('Firebase messaging', e)
}


// ICONOS
const inicio_azul = require("assets/img/icons/inicio_azul.png");
const cursos_azul = require("assets/img/icons/cursos_azul.png");
const colaborador_azul = require("assets/img/icons/colaborador_azul.png");
const biblioteca_azul = require("assets/img/icons/biblioteca_azul.png");
const perfil_azul = require("assets/img/icons/perfil_azul.png");
const mensaje_azul = require("assets/img/icons/mensaje_azul.png");
const reportes_azul = require("assets/img/icons/reportes_azul.png");

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/:id" component={Login} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/registro/:id" component={Registro} />
                <Route exact path="/recuperar_pass" component={sendRecoverPassword}/>
                <Route exact path="/cambiar_pass/:token" component={PassRecover}/>
                <ProtectedRoute exact path="/" component={Home} title={"Inicio"} icon={inicio_azul}/>

                {/* PASARELA DE PAGO */}
                <ProtectedRoute exact path="/pasarela" component={PasarelaPago} title={"Pasarela de pago"} icon={inicio_azul}/>
                <ProtectedRoute exact path="/pasarela/:id" component={PasarelaPago} title={"Pasarela de pago"} icon={inicio_azul}/>
                <ProtectedRoute exact path="/pago_exitoso" component={VistaOK} title={"Pasarela de pago / "} subtitle={"Pago exitoso"} icon={inicio_azul}/>
                <ProtectedRoute exact path="/pago_fallido" component={VistaKO} title={"Pasarela de pago / "} subtitle={"Pago fallido"} icon={inicio_azul}/>
                <ProtectedRoute exact path="/tarjeta_exitosa" component={VistaOK} title={"Tarjetas de crédito / "} subtitle={"Tarjeta exitosa"} icon={inicio_azul}/>
                <ProtectedRoute exact path="/tarjeta_fallida" component={VistaKO} title={"Tarjetas de crédito / "} subtitle={"Tarjeta fallida"} icon={inicio_azul}/>

                {/* PROFESOR */}
                <ProtectedRoute exact path="/temario/:id" component={TemarioClase} title={"Inicio"} />
                <ProtectedRoute exact path="/temario/:id/editar" component={TemarioClase} title={"Inicio"} />
                <ProtectedRoute exact path="/cursos" component={CursosProfesorGrid} title={"Cursos"} />
                <ProtectedRoute exact path="/cursos/:id" component={DetalleCurso} title={"Cursos /"} subtitle={"Detalle"} />
                {/* <ProtectedRoute exact path="/cursos/:id/archivo_asignar" component={ArchivoAgregar} title={"Cursos /"} subtitle={"Detalle / Asignar archivo"} /> */}
                <ProtectedRoute exact path="/cursos/:id/formulario_asignar" component={FormularioAgregar} title={"Cursos /"} subtitle={"Detalle / Asignar ejercicio o exámen"} />
                <ProtectedRoute exact path="/cursos/:id/recuperar_clase" component={RecuperarClase} title={"Cursos /"} subtitle={"Detalle / Recuperar clase"} />
                {/* Alumnos asignados del profe */}
                <ProtectedRoute exact path="/alumnos" component={AlumnosProfesorGrid} title={"Alumnos asignados "} icon={cursos_azul}/>
                <ProtectedRoute exact path="/alumnos/:id" component={FormsAlumnosGrid} title={"Alumnos asignados / "} subtitle={"Detalle"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/alumnos/:id/formulario_asignar" component={AsignarFormAlumno} title={"Alumnos asignados / "} subtitle={"Detalle / Asignar ejercicio o exámen"} icon={cursos_azul}/>
                {/* FORMULARIOS PROFESOR */}
                <ProtectedRoute exact path="/forms_profesor" component={FormsProfesorListar} title={"Ejercicios y exámenes "} icon={cursos_azul}/>
                <ProtectedRoute exact path="/forms_profesor/:id" component={DetalleFormulario} title={"Ejercicios y exámenes / "} subtitle={"Detalle"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/forms_profesor/:id/calificar" component={CalificarFormulario} title={"Ejercicios y exámenes / "} subtitle={"Calificar"} icon={cursos_azul}/>

                {/* REPORTES  */}
                <ProtectedRoute exact path="/reporte/recuperaciones_profe" component={RecuperacionesProfe} title={"Reportes / "} subtitle={"Tiempo de recuperación"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/avance_alumnos" component={ReporteCreditosAlumno} title={"Reportes / "} subtitle={"Avance de alumnos"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/puntuaciones_clases" component={PuntuacionesClases} title={"Reportes / "} subtitle={"Puntuación de alumnos"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/horas_trabajadas" component={ReporteHorasTrabajadas} title={"Reportes / "} subtitle={"Horas trabajadas"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/reporte/horas_trabajadas/:fecha" component={DetalleHorasTrabajadas} title={"Reportes / "} subtitle={"Horas trabajadas / Detalle"} icon={reportes_azul}/>
                <ProtectedRoute exact path="/notificaciones" component={NotificacionesGrid} title={"Notificaciones"} icon={reportes_azul}/>

                {/* ALUMNO */}
                <ProtectedRoute exact path="/apuntes" component={CursosAlumno} title={"Apuntes"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/apuntes/:id" component={DetalleCursoAlumno} title={"Apuntes / "} subtitle={"Detalle"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/apuntes/:id/clase/:id_clase" component={DetalleClaseAlumno} title={"Apuntes / "} subtitle={"Detalle"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/recuperaciones_alumno" component={RecuperacionesAlumno} title={"Tiempo de recuperación"} icon={cursos_azul}/>

                <ProtectedRoute exact path="/formularios" component={FormsAsignadosGrid} title={"Ejercicios y exámenes"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/formulario/:id/" component={VerSolucionForm} title={"Ejercicios y exámenes / "} subtitle={"Detalle"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/examen/:id" component={PrecargarExamen} title={"Exámenes"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/examen/:id/resolver" component={ResolverForm} title={"Exámenes"} icon={cursos_azul}/>
                <ProtectedRoute exact path="/ejercicio/:id/resolver" component={ResolverEjercicio} title={"Ejercicios"} icon={cursos_azul}/>

                {/* BIBLIOTECA */}
                <ProtectedRoute exact path="/niveles" component={ListarNiveles} title={"Niveles"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/biblioteca_archivos/:id" component={BibliotecaArchivos} title={"Gestión de archivos"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/biblioteca_archivos/:id/crear" component={SubirArchivos} title={"Gestión de archivos / "} subtitle={"Nuevo"} icon={biblioteca_azul}/>
                {/* EJERCICIOS Y EXAMENES */}
                <ProtectedRoute exact path="/biblioteca_formularios" component={FormulariosList} title={"Gestión de ejercicios y exámenes"} subtitle={""} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/biblioteca_formulario/crear" component={FormularioCrear} title={"Gestión de ejercicios y exámenes / "} subtitle={"Nuevo"} icon={biblioteca_azul}/>
                <ProtectedRoute exact path="/biblioteca_formulario/:id/editar" component={FormularioCrear} title={"Gestión de ejercicios y exámenes / "} subtitle={"Editar"} icon={biblioteca_azul}/>


                {/* PERFIL */}
                <ProtectedRoute exact path="/perfil" component={Perfil} title={"Mi perfil"} icon={perfil_azul}/>
                <ProtectedRoute exact path="/perfil/editar" component={EditarPerfil} title={"Mi perfil / "} subtitle={"Editar"} icon={perfil_azul}/>
                <ProtectedRoute exact path="/mi_avance" component={MiAvance} title={"Mi avance"} icon={perfil_azul}/>
                <ProtectedRoute exact path="/mis_facturas" component={FacturacionAlumno} title={"Facturación"} icon={perfil_azul}/>
                <ProtectedRoute exact path="/mis_tarjetas" component={ListarTarjetas} title={"Tarjetas de crédito"} icon={perfil_azul}/>


                {/* COLABORADORES */}
                <ProtectedRoute exact path="/colaborador/cupones" component={ListarCupones} title={"Cupones"} icon={colaborador_azul}/>
                <ProtectedRoute exact path="/colaborador/cupon/:id" component={VerCupones} title={"Cupones / "} subtitle={"Detalle"} icon={colaborador_azul}/>
                <ProtectedRoute exact path="/colaborador/reporte_codigos" component={ReporteCupones} title={"Cupones / "} subtitle={"Códigos canjeados"} icon={colaborador_azul}/>

                {/* COMUNICADOS PROFESOR */}
                <ProtectedRoute exact path="/comunicados_profesor" component={ComunicadosList} title={"Comunicados"} icon={mensaje_azul}/>
                <ProtectedRoute exact path="/comunicado_profesor/crear" component={ComunicadoCrear} title={"Comunicados / "} subtitle={" Nuevo"} icon={mensaje_azul}/>
                <ProtectedRoute exact path="/comunicado_profesor/:id/editar" component={ComunicadoCrear} title={"Comunicados /"} subtitle={" Editar"} icon={mensaje_azul}/>
                <ProtectedRoute exact path="/comunicado_profesor/:id" component={VerComunicado} title={"Comunicados / "} subtitle={"Detalle"} icon={mensaje_azul}/>

                {/* COMUNICADOS ALUMNO */}
                <ProtectedRoute exact path="/comunicados_alumno" component={ComunicadosAlumnosList} title={"Comunicados recibidos"} icon={mensaje_azul}/>
                <ProtectedRoute exact path="/comunicado_alumno/:id" component={VerComunicadoAlumno} title={"Comunicados recibidos / "} subtitle={"Detalle"} icon={mensaje_azul}/>

                <ProtectedRoute exact path="/page2" component={Examples} title={"Basic Components"}/>
                <ProtectedRoute exact path="/user-profile" component={Profile} title={"Profile"} />
                <ProtectedRoute exact path="/grids" component={Grids} title={"Grids"}/>
                <ProtectedRoute exact path="/notifications" component={Notificaciones} title={"Notificaciones"}/>
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} title={"Tabs"}/>
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
