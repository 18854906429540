import React, { Component } from "react";
import NotificationSystem from 'react-notification-system'
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe, crearComentario, inscribirme, newToken } from "./redux/modules/cuenta/login";
import queryString from 'query-string'
import _ from 'lodash';
// maquetado base
import SiderBar from './common/components/layout/Sidebar/SideBar';

import Navbar from "./common/components/layout/Navbar/Navbar";
import NotificacionPermanente from "./common/components/layout/NotificacionPermanente";
import ComentarioModal from "./common/components/Utils/Modales/ComentarioModal"
import InscripcionCursoModal from "./common/components/Utils/Modales/InscripcionCursoModal"
import { VerifyLogin } from "./common/components/layout";
import { USER_PROFESOR, USER_ALUMNO } from "./utility/constants";
import Linkify from 'react-linkify';

import { messaging } from "./utility/init-fcm";

class PrivateRouteBase extends Component {
    notificationSystem = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
            openModal: false,
        };
    }

    async componentDidMount() {
        console.log('props ', this.props)
        const value = queryString.parse(this.props.location.search);
        if (value.cupon) {
            localStorage.setItem('cupon', value.cupon);
        }
        if (value.id_curso) {
            localStorage.setItem('curso', value.id_curso);
        }
        if (value.tipo_curso) {
            localStorage.setItem('tipo_curso', value.tipo_curso);
        }

        // FCM
        messaging.requestPermission()
            .then(() => {
                this.pushToken(messaging);
            })
            .catch(function (err) {
                console.log("Error al obtener el token.", err);
            });
        navigator.serviceWorker.addEventListener("message", (message) => {
            this.handleNotificacion(message);
        });
    }

    handleNotificacion = (message) => {
        console.log('message ', message);
        const { data: { 'firebase-messaging-msg-data': {notification}} } = message;
        // event.preventDefault();
        const notificacion = this.notificationSystem.current;
        notificacion.addNotification({
            children: (
                <div
                    className="h-100 w-100 d-flex"
                    onClick={() => notification.click_action ? this.props.history.push(`${notification.click_action}`) : null}
                >
                    <div className="w-25 h-100 pr-3">
                    </div>
                    <div className="w-75 h-100 noti-min-heigth notification-body">
                        <p className="title mb-2">{notification.title}</p>
                        <p className='m-0'><Linkify>{notification.body}</Linkify></p>
                    </div>
                </div>
            ),
            level: 'info',
            position: 'tr',
            dismissible: true,
            autoDismiss: 0,
        });
    }

    pushToken = async (messaging) => {
        const token = await messaging.getToken();
        this.props.newToken(token);
    }

    navToggle = () => {
        this.setState({ toggleOpen: !this.state.toggleOpen });
    };

    openModal = () => {
        this.setState({ openModal: true })
    };

    closeModal = () => {
        this.setState({ openModal: false })
    };

    isAuthenticated = () => {
        const token = sessionStorage.getItem("token");
        const { getMe, login: { me } } = this.props;
        if (!!token && !!me.email) {
            return true;
        } else if (token) {
            getMe();
            return "Verifying"
        }
        return false;
    };

    isPaidUser = () => {
        const { login: { me } } = this.props;
        if (!!me && !me.pago_pendiente) {
            return true;
        } else {
            return false;
        }
    };

    alumnoSinComentario = () => {
        const { login: { me } } = this.props;
        if (!!me && me.clase_a_calificar && !_.isEmpty(me.clase_a_calificar)
        && me.clase_a_calificar.aula && me.clase_a_calificar.aula.id && me.clase_a_calificar.aula.id >0) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { component: Component, logOut, crearComentario, title: titulo, subtitle: subtitulo, icon: icono, login: { me }, ...rest } = this.props;
        const { computedMatch } = this.props;
        const isAuthenticated = this.isAuthenticated();
        const isPaidUser = this.isPaidUser();
        const alumnoSinComentario = this.alumnoSinComentario();
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        (isAuthenticated === true) ? (<div>
                            <SiderBar
                                toggleOpen={this.state.toggleOpen}
                                navToggle={this.navToggle}
                                me={me}
                                logOut={logOut}
                            />
                            <NotificationSystem ref={this.notificationSystem} />
                            <main className="main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2">
                                <Navbar navToggle={this.navToggle} logOut={logOut} user={me} title={titulo} subtitle={subtitulo} icon={icono} />
                                {alumnoSinComentario === true && (
                                    <ComentarioModal
                                        onClose={!alumnoSinComentario}
                                        open={alumnoSinComentario}
                                        item={me}
                                        loader={this.props.loader}
                                        onSubmit={crearComentario}
                                    />
                                )}

                                {(computedMatch) && (computedMatch.path !== '/pasarela' && computedMatch.path !== '/pasarela/:id') && (
                                    isPaidUser === false && me.tipo_usuario == USER_ALUMNO && (
                                        <NotificacionPermanente user={me} />
                                    )
                                )}
                                <div className="main-content-container px-3 container-fluid">
                                    <Component {...props} />
                                </div>
                                {/* <Footer /> */}
                            </main>
                        </div>) : (
                                <VerifyLogin />
                            )
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location }
                                }}
                            />
                        )
                }
            />
        );
    }
}

const mstp = state => ({ ...state });

const mdtp = { logOut, getMe, crearComentario, inscribirme, newToken };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(withRouter(PrivateRouteBase));

export default ProtectedRoute;

