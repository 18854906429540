import React, {Component, Fragment} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField"
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default class Pendientes extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    onPageChange = (page) => {
        this.props.listar(page);
    };

    render() {
        //Funciones
        const { listar:
            onPageChange,
            onSortChange,
            eliminar,
            searchChange,
            search,
            filtroMesInicioEnviados,
            filtroMesFinEnviados,
        } = this.props;

        //Constantes
        const {
            data,
            loader,
            page,
            mes_inicio_filtro_enviados,
            mes_fin_filtro_enviados,
        } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    to="comunicado_profesor/crear"
                    textBtn="Nuevo"
                    {...this.props}
                    searchChange={searchChange}
                    search={search}
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicio"
                            onChange={filtroMesInicioEnviados}
                            selected={mes_inicio_filtro_enviados}
                        />
                    }
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha fin"
                            onChange={filtroMesFinEnviados}
                            selected={mes_fin_filtro_enviados}
                        />
                    }
                />
                <Card>
                    <Grid hover striped
                        data={data}
                        loading={loader}
                        onPageChange={this.onPageChange}
                        onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ ver: "comunicado_profesor"})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('DD/MM/YYYY') : ""}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            TITULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creador"
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.nombres ? `${cell.nombres}` : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.tipo_usuario_text ? cell.tipo_usuario_text : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            USUARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_destinatario_text"
                            dataSort
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell ? cell : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{row.tipo_mensaje ? row.tipo_mensaje : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            DESTINO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="aprobado"
                            dataSort
                            dataFormat={(cell) => cell === true ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Aprobado</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-rosa mr-1"></div>
                                    <span className="rosa ml-1">Pendiente</span>
                                </div>
                            )}
                        >
                            ESTADO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
