import React, {Component} from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import ClasesGrid from './ClasesGrid';
import AlumnosGrid from './AlumnosGrid';
// import ArchivosGrid from './ArchivosGrid';
import FormsGrid from './FormsGrid';


export default class DetalleCurso extends Component{

    componentWillMount() {
        const id = this.props.match.params.id;
        this.props.leer(id)
    }

    render() {
        const { item } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <h1 className="azul-claro bold">{item.nombre}</h1>
                <Tabs
                    defaultActiveKey="CALENDARIO"
                    tabBarPosition="top"
                    onChange={this.callback}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="CALENDARIO" key="CALENDARIO">
                        <ClasesGrid {...this.props} />
                    </TabPane>
                    <TabPane tab="ALUMNOS" key="ALUMNOS">
                        <AlumnosGrid {...this.props} />
                    </TabPane>
                    {/* <TabPane tab="ARCHIVOS" key="ARCHIVOS">
                        <ArchivosGrid {...this.props} />
                    </TabPane> */}
                    <TabPane tab="EJERCICIOS Y EXÁMENES" key="EJERCICIOS Y EXÁMENES">
                        <FormsGrid {...this.props} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
