import { api } from "api";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchInput from './SearchInput';
import classNames from "classnames";
import Select from "react-select";
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CardEmpty from "Utils/Cards/CardEmpty";

export default class Header extends Component {
    render() {
        const { search, to, textBtn, searchChange, placeholder} = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 row col-12 mb-3 px-1">
                    <div className={`${to ? 'col-md-4' : 'col-md-0'} col-6 px-0 px-md-4`}>
                        {(to !== undefined) && (
                            <Link to={to} className="btn btn-primary mb-3">{textBtn}</Link>
                        )}
                    </div>
                    <div className={`${to ? 'col-md-4' : 'col-md-8'} col-12 d-flex flex-row-reverse flex-md-row px-0 px-md-2 mb-1 mb-md-0`}>
                        {this.props.filtro3 && (
                            <div className="d-flex flex-column flex-1 mx-lg-1">
                                {this.props.filtro3}
                            </div>
                        )}
                        <div className="d-flex flex-column flex-1 mx-1">
                            {this.props.filtro2 && this.props.filtro2}
                        </div>
                        <div className="d-flex flex-column flex-1 mx-1">
                            {this.props.filtro1 && this.props.filtro1}
                        </div>
                    </div>
                    <div className="col-md-4 col-12 px-0 pl-md-4 mt-2 mt-md-0">
                        {(search !== undefined) && (
                            <SearchInput search={search} searchChange={searchChange} placeholder={placeholder} />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}
