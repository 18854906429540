import React, {Component, Fragment} from 'react';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";

export default class ListarNiveles extends Component{

    componentWillMount() {
        const { listarNiveles, page } = this.props;
        listarNiveles(page);
    }

    render() {
        const { listarNiveles: onPageChange, onSortChange, eliminarNivel, searchChangeNivel } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header {...this.props} searchChange={searchChangeNivel} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ biblioteca: "biblioteca_archivos"})}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NIVEL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="asignatura"
                            dataFormat={(cell) => cell.nombre}
                        >
                            ASIGNATURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                        >
                            CODIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="archivos"
                            dataAlign="center"
                        >
                            CANTIDAD DE ARCHIVOS
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
