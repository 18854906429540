import React, { Fragment} from 'react';

function CardForm(props) {
    const {noPaddingXContent} = props;
    return (
        <Fragment>
            <div className="mb-4 card-tabla card-small">
                <div className={'card-content-form' + (noPaddingXContent ? ' px-0' : '')}>
                    <div className={`col-12 col-lg-${props.col ? props.col : '8'}`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default CardForm;
