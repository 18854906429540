import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import moment from 'moment';

export default class CursosAlumno extends Component{

    componentWillMount() {
        const { listar_cursos } = this.props;
        listar_cursos();
    }

    render() {
        const { listar_cursos: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='10%'
                            dataFormat={standardActions({ ver: "apuntes" })}
                        />
                        <TableHeaderColumn
                            dataField="curso_activo"
                            width='12%'
                            dataFormat={(cell) => cell  ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Activo</span>
                                </div>
                            ) : (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-rosa mr-1"></div>
                                    <span className="rosa ml-1">Inactivo</span>
                                </div>
                            )}
                        >
                            Estado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            width='25%'
                        >
                            Cursos
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="clase_grupal"
                            dataSort
                            dataFormat={ cell => cell ? 'Grupal' : 'Individual' }
                        >
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio"
                            dataSort
                            dataFormat={ cell => moment(cell).format('DD/MM/YY') }
                        >
                            Fecha inicio
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_fin"
                            dataSort
                            dataFormat={ cell => moment(cell).format('DD/MM/YY') }
                        >
                            Fecha Fin
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='8%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
