import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/alumnos/formularios';
import FormsAsignadosGrid from './FormulariosGrid';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.formsAlumno,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(FormsAsignadosGrid);
