import React, {Component, Fragment} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import {formatActivo} from "Utils/renderField/renderReadField";
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default class Aprobados extends Component{
    componentWillMount() {
        const { listarRecibidosProfesor, page_recibidos } = this.props;
        listarRecibidosProfesor(page_recibidos);
    }

    onPageChangeRecibidosProfesor = (page_recibidos) => {
        this.props.listarRecibidosProfesor(page_recibidos);
    };

    render() {
        // Funciones
        const { listarRecibidosProfesor:
            onPageChangeRecibidosProfesor,
            onSortChangeRecibidosProfesor,
            eliminar,
            searchChangeRecibidosProfesor,
            filtroMesInicioRecibidosProfesor,
            filtroMesFinRecibidosProfesor,
        } = this.props;

        // Constantes
        const {
            data_recibidos,
            loader,
            page_recibidos,
            search_recibidos,
            mes_inicio_filtro_recibidos,
            mes_fin_filtro_recibidos,
        } = this.props;

        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    {...this.props}
                    searchChange={searchChangeRecibidosProfesor}
                    search={search_recibidos}
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicio"
                            onChange={filtroMesInicioRecibidosProfesor}
                            selected={mes_inicio_filtro_recibidos}
                        />
                    }
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha fin"
                            onChange={filtroMesFinRecibidosProfesor}
                            selected={mes_fin_filtro_recibidos}
                        />
                    }
                />
                <Card>
                    <Grid hover striped
                        data={data_recibidos}
                        loading={loader}
                        onPageChange={this.onPageChangeRecibidosProfesor}
                        onSortChange={onSortChangeRecibidosProfesor}
                        page={page_recibidos}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ ver: "comunicado_profesor"})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('DD/MM/YYYY') : ""}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            TITULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creador"
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.nombres ? `${cell.nombres}` : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.tipo_usuario_text ? cell.tipo_usuario_text : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            USUARIO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
