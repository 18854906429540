import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { SelectField, renderTimeField, renderEditorField } from '../../../Utils/renderField';
import { DIAS_SEMANA, CLASE_CANCELADA } from '../../../../../utility/constants';
import { api } from 'api';


const EventoDetalleModal = (props) => {
    const { handleSubmit, cancelar, evento, editarEvento, generarLinks } = props;
    return (
        <form name="AsignacionForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group grid-container mx-md-2">
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-md-5 mt-3">
                    <div className="d-flex flex-row align-items-center w-100">
                        <div className="flex-1">
                            <label htmlFor="dia" className="m-0">Día</label>
                        </div>
                        <div className="flex-1">
                            <Field name="dia" disabled component={SelectField} options={DIAS_SEMANA} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center w-100 mt-3">
                        <div className="flex-1">
                            <label htmlFor="hora_inicio" className="m-0">Hora inicio</label>
                        </div>
                        <div className="flex-1">
                            <Field name="hora_inicio" disabled component={renderTimeField} />
                        </div>
                    </div>
                    <div className="d-flex flex-row align-items-center w-100 mt-3">
                        <div className="flex-1">
                            <label htmlFor="hora_fin" className="m-0">Hora fin</label>
                        </div>
                        <div className="flex-1">
                            <Field name="hora_fin" disabled component={renderTimeField} />
                        </div>
                    </div>
                </div>
                {
                    (evento) && (evento.tipo_evento != CLASE_CANCELADA) && (
                        <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end mt-3">
                            {
                                (evento) && (evento.contenido) ? (
                                    <div className="d-flex flex-column w-100 mt-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button
                                                type="button"
                                                className="btn btn-outline-secondary"
                                                onClick={() => editarEvento(evento.id)}
                                            >Ver temario</button>
                                        </div>
                                        <div className="flex-1">
                                            <Field name="contenido" height="10rem" disabled component={renderEditorField} />
                                        </div>
                                    </div>
                                ) : (
                                        <div className="rd-flex flex-row align-items-center w-100 mt-3">
                                            <h4 className="rosa" style={{ textAlign: "center" }}>NO HAY TEMARIO</h4>
                                        </div>
                                    )
                            }
                        </div>
                    )
                }
                {
                    (evento) && (evento.tipo_evento == CLASE_CANCELADA) ? (
                        <Fragment>
                            <div className="rd-flex flex-row align-items-center w-100 mt-3">
                                <h4 className="rosa" style={{ textAlign: "center" }}>CLASE CANCELADA</h4>
                                <div className="d-flex flex-row align-items-center w-100 my-3">
                                    <div className="flex-1 w-100">
                                        <label style={{fontSize: "14px"}} className="m-0 mr-3">Usuario:</label>
                                        <label style={{fontWeight: "600"}} className="m-0">
                                            {evento.usuario_cancelacion.nombres}
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center w-100">
                                    <div className="flex-1 w-100">
                                        <label style={{fontSize: "14px"}} className="m-0">Motivo de anulación:</label>
                                    </div>
                                    <div className="flex-1 w-100">
                                        <label style={{fontWeight: "600"}} className="m-0">
                                            {evento.motivo_anulacion}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="buttons-box mt-5 px-md-5 mx-md-5">
                                <button type="button" className="btn btn-secondary " onClick={cancelar}>Regresar</button>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="buttons-box mt-5 px-md-5">
                            <button type="button" className="btn btn-secondary d-flex flex-1 mr-3" onClick={cancelar}>Cancelar</button>
                            {
                                (evento) && (evento.contenido) ? (
                                    <button type="button" onClick={() => generarLinks(evento.id)} className="btn btn-primary d-flex flex-1" >Comenzar clase</button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-primary d-flex flex-1 "
                                        onClick={() => editarEvento(evento.id)}
                                    >Realizar temario</button>
                                )
                            }
                        </div>

                    )
                }
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'EventoEditForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'dia': validators.exists()('Campo requerido.'),
            'hora_inicio': validators.exists()('Campo requerido.'),
            'hora_fin': validators.exists()('Campo requerido.'),
        })
    }
})(EventoDetalleModal);


