import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/cuenta/pasarela';
import PasarelaPago from './PasarelaPago';


const ms2p = (state) => {
    return {
        ...state.pasarela,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PasarelaPago);
