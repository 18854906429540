import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/biblioteca';
import ListarNiveles from './ListarNiveles';


const ms2p = (state) => {
  return {
    ...state.niveles,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ListarNiveles);
