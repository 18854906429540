import React, {Component} from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import EnviadosList from './Enviados';
import RecibidosList from './Recibidos';

export default class Comunicados extends Component{
    render() {
        return(
            <div className="mb-4 col-12 mt-4">
                <Tabs
                    defaultActiveKey="ENVIADOS"
                    tabBarPosition="top"
                    onChange={this.callback}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="ENVIADOS" key="ENVIADOS">
                        <EnviadosList {...this.props} />
                    </TabPane>
                    <TabPane tab="RECIBIDOS" key="RECIBIDOS">
                        <RecibidosList {...this.props} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
