import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/colaboradores';
import CuponesList from './Cupones';

const ms2p = (state) => {
    return {
        ...state.colaboradores,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CuponesList);
