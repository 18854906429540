import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import './login.css';
import LoadMask from "Utils/LoadMask/LoadMask";
import { capitalize } from '../../../../utility/helpers';
import queryString from 'query-string'

const logo = require("../../../../../assets/img/logo.png");
const logoLogin = require("../../../../../assets/img/logo_login.png");
const logoResponsivo = require("../../../../../assets/img/logo_login_movil.png");

class Login extends Component {
    state = {
        ver_password: false,
        show_animacion: false,
        show_animacion2: false,
        compra_curso: false
    }

    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        const idCurso = this.props.match.params.id;
        if (idCurso) {
            this.setState({compra_curso: true});
        }
        setTimeout(() => {this.setState({show_animacion: true})}, 500);
        setTimeout(() => {this.setState({show_animacion2: true})}, 1000);

        // Parametros mandados desde la frontpage o desde el correo que mando el colaborador.
        const value = queryString.parse(this.props.location.search);
        if (value.cupon) {
            localStorage.setItem('cupon', value.cupon);
        }
        if (value.id_curso) {
            localStorage.setItem('curso', value.id_curso);
        }
        if (value.tipo_curso) {
            localStorage.setItem('tipo_curso', value.tipo_curso);
        }
    }

    change_ver_password = () => {
        this.setState({ver_password: !this.state.ver_password});
    }

    getAnimacion = () => {
        const { show_animacion } = this.state;
        if (show_animacion) {
            return "animado";
        }
        return "";
    };

    getAnimacion2 = () => {
        const { show_animacion2 } = this.state;
        if (show_animacion2) {
            return "animado";
        }
        return "";
    };

    callbackFacebookLogin = (data) => {
        const {socialLoginRegistro} = this.props;
        if (!!data && data.status !== "unknown") {
            const body = {
                email: data.email,
                nombres: capitalize(data.first_name.toLowerCase()),
                apellidos: capitalize(data.last_name.toLowerCase()),
            }
            socialLoginRegistro(body, 'Facebook');
            console.log("Facebook",body)
        }
    }

    handleGoogleSuccess = (data) => {
        const {socialLoginRegistro} = this.props;
        if(data) {
            const body = {
                email: data.profileObj.email,
                nombres: capitalize(data.profileObj.givenName.toLowerCase()),
                apellidos: capitalize(data.profileObj.familyName.toLowerCase()),
            }
            socialLoginRegistro(body, 'Google');
            console.log("Google", body)
        }
    }

    handleGoogleFailure = (data) => {
        console.log("Error",data);
    }

    onSubmit = (values) => {
        const idCurso = this.props.match.params.id;
        const data = {...values};
        if (idCurso) {
            data.id_curso = idCurso;
        }

        this.props.onSubmit(data);
    }

    changeInicio = () => {
        this.setState({compra_curso: false});
    }

    render() {
        const { onSubmit, loader } = this.props;
        const idCurso = this.props.match.params.id;
        // console.log("local ", localStorage.getItem('sin_pasarela'))
        if (sessionStorage.getItem('token') && localStorage.getItem('sin_pasarela') === 'null') {
            return (<Redirect to={idCurso ? `/pasarela/${idCurso}` : "/"} />);
        }
        return (
            <div className="d-flex flex-row bg-celeste flex-1 login-container">
                <div className={`flex-1 align-items-center align-self-center card-conteiner`}>
                    <div className={`d-lg-flex d-none card card-login ${this.getAnimacion2()}`} style={{boxShadow: "0px 10px 30px #0000003B"}}>
                        <div className={`logo-card-login mb-3`}>
                            <img src={logo} alt="Sidioms" style={{width: "30%"}} />
                        </div>
                        <LoadMask loading={loader} light>
                            {
                                (this.state.compra_curso) ? (
                                    <div className="form-validate form-login mb-lg">
                                        <h3 className="text-center">Por favor inicia sesión o registrate para realizar tu compra.</h3>
                                        <div className="col-12 px-5 my-3">
                                            <button type="button" onClick={this.changeInicio} className="btn btn-primary btn-block">Iniciar sesión</button>
                                        </div>
                                        <div className="col-12 px-5 mb-3">
                                            <Link type="submit" to={idCurso ? `/registro/${idCurso}` : "/registro" } className="btn btn-secondary btn-block">Registrarme</Link>
                                        </div>
                                    </div>
                                ) : (
                                    <LoginForm
                                        onSubmit={this.onSubmit}
                                        change_ver_password={this.change_ver_password}
                                        ver_password={this.state.ver_password}
                                        onFacebookLogin={this.callbackFacebookLogin}
                                        onGoogleSuccess={this.handleGoogleSuccess}
                                        onGoogleFailure={this.handleGoogleFailure}
                                        idCurso={idCurso}
                                    />
                                )
                            }
                        </LoadMask>
                    </div>
                    <div className={`d-flex d-lg-none card card-login-responsive ${this.getAnimacion()}`} style={{boxShadow: "0px 10px 30px #0000003B"}}>
                        <div className={`logo-card-login mb-3 flex-1`}>
                            <img src={logo} alt="Sidioms" style={{width: "80%"}} />
                        </div>
                        <div className={`d-flex flex-4 align-items-center`}>
                            <LoadMask loading={loader} light>
                                {
                                    (this.state.compra_curso) ? (
                                        <div className="form-validate form-login mb-lg">
                                            <h3 className="text-center">Por favor inicia sesión o registrate para realizar tu compra.</h3>
                                            <div className="col-12 px-5 my-3">
                                                <button type="button" onClick={this.changeInicio} className="btn btn-primary btn-block">Iniciar sesión</button>
                                            </div>
                                            <div className="col-12 px-5 mb-3">
                                                <Link type="submit" to={idCurso ? `/registro/${idCurso}` : "/registro" } className="btn btn-secondary btn-block">Registrarme</Link>
                                            </div>
                                        </div>
                                    ) : (
                                        <LoginForm
                                            onSubmit={this.onSubmit}
                                            change_ver_password={this.change_ver_password}
                                            ver_password={this.state.ver_password}
                                            onFacebookLogin={this.callbackFacebookLogin}
                                            onGoogleSuccess={this.handleGoogleSuccess}
                                            onGoogleFailure={this.handleGoogleFailure}
                                            idCurso={idCurso}
                                        />
                                    )
                                }
                            </LoadMask>
                        </div>
                    </div>
                </div>
                <div className={`d-lg-flex d-none logo-login ${this.getAnimacion()} justify-content-center align-items-center`}>
                    <img className="img-logo-login" src={logoLogin}/>
                </div>
                <div className={`d-lg-none d-block logo-login-responsive`}>
                    <img className="img-logo-login-responsive" src={logoResponsivo}/>
                </div>
            </div>
        );
    }
}

export default Login;
