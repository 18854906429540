import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { getMe } from '../cuenta/login';
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_PERFIL';
const DATA_FACTURACION = 'DATA_FACTURACION_PERFIL';
const PAGE_FACTURACION = 'PAGE_FACTURACION_PERFIL';
const SEARCH_FACTURACION = 'SEARCH_FACTURACION_PERFIL';
const FECHA_FACTURACION = 'FECHA_FACTURACION_PERFIL';
const ITEM_DATA = 'ITEM_PERFIL';
const MENSAJES = 'MENSAJES_PERFIL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data
});

const setPage = (type, page) => ({
    type,
    page
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

// ------------------------------------
// Actions
// ------------------------------------

const listarFacturas = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().perfil;
    const me = getStore().login.me;
    const params = { alumno: me.id, page };
    params.search = resource.search_facturacion;
    if (resource.fecha_facturacion)
        params.fecha = moment(resource.fecha_facturacion).hour(0).minute(0).seconds(0).format()
    dispatch(setLoader(true));
    api.get('facturas', params).then((response) => {
        dispatch(setData(DATA_FACTURACION, response));
        dispatch(setPage(PAGE_FACTURACION, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('user/me').then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('EditarPerfilForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (data, attachments = []) => (dispatch) => {
    console.log("attachments", attachments);
    dispatch(setLoader(true));
    api.putAttachments(`user/update_me`, data, attachments).then(() => {
        NotificationManager.success('Perfil actualizado', 'Éxito', 3000);
        dispatch(push('/perfil'));
        dispatch(getMe());
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerMensajes = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('mensajes/obtener_todos').then((response) => {
        dispatch(setData(MENSAJES, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const enviarMensaje = (data, closeModal) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('mensajes/enviar_mensaje', data).then((response) => {
        NotificationManager.success('Mensaje enviado al profesor', 'Éxito', 3000);
        closeModal();
    }).catch(() => {
        NotificationManager.error('Error al enviar el mensaje', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchFacturacionChange = value => (dispatch) => {
    dispatch(setData(SEARCH_FACTURACION, value));
    dispatch(listarFacturas());
}

const changeFechaFacturacion = value => (dispatch) => {
    dispatch(setData(FECHA_FACTURACION, value));
    dispatch(listarFacturas());
}

export const actions = {
    listarFacturas,
    leer,
    editar,
    obtenerMensajes,
    enviarMensaje,
    searchFacturacionChange,
    changeFechaFacturacion,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [DATA_FACTURACION]: (state, { data }) => {
        return {
            ...state,
            data_facturacion: data,
        };
    },
    [PAGE_FACTURACION]: (state, { page }) => {
        return {
            ...state,
            page_facturacion: page,
        };
    },
    [SEARCH_FACTURACION]: (state, { data }) => {
        return {
            ...state,
            search_facturacion: data,
        };
    },
    [FECHA_FACTURACION]: (state, { data }) => {
        return {
            ...state,
            fecha_facturacion: data,
        };
    },
    [MENSAJES]: (state, { data }) => {
        return {
            ...state,
            mensajes: data,
        };
    },
};

export const initialState = {
    loader: false,
    data_facturacion: {
        results: [],
        count: 0,
    },
    page_facturacion: 1,
    search_facturacion: '',
    fecha_facturacion: null,
    item: {},
    mensajes: [],
};

export default handleActions(reducers, initialState);

