import React, { Component } from 'react';
import {Collapse} from 'react-collapse';
import moment from 'moment';
import Swal from 'sweetalert2';
import "./styles.css";
import { Link } from 'react-router-dom';

const notificacion = require("assets/img/icons/notificacion.png");

class NotificacionPermanente extends Component {
    state = {
        is_open: false,
    }
    constructor(props) {
        super(props);
    }

    generarPago = () => {
        this.props.generar_pago();
    }

    cancelarInscripcion = () => {
        Swal.fire({
            title: 'Desea eliminar la inscripción a este curso?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, eliminar!',
            cancelButtonText: 'No, anular',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.cancelarInscripcion();
            }
        });
    }

    isOpen = () => {
        if (this.state.is_open === true) {
            this.setState({is_open: false});
        }else{
            this.setState({is_open: true});
        }
    }
    render() {
        const { user } = this.props;
        return (
            <div>
                <div className='ml-lg-4 bg-rosa mt-1 mr-sm-4 mr-md-4 mr-0'>
                    <div className="px-3 pt-2 pb-1 d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="img-avatar d-flex justify-content-center align-items-center">
                                <img src={notificacion} alt="" width="20" height="28" className=""/>
                            </div>
                            <span className="ml-3 text-white">Perdona la molestía pero es necesario atender esta nofiticación,</span>
                        </div>
                        <button className="material-icons btn-collape" onClick={this.isOpen}>{this.state.is_open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</button>
                    </div>
                    <div className="px-3 py-2">
                        <Collapse isOpened={this.state.is_open} theme={{collapse: 'ReactCollapse--collapse', content: 'ReactCollapse--content'}}>
                            <div className="mt-3 d-flex align-items-center row">
                                <div className="col-md-6 col-12 d-flex flex-column">
                                    <span>Enhorabuena, antes de comenzar con el curso:&nbsp;
                                        <span className="bold">
                                            {user.pago_pendiente ? user.pago_pendiente.aula ? user.pago_pendiente.aula.nombre : user.pago_pendiente.curso.titulo  : ''}
                                        </span>
                                        , es necesario realizar tu pago.
                                    </span>
                                    {
                                        (user.pago_pendiente) && (user.pago_pendiente.aula) && (
                                            <span className="bold">
                                                Fecha maxima de pago: {moment(user.pago_pendiente.aula.fecha_maxima_pago).format("DD/MM/YYYY")}
                                            </span>
                                        )
                                    }
                                </div>
                                <div className="col-md-6 col-12 mt-md-0 mt-2">
                                    <Link   style={{width: "9rem"}} className="btn btn-white mr-2" to="/pasarela">Pagar</Link>
                                    <button style={{width: "9rem"}} className="btn btn-secondary ml-2" onClick={this.cancelarInscripcion} type="button">Cancelar</button>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotificacionPermanente;
