import React, {Component, Fragment} from 'react';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";

import moment from 'moment';
import { ARCHIVO } from '../../../../../utility/constants';

const descargar = require("../../../../../../assets/img/iconos/descargar.png");
const link = require("../../../../../../assets/img/iconos/link.png");

export default class FormsGrid extends Component{

    componentWillMount() {
        const id = this.props.match.params.id;
        this.props.listarForms(1, id);
    }

    onPageChange = (page) => {
        const id = this.props.match.params.id;
        this.props.listarForms(page, id);
    };

    searchChange = (search) => {
        const id = this.props.match.params.id;
        this.props.searchChange5(search, id);
    };

    eliminar = (id) => {
        const idAula = this.props.match.params.id;
        this.props.eliminarForm(id, idAula)
    }

    render() {
        const { data5, loader, page5, search5, item } = this.props;
        return(
            <div className="mt-4 px-1">
                <Header
                    {...this.props}
                    to="formulario_asignar" textBtn="Agregar"
                    searchChange={this.searchChange}
                    searchChange={this.searchChange}
                    search={search5}
                />
                <Card>
                    <Grid
                        hover striped
                        data={data5}
                        loading={loader}
                        onPageChange={this.onPageChange}
                        // onSortChange={onSortChange}
                        page={page5}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='10%'
                            dataFormat={standardActions({ eliminar: this.eliminar })}
                        />
                        <TableHeaderColumn
                            dataField="clase"
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        <Fragment>
                                            <span className="bold azul-marino">Clase: </span>
                                            <span>{cell ? cell.titulo : 'Sin clase'}</span>
                                            <br />
                                        </Fragment>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span className="bold azul-marino">Fecha: </span>
                                                    <span>{moment(cell.fecha_hora_inicio).format('DD/MM/YYYY HH:mm')}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            (row.alumno) && (
                                                <Fragment>
                                                    <span className="bold azul-marino">Asignado a: </span>
                                                    <span>{row.alumno.nombres}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            CLASE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="formulario"
                            dataFormat={ (cell) => `${cell.titulo}`}
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="formulario"
                            dataFormat={ cell => cell.tipo_text}
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={ cell => moment(cell).format("DD/MM/YYYY") }
                        >
                            FECHA DE ASIGNACIÓN
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
