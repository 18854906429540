import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import {AsyncSelectField, renderField, SelectField, renderCurrency, renderNumber, renderPasswordField} from '../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { generos } from "../../../../utility/constants";
import FacebookIcon from '@material-ui/icons/Facebook';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

let PasoUnoForm = props => {
    const { handleSubmit, idCurso, ver_password, change_ver_password, onFacebookLogin, onGoogleSuccess, onGoogleFailure } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Para ingresar al sistema</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">Correo *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="email" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="password" className="m-0">Contraseña *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="password"
                                label="Contraseña"
                                component={renderPasswordField}
                                type={`${ver_password ? "text" : "password"}`}
                                className="form-control"
                                ver_password={ver_password}
                                change_ver_password={change_ver_password}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="buttons-box mt-3"
                    // style={{border: "1px solid #366AED", padding: "10px"}}
                >
                    <div className="col-md-8 col-12 row">
                        <div className="col-12 text-center">
                            <h6>Puedes registrarte con tus redes sociales</h6>
                        </div>
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <FacebookLogin
                                appId="640317750244037"
                                autoLoad={false}
                                fields="first_name,email,picture,last_name"
                                callback={onFacebookLogin}
                                textButton=" Facebook"
                                icon="fa-facebook"
                                language="es_ES"
                                cssClass="btn btn-facebook btn-block"
                            />
                        </div>
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <GoogleLogin
                                clientId="806083121026-79bh0lekj4nrj9molbg0m615vv37hehl.apps.googleusercontent.com"
                                onSuccess={onGoogleSuccess}
                                onFailure={onGoogleFailure}
                                cookiePolicy={'single_host_origin'}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-google btn-block"> <i className="fa fa-google"></i> Google</button>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <div className="col-md-8 col-12 row">
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <Link className="btn btn-secondary btn-block" to={idCurso ? `/login/${idCurso}` : "/login"}>Cancelar</Link>
                        </div>
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <button type="submit" className="btn btn-primary btn-block">Siguiente</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center mt-3">
                    <span>¿Ya tienes cuenta?&nbsp;<Link to="/login" className="puntero">Ingresa aquí</Link></span>
                </div>
            </div>
        </form>
    )
};

PasoUnoForm = reduxForm({
    form: 'AlumnoRegistroForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'email': validators.exists()('Campo requerido.'),
            'password': validators.exists()('Campo requerido.'),
        })
    }
})(PasoUnoForm);

export default PasoUnoForm;
