import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/cursos/cursosProfe';
import RecuperacionesProfe from './Recuperaciones';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.cursosProfe,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(RecuperacionesProfe);
