import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { getMe } from '../cuenta/login';
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_TARJETAS';
const DATA = 'DATA_TARJETAS';
const PAGE = 'PAGE_TARJETAS';
const URL = 'URL_TARJETAS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (data) => ({
    type: DATA,
    data
});

const setPage = (page) => ({
    type: PAGE,
    page
});

const setUrl = (url) => ({
    type: URL,
    url
});


// ------------------------------------
// Actions
// ------------------------------------

const listarTarjetas = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().tarjetas;
    dispatch(setLoader(true));
    api.get('pasarela/mis_tarjetas', {}).then((response) => {
        if(response.results.length>0){
            getStore().login.me.tiene_identificador = true;
        }
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const generarLinkAgregarTarjeta = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`pasarela/generar_link_tarjeta`, {}).then((response) => {
        dispatch(setUrl(response));
        document.forms['AgregarTarjetaForm'].submit();
    }).catch((error) => {
        NotificationManager.error("Error al intentar agregar la tarjeta", 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editarDefecto = id => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`pasarela/${id}/cambiar_tarjeta_defecto`, {}).then(() => {
        NotificationManager.success('Tarjeta por defecto cambiada', 'Éxito', 3000);
        dispatch(listarTarjetas()); // Limpiar el formulario
    }).catch((error) => {
        let mensaje = "Error al cambiar tarjeta";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`pasarela/${id}/borrar_tarjeta`, {}).then(() => {
        NotificationManager.success('Tarjeta eliminada', 'Éxito', 3000);
        dispatch(listarTarjetas()); // Limpiar el formulario
    }).catch((error) => {
        let mensaje = "Error al eliminar tarjeta";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


export const actions = {
    listarTarjetas,
    generarLinkAgregarTarjeta,
    editarDefecto,
    eliminar,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [URL]: (state, { url }) => {
        return {
            ...state,
            url,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    url: '',
};

export default handleActions(reducers, initialState);

