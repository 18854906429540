import React, { Component, Fragment } from 'react';
import Modal from 'react-responsive-modal';
import {
    Accordion,
    AccordionItem,
    AccordionItemState,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link, NavLink } from "react-router-dom";
import { EnvioMensajes } from '../../App/Perfil';
import "./styles.css";
import { USER_PROFESOR, USER_ALUMNO, USER_COLABORADOR } from '../../../../utility/constants';
import { Badge } from 'antd';
import { api } from "api";

const avatar = require("../../../../../assets/img/avatar.png");
const inicio_azul = require("../../../../../assets/img/icons/inicio_azul.png");
const inicio_blanco = require("../../../../../assets/img/icons/inicio_blanco.png");
const cursos_azul = require("../../../../../assets/img/icons/cursos_azul.png");
const cursos_blanco = require("../../../../../assets/img/icons/cursos_blanco.png");
const perfil_verde = require("../../../../../assets/img/icons/perfil_verde.png");
const perfil_blanco = require("../../../../../assets/img/icons/perfil_blanco.png");
const alerta_verde = require("../../../../../assets/img/icons/alerta_verde.png");
const alerta_blanco = require("../../../../../assets/img/icons/alerta_blanco.png");
const mensaje_blanco = require("../../../../../assets/img/icons/mensaje_blanco.png");
const mensaje_verde = require("../../../../../assets/img/icons/mensaje_verde.png");
const biblioteca_azul = require("../../../../../assets/img/icons/biblioteca_azul.png");
const biblioteca_blanco = require("../../../../../assets/img/icons/biblioteca_blanco.png")
const colaborador_azul = require("../../../../../assets/img/icons/colaborador_azul.png");
const colaborador_blanco = require("../../../../../assets/img/icons/colaborador_blanco.png");
const reportes_azul = require("../../../../../assets/img/icons/reportes_azul.png");
const reportes_blanco = require("../../../../../assets/img/icons/reportes_blanco.png");
const salir = require("../../../../../assets/img/iconos/salir.png");

class SideBar extends Component {

    state = {
        item_activo: "Inicio",
        subMenus: [
            // {path: "/perfil", nombre: "Perfil"}
        ],
        modalMensaje: false,
        notificaciones: false
    }

    constructor(props) {
        super(props);
        console.log(props);
    }

    componentDidMount() {
        const url = window.location.href;
        this.comprobarNotificaciones();
        this.interval = setInterval(() => this.comprobarNotificaciones(), 150000)

        if (url.includes("grids") || url.includes("tabs") || url.includes("notifications")) {
            this.selectAdmin();
        } else if (url.includes("cursos") || url.includes("alumnos") || url.includes("forms_profesor")) {
            this.selectCursos();
        } else if (url.includes("colaborador")) {
            this.selectColaborador();
        } else if (url.includes("niveles") || url.includes("biblioteca")) {
            this.selectBiblioteca();
        } else if (url.includes("apuntes") || url.includes("formularios") || url.includes("recuperaciones_alumno")) {
            this.selectApuntes();
        } else if (url.includes("perfil") || url.includes("mi_avance") || url.includes("mis_facturas") || url.includes("mis_tarjetas")) {
            this.selectPerfil();
        } else if (url.includes("reporte")) {
            this.selectReportesProfe();
        } else if (url.includes("comunicados_profesor") || url.includes("comunicado_profesor")) {
            this.selectComunicadosProfesor();
        } else if (url.includes("comunicados_alumno") || url.includes("comunicado_alumno")) {
            this.selectComunicadosAlumno();
        } else if (url.includes("notificaciones")) {
            this.selectNotificaciones();
        }
    };

    comprobarNotificaciones = () => {
        api.get('notificaciones/comprobar').then((response) => {
            this.setState({ notificaciones: response.resp ? true : false });
        }).catch(() => {
            this.setState({ notificaciones: false });
        });
    }

    openModalMensaje = () => {
        this.setState({ modalMensaje: true });
    };

    closeModalMensaje = () => {
        this.setState({ modalMensaje: false });
    };

    renderModalDetalle = () => {
        return (
            <Modal
                open={this.state.modalMensaje}
                onClose={this.closeModalMensaje}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <span className="text-sm azul-claro bold">Mensaje para el profesor</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12 mx-0">
                            <EnvioMensajes cancelar={this.closeModalMensaje} />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    selectHome = () => {
        this.setState({ item_activo: "Inicio" });
        this.setState({ subMenus: [] });
    };

    selectAdmin = () => {
        this.setState({ item_activo: "Administración" });
        this.setState({
            subMenus: [
                { path: "/grids", nombre: "Estudiantes" },
                { path: "/tabs", nombre: "Profesores" },
                { path: "/notifications", nombre: "Administradores daffafd" },
                { path: "/temario/1", nombre: "Test temario" },
            ]
        })
    };

    selectComunicadosProfesor = () => {
        this.setState({ item_activo: "Comunicados" });
        this.setState({
            subMenus: [
                { path: "/comunicados_profesor", nombre: "Comunicados" },
            ]
        });
    };

    selectComunicadosAlumno = () => {
        this.setState({ item_activo: "Comunicados" });
        this.setState({
            subMenus: [
                { path: "/comunicados_alumno", nombre: "Comunicados recibidos" },
                { path: null, nombre: "Mensaje al profesor" },
            ]
        });
    };

    selectColaborador = () => {
        this.setState({ item_activo: "Colaborador" });
        this.setState({
            subMenus: [
                { path: "/colaborador/cupones", nombre: "Cupones" },
                { path: "/colaborador/reporte_codigos", nombre: "Códigos canjeados" },
            ]
        })
    };

    selectCursos = () => {
        this.setState({ item_activo: "Cursos" });
        this.setState({
            subMenus: [
                { path: "/cursos", nombre: "Listado de cursos" },
                { path: "/alumnos", nombre: "Alumnos asignados" },
                { path: "/forms_profesor", nombre: "Ejercicios y exámenes" },
            ]
        })
    };

    selectReportesProfe = () => {
        this.setState({ item_activo: "Reportes" });
        this.setState({
            subMenus: [
                { path: "/reporte/recuperaciones_profe", nombre: "Tiempo de recuperación" },
                { path: "/reporte/avance_alumnos", nombre: "Avance de alumnos" },
                { path: "/reporte/puntuaciones_clases", nombre: "Puntación de alumnos" },
                { path: "/reporte/horas_trabajadas", nombre: "Horas trabajadas" },
            ]
        })
    };

    selectApuntes = () => {
        this.setState({ item_activo: "Apuntes" });
        this.setState({
            subMenus: [
                { path: "/apuntes", nombre: "Apuntes" },
                { path: "/formularios", nombre: "Ejercicios y exámenes" },
                { path: "/recuperaciones_alumno", nombre: "Tiempo de recuperación" },
            ]
        })
    };

    selectBiblioteca = () => {
        this.setState({ item_activo: "Biblioteca" });
        this.setState({
            subMenus: [
                { path: "/niveles", nombre: "Asignaturas y niveles" },
                { path: "/biblioteca_formularios", nombre: "Ejercicios y examenes" },
            ]
        })
    };

    selectPerfil = () => {
        const { me } = this.props;
        this.setState({ item_activo: "Mi perfil" });
        if (me.tipo_usuario == USER_ALUMNO && me.activar_plataforma) {
            this.setState({
                subMenus: [
                    { path: "/perfil", nombre: "Datos perfil" },
                    { path: "/mi_avance", nombre: "Mi avance" },
                    { path: "/mis_facturas", nombre: "Facturación" },
                    { path: "/mis_tarjetas", nombre: "Tarjetas de crédito" },
                ]
            });
        } else {
            this.setState({
                subMenus: [
                    { path: "/perfil", nombre: "Datos perfil" },
                ]
            });
        }
    };

    selectNotificaciones = () => {
        this.setState({ item_activo: "Notificaciones" });
        this.setState({
            subMenus: [
            ]
        })
    };

    render() {
        const { toggleOpen, navToggle, logOut, me } = this.props;
        const { item_activo, subMenus, notificaciones } = this.state;
        const url = window.location.href;
        return (
            <div className={`main-sidebar menu d-flex flex-row flex-1 ${toggleOpen ? '' : 'open'}`}>
                {this.renderModalDetalle()}
                <aside className="icons-part d-flex flex-1 flex-column">
                    <div className="main-navbar">
                        <nav
                            className="align-items-stretch bg-white flex-md-nowrap p-0 navbar navbar-light my-lg-4 my-2">
                            <a href="#" className="w-100 mr-0 navbar-brand" onClick={this.selectHome} >
                                <div className="d-table m-auto">
                                    <img
                                        id="main-logo"
                                        className="d-inline-block align-top"
                                        src={require('assets/img/Isotipo.png')}
                                        alt="Logo"
                                        width="30"
                                    />
                                </div>
                            </a>
                        </nav>
                    </div>
                    <div className="nav-wrapper h-100">
                        <div className="menu-icon h-100">
                            <Accordion className="nav--no-borders flex-column nav h-100">
                                {/* EJEMPLO DE UN ITEM SIN SUB MENU */}
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <NavLink to="/" exact className={`nav-link ${item_activo === "Inicio" && 'active'}`} onClick={this.selectHome} >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <img
                                                                        className="align-center img_azul_display"
                                                                        src={inicio_azul}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                    <img
                                                                        className="align-center img_blanco_display"
                                                                        src={inicio_blanco}
                                                                        alt="Logo"
                                                                        width="15"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                {
                                    (me) && (me.tipo_usuario == USER_PROFESOR) && (
                                        <div className="nav-item">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <AccordionItemState>
                                                            {({ expanded }) => (
                                                                <div
                                                                    className={`nav-link ${this.state.item_activo === "Cursos" && 'active'}`}
                                                                    onClick={this.selectCursos}
                                                                >
                                                                    <div className="item-menu">
                                                                        <div className="d-inline-block item-icon-wrapper">
                                                                            <img
                                                                                className="align-center img_azul_display"
                                                                                src={cursos_azul}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                            <img
                                                                                className="align-center img_blanco_display"
                                                                                src={cursos_blanco}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </AccordionItemState>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                            </AccordionItem>
                                        </div>
                                    )
                                }
                                {/* Biblioteca */}
                                {
                                    (me) && (me.tipo_usuario == USER_PROFESOR) && (
                                        <div className="nav-item">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <AccordionItemState>
                                                            {({ expanded }) => (
                                                                <div
                                                                    className={`nav-link ${this.state.item_activo === "Biblioteca" && 'active'}`}
                                                                    onClick={this.selectBiblioteca}
                                                                >
                                                                    <div className="item-menu">
                                                                        <div className="d-inline-block item-icon-wrapper">
                                                                            <img
                                                                                className="align-center img_azul_display"
                                                                                src={biblioteca_azul}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                            <img
                                                                                className="align-center img_blanco_display"
                                                                                src={biblioteca_blanco}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </AccordionItemState>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                            </AccordionItem>
                                        </div>
                                    )
                                }
                                {
                                    (me) && (me.tipo_usuario == USER_PROFESOR) && (
                                        <div className="nav-item">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <AccordionItemState>
                                                            {({ expanded }) => (
                                                                <div
                                                                    className={`nav-link ${this.state.item_activo === "Reportes" && 'active'}`}
                                                                    onClick={this.selectReportesProfe}
                                                                >
                                                                    <div className="item-menu">
                                                                        <div className="d-inline-block item-icon-wrapper">
                                                                            <img
                                                                                className="align-center img_azul_display"
                                                                                src={reportes_azul}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                            <img
                                                                                className="align-center img_blanco_display"
                                                                                src={reportes_blanco}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </AccordionItemState>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                            </AccordionItem>
                                        </div>
                                    )
                                }
                                {
                                    (me) && (me.tipo_usuario == USER_ALUMNO) && (me.activar_plataforma) && (
                                        <div className="nav-item">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <AccordionItemState>
                                                            {({ expanded }) => (
                                                                <div
                                                                    className={`nav-link ${this.state.item_activo === "Apuntes" && 'active'}`}
                                                                    onClick={this.selectApuntes}
                                                                >
                                                                    <div className="item-menu">
                                                                        <div className="d-inline-block item-icon-wrapper">
                                                                            <img
                                                                                className="align-center img_azul_display"
                                                                                src={cursos_azul}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                            <img
                                                                                className="align-center img_blanco_display"
                                                                                src={cursos_blanco}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </AccordionItemState>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                            </AccordionItem>
                                        </div>
                                    )
                                }
                                {
                                    (me) && (me.tipo_usuario == USER_COLABORADOR || me.tipo_usuario == USER_PROFESOR || me.tipo_usuario == USER_ALUMNO) && (me.es_colaborador) && (
                                        <div className="nav-item">
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <AccordionItemState>
                                                            {({ expanded }) => (
                                                                <div
                                                                    className={`nav-link ${this.state.item_activo === "Colaborador" && 'active'}`}
                                                                    onClick={this.selectColaborador}
                                                                >
                                                                    <div className="item-menu">
                                                                        <div className="d-inline-block item-icon-wrapper">
                                                                            <img
                                                                                className="align-center img_azul_display"
                                                                                src={colaborador_azul}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                            <img
                                                                                className="align-center img_blanco_display"
                                                                                src={colaborador_blanco}
                                                                                alt="Logo"
                                                                                width="15"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </AccordionItemState>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                            </AccordionItem>
                                        </div>
                                    )
                                }
                                <div className="nav-item">
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <AccordionItemState>
                                                    {({ expanded }) => (
                                                        <NavLink
                                                            to="/login" onClick={logOut} exact className={`nav-link ${item_activo === "logut" && 'active'}`} >
                                                            <div className="item-menu">
                                                                <div className="d-inline-block item-icon-wrapper">
                                                                    <img className="material-icons icon-menu" src={salir} />
                                                                    {/* <i className="material-icons">edit</img> */}
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    )}
                                                </AccordionItemState>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                    </AccordionItem>
                                </div>
                                <div className="cola-menu d-flex w-100 flex-1"></div>
                            </Accordion>
                        </div>
                    </div>
                </aside>
                <div className="items-part d-flex flex-column flex-3">
                    <div className="perfil-header w-100 d-flex flex-column justify-content-center align-items-center">
                        <div className="">
                            <img className="perfil-img" src={me.avatar ? me.avatar : avatar} alt="Perfil" />
                        </div>
                        <div className="perfil-menu mt-1 d-flex flex-column w-100">
                            <span className="perfil-nombre">{`${me.nombres && me.nombres}`}</span>
                            <div className="perfil-actions d-flex justify-content-center">
                                <button className="perfil-action px-0" onClick={this.selectPerfil} >
                                    {
                                        (url.includes("perfil") || url.includes("mi_avance") || url.includes("mis_facturas") || url.includes("mis_tarjetas")) ? (
                                            <img src={perfil_blanco} style={{ width: 30 }} />

                                        ) : (
                                                <img src={perfil_verde} style={{ width: 35 }} />
                                            )
                                    }
                                </button>
                                <Link className="perfil-action px-0" to='/notificaciones' onClick={this.selectNotificaciones} >
                                    {
                                        (url.includes("notificaciones")) ? (
                                            <img src={alerta_blanco} style={{width: 30}} />

                                        ) : (
                                            <Badge dot={notificaciones} offset={[-8, 10]}>
                                                <img src={alerta_verde} style={{width: 35}} />
                                            </Badge>
                                        )
                                    }
                                </Link>
                                {
                                    (me) && (me.tipo_usuario == USER_ALUMNO) && (me.activar_plataforma) && (
                                        <button className="perfil-action px-0" onClick={this.selectComunicadosAlumno}>
                                        {
                                            (url.includes("comunicados_alumno") || url.includes("comunicado_alumno")) ? (
                                                <img src={mensaje_blanco} style={{width: 30}} />

                                            ) : (
                                                <img src={mensaje_verde} style={{width: 35}} />
                                            )
                                        }
                                        </button>
                                    )
                                }
                                {
                                    (me) && (me.tipo_usuario == USER_PROFESOR) && (
                                        <button className="perfil-action px-0" onClick={this.selectComunicadosProfesor}>
                                        {
                                            (url.includes("comunicados_profesor") || url.includes("comunicado_profesor")) ? (
                                                <img src={mensaje_blanco} style={{width: 30}} />

                                            ) : (
                                                <img src={mensaje_verde} style={{width: 35}} />
                                            )
                                        }
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="menu-items w-100">
                        <div className="header-item">
                            <span className="text-nav">{this.state.item_activo}</span>
                        </div>
                        <ul className="sub-menu-list">
                            {
                                this.state.subMenus.map((item, index) => (
                                    <li className="sub-nav-item" key={index}>
                                        { item.path ? (
                                            <NavLink to={item.path} exact className="sub-nav-link " activeClassName={'active'}>
                                                <div className="sub-item-menu">
                                                    <span className="sub-text-nav">{item.nombre}</span>
                                                </div>
                                            </NavLink>
                                        ): (
                                            <NavLink to={'#'} type="button" onClick={this.openModalMensaje} className="sub-nav-link" >
                                                <div className="sub-item-menu">
                                                    <span className="sub-text-nav">{item.nombre}</span>
                                                </div>
                                            </NavLink>

                                        )}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default SideBar;
