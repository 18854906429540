import React, { Component, Fragment } from 'react';
import {NotificationManager} from "react-notifications";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Calendario from '../../../Utils/Calendario/Calendario';
import Modal from 'react-responsive-modal';
import Swal from 'sweetalert2';
import { api } from "api";

import AgendaAlumno from './Agenda';
import EventoDetalleModal from './EventoModal';

export default class CalendarioAlumno extends Component {

    state = {
        modalDetalle: false,
        evento: null,
        anulacion: false,
        link: null,
    }

    componentWillMount() {
        const { me } = this.props;
        this.props.iniciarFechas();
        this.props.obtenerActividades(me.id);
        // this.interval = setInterval(() => this.solicitarLink(), 5000)
    };

    refrescar = () => {
        const { me } = this.props;
        this.props.obtenerActividades(me.id);
    }

    comprobarLink = () => {
        const {evento} = this.state;
        console.log("solicitar link");
        api.get(`clase/${evento.id}/link_alumno`).then((response) => {
            if (response.link != null) {
                window.open(response.link, "_blank");
            } else {
                NotificationManager.error("El profesor no se encuentra en la clase", 'ERROR');
            }
        }).catch(() => {NotificationManager.error("El profesor no se encuentra en la clase", 'ERROR');});
    }

    componentWillUnmount() {
        this.props.iniciarFechas();
        this.setState({ link: null });
    };

    openModalDetalle = (evento) => {
        console.log("evento ", evento);
        this.props.setearFormEventoDetalle(evento);
        this.setState({ modalDetalle: true, evento: evento });
    };

    closeModal = (e) => {
        this.setState({ modalDetalle: false, evento: null, anulacion: false });
        this.setState({ link: null });
    };

    openClase = (idAula, idClase) => {
        this.closeModal(null);
        setTimeout(() => {
            this.props.history.push(`/apuntes/${idAula}/clase/${idClase}`);
        }, 700);
    }

    anularClase = (data) => {
        const { me } = this.props;
        Swal.fire({
            title: '¿Cancelar clase?',
            text: '¡No podrá revertir esta acción!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, cancelar clase!',
            cancelButtonText: 'No, salir',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                // this.props.eliminar(id);
                const { evento } = this.state;
                this.props.anularClase(evento.id, data, me.id);
                this.closeModal(null);
            }
        });
    };

    mostrarAnulacion = () => {
        this.setState({ anulacion: true });
    };

    renderModalDetalle = () => {
        const { evento } = this.state;
        return (
            <Modal
                open={this.state.modalDetalle}
                onClose={this.closeModal}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <span className="text-sm azul-claro bold">{evento ? evento.titulo : ""}</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12 mx-0">
                            <EventoDetalleModal
                                evento={this.state.evento}
                                cancelar={this.closeModal}
                                mostrarAnulacion={this.mostrarAnulacion}
                                anulacion={this.state.anulacion}
                                onSubmit={this.anularClase}
                                comprobarLink={this.comprobarLink}
                                openClase={this.openClase}
                            />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    changeFechas = (value) => {
        const { me } = this.props;
        this.props.changeFechas(value, me.id);
    }

    changeTipoEvento = (value) => {
        const { me } = this.props;
        this.props.changeTipoEvento(value, me.id);
    }

    render() {
        const { eventos, tipo_evento, loader } = this.props;
        return (
            <div>
                {this.renderModalDetalle()}
                <LoadMask loading={loader} blur>
                    <Tabs
                        defaultActiveKey="CALENDARIO"
                        tabBarPosition="top"
                        onChange={this.callback}
                        renderTabBar={() => <ScrollableInkTabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="CALENDARIO" key="CALENDARIO">
                            <div className="d-flex flex-1 flex-column w-100 mt-5 mt-md-4 px-1" style={{ height: "calc(100vh - 7.5rem)" }}>
                                <Calendario
                                    eventos={eventos}
                                    toolbar={true}
                                    onlySemana={false}
                                    height="100%"
                                    tipo_evento={tipo_evento}
                                    changeTipoEvento={this.changeTipoEvento}
                                    changeFechas={this.changeFechas}
                                    onSelectEvent={this.openModalDetalle}
                                    refrescar={this.refrescar}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="AGENDA" key="AGENDA">
                            <div className="d-flex flex-1 flex-column w-100 mt-5 mt-md-4 px-1">
                                <AgendaAlumno {...this.props} openModalDetalle={this.openModalDetalle} />
                            </div>
                        </TabPane>
                    </Tabs>
                </LoadMask>
            </div>
        )
    }
}
