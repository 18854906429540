import React, { Fragment} from 'react';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { ENLACE, EXTENSIONES, EJERCICIO, EXAMEN } from '../../../../utility/constants';
import { getIconoForm } from '../../../../utility/iconos';
import moment from 'moment';


const clear = require('../../../../../assets/img/iconos/clear.png');
const check = require('../../../../../assets/img/iconos/check.png');

export function CardSmallEjercicio(props) {
    const { index, nombre, tipo, seleccionar, seleccionado, eliminar, item} = props;
    return (
        <Fragment>
            <div
                className={`py-2 card-archivo ${eliminar && 'eliminar'} ${seleccionado && 'seleccionado'}`}
                style={{cursor: seleccionar ? 'pointer' : 'default', height: '100%'}}
                onClick={() => seleccionar && seleccionar(index, item)}
            >
                <div className="d-flex align-items-center justify-content-center">
                    <img src={getIconoForm(tipo)} className="card-archivo-icon" />
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2 card-tooltip">
                    <Tooltip title={nombre} placement="bottom">
                        <label className="card-archivo-label">{nombre}</label>
                    </Tooltip>
                </div>
                {
                    eliminar && (
                        <button className="card-archivo-eliminar" type="button" onClick={() => eliminar(index)}>
                            <img className="card-icon-clear" src={clear} />
                            {/* <i className="material-icons card-icon-clear">clear</img> */}
                        </button>
                    )
                }
                {
                    seleccionado && (
                        <div className="card-archivo-seleccionado">
                            <img className="card-icon-clear" src={check} />
                        </div>
                    )
                }
            </div>
        </Fragment>

    )
}

export function CardEjercicio(props) {
    const { index, nombre, tipo, seleccionar, seleccionado, eliminar, item} = props;
    return (
        <Fragment>
            <div
                className={`py-2 card-archivo flex-row ${eliminar && 'eliminar'} ${seleccionado && 'seleccionado'}`}
                style={{cursor: seleccionar ? 'pointer' : 'default', height: '100%'}}
                onClick={() => seleccionar && seleccionar(index, item)}
            >
                <div className="d-flex flex-1 align-items-center justify-content-center card-img-ejercicio">
                    <img src={getIconoForm(tipo)} style={{width: "75%"}} />
                </div>
                <div className="d-flex flex-2 flex-column align-items-start justify-content-center mt-2 card-tooltip">
                    <Tooltip title={nombre} placement="bottom">
                        <label className="card-archivo-label">{nombre}</label>
                    </Tooltip>
                    {
                        (item) && (item.formulario) && (item.formulario.tipo == EJERCICIO) ? (
                            <label className="card-archivo-label sublabel">
                                {`Fecha: ${moment(item.fecha_hora_entrega).format('DD/MM/YYYY hh:mm a')}`}
                            </label>
                        ) : (
                            <label className="card-archivo-label sublabel">
                                {`Fecha: ${moment(item.fecha_hora_examen).format('DD/MM/YYYY hh:mm a')}`}
                            </label>
                        )
                    }
                    {
                        (item) && (item.formulario) && (item.formulario.tipo == EXAMEN) && (
                            <label className="card-archivo-label sublabel">
                                {`Duración: ${item.tiempo_maximo} ${item.tiempo_maximo == 1 ? 'hora' : 'horas'}`}
                            </label>
                        )
                    }
                </div>
                {
                    eliminar && (
                        <button className="card-archivo-eliminar" type="button" onClick={() => eliminar(index)}>
                            <img className="card-icon-clear" src={clear} />
                            {/* <i className="material-icons card-icon-clear">clear</img> */}
                        </button>
                    )
                }
                {
                    seleccionado && (
                        <div className="card-archivo-seleccionado">
                            <img className="card-icon-clear" src={check} />
                        </div>
                    )
                }
            </div>
        </Fragment>

    )
}

