import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import CardForm from 'Utils/Cards/CardForm';

const iconoOK = require("../../../../../../assets/img/iconos/icono-ok.png");
const pago = "¡Felicidades, se ha realizado el pago exitosamente!";
const tarjeta = "¡Felicidades, has agregado una nueva tarjeta!";


export default class VistaOK extends Component {


    render() {
        const url = window.location.href;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <div className="d-flex flex-column flex-1 w-100 h-100">
                        <div className="d-flex flex-1 align-items-center justify-content-center">
                            <img style={{width: "25%"}} src={iconoOK} alt="OK" />
                        </div>
                        <div className="d-flex flex-1 align-items-center justify-content-center my-5">
                            <h1 className="bold azul-claro text-center">{url.includes("pago_exitoso") ? pago : tarjeta}</h1>
                        </div>
                        <div className="d-flex flex-1 align-items-center justify-content-center">
                            <Link className="btn btn-primary" to={url.includes("pago_exitoso") ? "/mis_facturas" : "/mis_tarjetas"}>Siguiente</Link>
                        </div>
                    </div>
                </CardForm>
            </div>
        )
    }
}
