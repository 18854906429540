import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/comunicados';
import VerComunicado from './VerComunicado';

const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.comunicados,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VerComunicado);
