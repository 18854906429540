import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('user/login', data).then((response) => {
        dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        localStorage.setItem('sin_pasarela', true);
        sessionStorage.setItem('token', response.token);
        if (data.id_curso) {
            dispatch(push(`/pasarela`));
        } else {
            dispatch(push("/"));
        }
    }).catch((error) => {
        let mensaje = 'Credenciales incorrectas, vuelva a intentar';
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


export const getMe = () => (dispatch) => {
    api.get('/user/me').then(me => {
        dispatch(initializeForm('profile', me));
        localStorage.setItem('sin_pasarela', null);
        dispatch(setMe(me));
    })
        .catch(() => {
    }).finally(() => {});
};

export const logOut = () => (dispatch) => {
    api.post('/user/logout').then(() => {
    }).catch(() => {
    }).finally(() => {});
    sessionStorage.removeItem('token');
};

export const socialLoginRegistro = (data, red_social) => (dispatch) => {
    dispatch(setLoader(true))
    api.post('user/social_login', data).then(response => {
        if (response.crear_usuario) {
            dispatch(initializeForm('AlumnoRegistroForm', response));
            dispatch(push("/registro"));
        }
        else{
            sessionStorage.setItem('token', response.token);
            dispatch(initializeForm('profile', response.user));
            dispatch(setMe(response.user));
            dispatch(push("/"));
        }
    }).catch(error => {
        let mensaje = `Error en ${red_social} , vuelva a intentar`;
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR', 5000);
    }).finally(() => {
        dispatch(setLoader(false))
    })
}

export const crearComentario = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('comentario', data).then(() => {
        NotificationManager.success('Comentario creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(getMe());
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('ComentarioForm', {calificacion: 1}));
};

export const changePassword = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user/change_password', data).then((response) => {
        NotificationManager.success('Tu constaseña a sido actualizada');
        dispatch(setMe(response));
        dispatch(push("/"));
    }).catch(() => {
        NotificationManager.error('Error al modificar la contraseña, vuelva a intentar', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const sendRecover = (data) => (dispatch) => {
    const email = data.email;
    dispatch(setLoader(true));
    api.post('user/pass_reset_mail', {email}).then(res =>{
        NotificationManager.success('El correo para recuperar la contraseña ha sido enviada, por favor revisa tu correo');
        dispatch(push("/login"));
    }).catch(err => {
        NotificationManager.error('A ocurrido un error al enviar el correo para recuperar la contraseña, por favor intenta de nuevo.', 'ERROR');
    }).finally(()=>{
        dispatch(setLoader(false));
    });
};

export const resetPassword = (data) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user/pass_reset', data).then(res =>{
        NotificationManager.success('Tu contraseña ha sido reestablecida correctamente');
        dispatch(push('/login'));
    }).catch(err => {
        NotificationManager.error((err && err.detail) ? err.detail : 'Ocurrio un error en el reseteo de contraseña, por favor intente de nuevo.' , 'ERROR');
    }).finally(()=> {
        dispatch(setLoader(false));
    });
};

export const newToken = (token) => (dispatch) => {
    const body = { token };
    api.post('fcm', body).catch((error) => { }).then((data) => {
        console.log('Se registro el token')
    });
};

export const actions = {
    onSubmit,
    logOut,
    socialLoginRegistro,
    crearComentario,
    changePassword,
    sendRecover,
    resetPassword,
    newToken,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
};

export const initialState = {
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
