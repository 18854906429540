import React, { Component, Fragment } from 'react';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Select from 'react-select';
import classNames from 'classnames';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import { ESTADOS_EVENTOS } from '../../../../../utility/constants';

import _ from 'lodash';
import moment from 'moment';

export default class ClasesGrid extends Component {

    componentWillMount() {
        const id = this.props.match.params.id;
        this.props.listarClases(1, id);
    }

    onPageChange = (page) => {
        const id = this.props.match.params.id;
        this.props.listarClases(page, id);
    };

    searchChange = (search) => {
        const id = this.props.match.params.id;
        this.props.searchChange2(search, id);
    };

    changeEstadoEvento = (value) => {
        const id = this.props.match.params.id;
        this.props.changeEstadoEvento(value, id);
    };

    render() {
        // const {  } = this.props; //Funciones
        const { data2, loader, page2, search2, item } = this.props; //Constantes
        return (
            <div className="mt-4 px-1">
                <Header
                    {...this.props}
                    searchChange={this.searchChange}
                    search={search2}
                    to={item && item.clase_grupal == false ? 'recuperar_clase' : undefined}
                    textBtn="Asignar clase de recuperación"
                    filtro1={
                        <Select
                            isClearable={true}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={ESTADOS_EVENTOS}
                            placeholder={"Filtrar por estado"}
                            value={this.props.estado_evento}
                            onChange={this.changeEstadoEvento}
                        />
                    }
                />
                <Card>
                    <Grid
                        hover striped
                        data={data2}
                        loading={loader}
                        onPageChange={this.onPageChange}
                        // onSortChange={onSortChange}
                        page={page2}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='10%'
                            dataFormat={standardActions({ editar: '/temario' })}
                        />
                        <TableHeaderColumn
                            dataField="titulo"
                            width='15%'
                        >
                            CLASE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="contenido"
                            width='15%'
                            dataFormat={(cell) => cell ? (
                                <div className="d-flex flex-row align-items-center">
                                    <div className="circulo bg-aqua mr-1"></div>
                                    <span className="aqua ml-1">Con temario</span>
                                </div>
                                ) : (
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="circulo bg-rosa mr-1"></div>
                                        <span className="rosa ml-1">Sin temario</span>
                                    </div>
                            )}
                        >
                            TEMARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_hora_inicio"
                            dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_hora_inicio"
                            dataFormat={cell => moment(cell).format("LT")}
                        >
                            HORA INICIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_hora_fin"
                            dataFormat={cell => moment(cell).format("LT")}
                        >
                            HORA FIN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="estado_evento"
                            // width='25%'
                            dataFormat={cell => {
                                const item = _.find(ESTADOS_EVENTOS, { value: cell })

                                return (
                                    <div style={{ color: item ? item.color : '#1C375E' }}>
                                        {item ? item.label : ''}
                                    </div>
                                )
                            }}
                        >
                            ESTADO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
