import React, { Component, cloneElement, Fragment } from 'react';
import Chart from "react-google-charts";
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Select, { Async } from 'react-select';
import classNames from 'classnames';
import Card from "Utils/Cards/Card"
import DatePicker from 'react-datepicker';
import Modal from 'react-responsive-modal';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import HeaderFiltros from '../../../Utils/Grid/HeaderFiltros';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import { api } from "api";

import CanjeadosGrid from './CanjeadosGrid';
import NoCanjeadosGrid from './NoCanjeadosGrid';
import ModalSolicitudForm from './ModalSolicitud';

const getEncabezados = (search) => {
    return api.get("cupon/cupones_colaborador", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

export default class ReporteCupones extends Component {

    state = {
        modal: false
    }

    componentWillMount() {
        this.props.listarGrafica();
        // this.props.listarTabla();
    }

    openModal = () => {
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    solicitarCupones = (values) => {
        console.log(values);
        this.props.solicitarCupones(values, this.closeModal);
    }

    renderModal = () => {
        return (
            <Modal
                open={this.state.modal}
                onClose={this.closeModal}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <span className="text-sm azul-claro bold">Solicitar más códigos</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12 mx-0">
                            <ModalSolicitudForm
                                cancelar={this.closeModal}
                                onSubmit={this.solicitarCupones}
                            />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    render() {
        const { loader, graficas } = this.props; //Constantes

        return (
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicial"
                            onChange={(value) => {this.props.changeFechaInicio(value)}}
                            selected={this.props.fecha_inicio}
                        />
                    }
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha final"
                            onChange={(value) => {this.props.changeFechaFin(value)}}
                            selected={this.props.fecha_fin}
                        />
                    }
                    filtro3={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.encabezado_cupon}
                            isSearchable={true}
                            loadOptions={getEncabezados}
                            getOptionValue={(option) => option["id"]}
                            getOptionLabel={(option) => option["nombre"]}
                            type="text"
                            onChange={(e) => this.props.changeEncabezado(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames(
                                "react-select-container w-100"
                            )}
                            defaultOptions={true}
                            placeholder="Filtrar por codigo.."
                        />
                    }
                    filtro4={
                        <Select
                            isClearable={true}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={[{ value: 'True', label: 'Recurrente'}, { value: 'False', label: 'No recurrente'}]}
                            placeholder="Filtrar por tipo"
                            value={this.props.recurrente}
                            onChange={this.props.changeRecurrente}
                        />
                    }
                />
                <Card>
                    <LoadMask loading={loader} blur>
                        <div className="d-flex flex-lg-row flex-column flex-1 mt-3 mb-2">
                            <div className="flex-1">
                                <div className="row col-12 mx-0">
                                    <span className="text-sm azul-claro bold">Gráfica de totales</span>
                                    <div className="d-flex w-100 bg-gris-claro" style={{ height: '3px' }} />
                                </div>
                                <div className='d-flex flex-lg-row flex-column'>
                                    <div className='flex-1'>
                                        <Chart
                                            width={'100%'}
                                            height={'40vh'}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                ['', ''],
                                                ['Canjeados', graficas.usados],
                                                ['No canjeados', graficas.cupones],
                                            ]}
                                            options={{
                                                colors: ['#53A513', '#366AED'],
                                            }}
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    </div>
                                    <div className='d-flex flex-column flex-1 justify-content-center align-items-lg-start alignt-items-center'>
                                        <div className='d-flex flex-row justify-content-center'>
                                            <h1 className='bold azul-claro mr-3'>Total de ganancias:</h1>
                                            <RenderCurrency  value={graficas.total} className='h1 bold azul-claro' />
                                        </div>
                                        <button type='button' onClick={this.openModal} className='btn btn-primary mt-3'>Solicitar más códigos</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                    <div className="mt-3 px-lg-3">
                        <Tabs
                            defaultActiveKey="Canjeados"
                            tabBarPosition="top"
                            onChange={this.callback}
                            renderTabBar={() => <ScrollableInkTabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="Canjeados" key="Canjeados">
                                <CanjeadosGrid  {...this.props} />
                            </TabPane>
                            <TabPane tab="No canjeados" key="No canjeados">
                                <NoCanjeadosGrid {...this.props} />
                            </TabPane>
                        </Tabs>
                    </div>
                    {this.renderModal()}
                </Card>
            </div>
        )
    }


}
