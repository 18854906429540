import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import moment from 'moment';

const LOADER = 'LOADER_COMUNICADOS';
const DATA = 'DATA_COMUNICADOS';
const ITEM_DATA = 'ITEM_COMUNICADOS';
const PAGE = 'PAGE_COMUNICADOS';
const ORDERING = 'ORDERING_COMUNICADOS';
const SEARCH = 'SEARCH_COMUNICADOS';
const FILTRO_MES_INICIO_ENVIADOS = 'FILTRO_MES_INICIO_ENVIADOS';
const FILTRO_MES_FIN_ENVIADOS = 'FILTRO_MES_FIN_ENVIADOS';

// COMUNICADOS RECIBIDOS PROFESOR
const DATA_COMUNICADOS_RECIBIDOS = 'DATA_COMUNICADOS_RECIBIDOS';
const PAGE_COMUNICADOS_RECIBIDOS = 'PAGE_COMUNICADOS_RECIBIDOS';
const ORDERING_COMUNICADOS_RECIBIDOS = 'ORDERING_COMUNICADOS_RECIBIDOS';
const SEARCH_COMUNICADOS_RECIBIDOS = 'SEARCH_COMUNICADOS_RECIBIDOS';
const FILTRO_MES_INICIO_COMUNICADOS_RECIBIDOS = 'FILTRO_MES_INICIO_COMUNICADOS_RECIBIDOS';
const FILTRO_MES_FIN_COMUNICADOS_RECIBIDOS = 'FILTRO_MES_FIN_COMUNICADOS_RECIBIDOS';

// COMUNICADOS RECIBIDOS ALUMNO
const DATA_COMUNICADOS_RECIBIDOS_ALUMNO = 'DATA_COMUNICADOS_RECIBIDOS_ALUMNO';
const PAGE_COMUNICADOS_RECIBIDOS_ALUMNO = 'PAGE_COMUNICADOS_RECIBIDOS_ALUMNO';
const ORDERING_COMUNICADOS_RECIBIDOS_ALUMNO = 'ORDERING_COMUNICADOS_RECIBIDOS_ALUMNO';
const SEARCH_COMUNICADOS_RECIBIDOS_ALUMNO = 'SEARCH_COMUNICADOS_RECIBIDOS_ALUMNO';
const FILTRO_MES_INICIO_COMUNICADOS_RECIBIDOS_ALUMNO = 'FILTRO_MES_INICIO_COMUNICADOS_RECIBIDOS_ALUMNO';
const FILTRO_MES_FIN_COMUNICADOS_RECIBIDOS_ALUMNO = 'FILTRO_MES_FIN_COMUNICADOS_RECIBIDOS_ALUMNO';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// -----------------------------------------------
// Pure Actions Comunicados Recibidos Profesores
// -----------------------------------------------

const setDataRecibidos = data_recibidos => ({
    type: DATA_COMUNICADOS_RECIBIDOS,
    data_recibidos,
});

const setPageRecibidos = page_recibidos => ({
    type: PAGE_COMUNICADOS_RECIBIDOS,
    page_recibidos,
});

const setOrderingRecibidos = ordering_recibidos => ({
    type: ORDERING_COMUNICADOS_RECIBIDOS,
    ordering_recibidos,
});

const setSearchRecibidos = search_recibidos => ({
    type: SEARCH_COMUNICADOS_RECIBIDOS,
    search_recibidos,
});

// -----------------------------------------------
// Pure Actions Comunicados Recibidos Alumnos
// -----------------------------------------------

const setDataRecibidosAlumnos = data_recibidos_alumnos => ({
    type: DATA_COMUNICADOS_RECIBIDOS_ALUMNO,
    data_recibidos_alumnos,
});

const setPageRecibidosAlumnos = page_recibidos_alumnos => ({
    type: PAGE_COMUNICADOS_RECIBIDOS_ALUMNO,
    page_recibidos_alumnos,
});

const setOrderingRecibidosAlumnos = ordering_recibidos_alumnos => ({
    type: ORDERING_COMUNICADOS_RECIBIDOS_ALUMNO,
    ordering_recibidos_alumnos,
});

const setSearchRecibidosAlumnos = search_recibidos_alumnos => ({
    type: SEARCH_COMUNICADOS_RECIBIDOS_ALUMNO,
    search_recibidos_alumnos,
});


// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().comunicados;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    if (resource.mes_inicio_filtro_enviados && resource.mes_fin_filtro_enviados) {
        params.fecha_inicio = moment(resource.mes_inicio_filtro_enviados).format()
        params.fecha_fin = moment(resource.mes_fin_filtro_enviados).format()
    }
    dispatch(setLoader(true));
    api.get('comunicado/comunicados_enviados_profesor', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'comunicado'}/${id}`).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, attachments = []) => (dispatch) => {
    const _data = data;
    _data.tipo_destinatario = 10;
    dispatch(setLoader(true));
    api.postAttachments('comunicado', _data, attachments).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/comunicados_profesor'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('ComunicadoForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().comunicados.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const filtroMesInicioEnviados = mes_inicio_filtro_enviados => (dispatch) => {
    dispatch({type: FILTRO_MES_INICIO_ENVIADOS, mes_inicio_filtro_enviados})
    dispatch(listar());
};

const filtroMesFinEnviados = mes_fin_filtro_enviados => (dispatch) => {
    dispatch({type: FILTRO_MES_FIN_ENVIADOS, mes_fin_filtro_enviados})
    dispatch(listar());
};

// COMUNICADOS DEL PROFESOR RECIBIDOS
const listarRecibidosProfesor = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().comunicados;
    const params = { page };
    params.search = resource.search_recibidos;
    if (resource.mes_inicio_filtro_recibidos && resource.mes_fin_filtro_recibidos) {
        params.fecha_inicio = moment(resource.mes_inicio_filtro_recibidos).format()
        params.fecha_fin = moment(resource.mes_fin_filtro_recibidos).format()
    }
    dispatch(setLoader(true));
    api.get('comunicado/comunicados_recibidos_profesor', params).then((response) => {
        dispatch(setDataRecibidos(response));
        dispatch(setPageRecibidos(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChangeRecibidosProfesor = search_recibidos => (dispatch) => {
    dispatch(setSearchRecibidos(search_recibidos));
    dispatch(listarRecibidosProfesor());
};

const onSortChangeRecibidosProfesor = ordering_recibidos => (dispatch, getStore) => {
    const sort = getStore().comunicados.ordering_recibidos;
    if (ordering_recibidos === sort) {
        dispatch(setOrderingRecibidos(`-${ordering_recibidos}`));
    } else {
        dispatch(setOrderingRecibidos(ordering_recibidos));
    }
    dispatch(listarRecibidosProfesor());
};

const filtroMesInicioRecibidosProfesor = mes_inicio_filtro_recibidos => (dispatch) => {
    dispatch({type: FILTRO_MES_INICIO_COMUNICADOS_RECIBIDOS, mes_inicio_filtro_recibidos})
    dispatch(listarRecibidosProfesor());
};

const filtroMesFinRecibidosProfesor = mes_fin_filtro_recibidos => (dispatch) => {
    dispatch({type: FILTRO_MES_FIN_COMUNICADOS_RECIBIDOS, mes_fin_filtro_recibidos})
    dispatch(listarRecibidosProfesor());
};

// COMUNICADOS DEL ALUMNO RECIBIDOS
const listarRecibidosAlumno = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().comunicados;
    const params = { page };
    params.search = resource.search_recibidos_alumnos;
    if (resource.mes_inicio_filtro_recibidos_alumnos && resource.mes_fin_filtro_recibidos_alumnos) {
        params.fecha_inicio = moment(resource.mes_inicio_filtro_recibidos_alumnos).format()
        params.fecha_fin = moment(resource.mes_fin_filtro_recibidos_alumnos).format()
    }
    dispatch(setLoader(true));
    api.get('comunicado', params).then((response) => {
        dispatch(setDataRecibidosAlumnos(response));
        dispatch(setPageRecibidosAlumnos(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChangeRecibidosAlumno = search_recibidos_alumnos => (dispatch) => {
    dispatch(setSearchRecibidosAlumnos(search_recibidos_alumnos));
    dispatch(listarRecibidosAlumno());
};

const onSortChangeRecibidosAlumno = ordering_recibidos_alumnos => (dispatch, getStore) => {
    const sort = getStore().comunicados.ordering_recibidos_alumnos;
    if (ordering_recibidos_alumnos === sort) {
        dispatch(setOrderingRecibidosAlumnos(`-${ordering_recibidos_alumnos}`));
    } else {
        dispatch(setOrderingRecibidosAlumnos(ordering_recibidos_alumnos));
    }
    dispatch(listarRecibidosAlumno());
};

const filtroMesInicioRecibidosAlumno = mes_inicio_filtro_recibidos_alumnos => (dispatch) => {
    dispatch({type: FILTRO_MES_INICIO_COMUNICADOS_RECIBIDOS_ALUMNO, mes_inicio_filtro_recibidos_alumnos})
    dispatch(listarRecibidosAlumno());
};

const filtroMesFinRecibidosAlumno = mes_fin_filtro_recibidos_alumnos => (dispatch) => {
    dispatch({type: FILTRO_MES_FIN_COMUNICADOS_RECIBIDOS_ALUMNO, mes_fin_filtro_recibidos_alumnos})
    dispatch(listarRecibidosAlumno());
};

export const actions = {
    listar,
    leer,
    crear,
    searchChange,
    onSortChange,
    cleanForm,
    filtroMesInicioEnviados,
    filtroMesFinEnviados,
    // Comunicados del profesor recibidos
    listarRecibidosProfesor,
    searchChangeRecibidosProfesor,
    onSortChangeRecibidosProfesor,
    filtroMesInicioRecibidosProfesor,
    filtroMesFinRecibidosProfesor,
    // Comunicados del alumno recibidos
    listarRecibidosAlumno,
    searchChangeRecibidosAlumno,
    onSortChangeRecibidosAlumno,
    filtroMesInicioRecibidosAlumno,
    filtroMesFinRecibidosAlumno,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FILTRO_MES_INICIO_ENVIADOS]: (state, { mes_inicio_filtro_enviados }) => {
        return {
            ...state,
            mes_inicio_filtro_enviados,
        };
    },
    [FILTRO_MES_FIN_ENVIADOS]: (state, { mes_fin_filtro_enviados }) => {
        return {
            ...state,
            mes_fin_filtro_enviados,
        };
    },
    // Comunicados del profesor recibidos
    [DATA_COMUNICADOS_RECIBIDOS]: (state, { data_recibidos }) => {
        return {
            ...state,
            data_recibidos,
        };
    },
    [PAGE_COMUNICADOS_RECIBIDOS]: (state, { page_recibidos }) => {
        return {
            ...state,
            page_recibidos,
        };
    },
    [ORDERING_COMUNICADOS_RECIBIDOS]: (state, { ordering_recibidos }) => {
        return {
            ...state,
            ordering_recibidos,
        };
    },
    [SEARCH_COMUNICADOS_RECIBIDOS]: (state, { search_recibidos }) => {
        return {
            ...state,
            search_recibidos,
        };
    },
    [FILTRO_MES_INICIO_COMUNICADOS_RECIBIDOS]: (state, { mes_inicio_filtro_recibidos }) => {
        return {
            ...state,
            mes_inicio_filtro_recibidos,
        };
    },
    [FILTRO_MES_FIN_COMUNICADOS_RECIBIDOS]: (state, { mes_fin_filtro_recibidos }) => {
        return {
            ...state,
            mes_fin_filtro_recibidos,
        };
    },
    // Comunicados del alumno recibidos
    [DATA_COMUNICADOS_RECIBIDOS_ALUMNO]: (state, { data_recibidos_alumnos }) => {
        return {
            ...state,
            data_recibidos_alumnos,
        };
    },
    [PAGE_COMUNICADOS_RECIBIDOS_ALUMNO]: (state, { page_recibidos_alumnos }) => {
        return {
            ...state,
            page_recibidos_alumnos,
        };
    },
    [ORDERING_COMUNICADOS_RECIBIDOS_ALUMNO]: (state, { ordering_recibidos_alumnos }) => {
        return {
            ...state,
            ordering_recibidos_alumnos,
        };
    },
    [SEARCH_COMUNICADOS_RECIBIDOS_ALUMNO]: (state, { search_recibidos_alumnos }) => {
        return {
            ...state,
            search_recibidos_alumnos,
        };
    },
    [FILTRO_MES_INICIO_COMUNICADOS_RECIBIDOS_ALUMNO]: (state, { mes_inicio_filtro_recibidos_alumnos }) => {
        return {
            ...state,
            mes_inicio_filtro_recibidos_alumnos,
        };
    },
    [FILTRO_MES_FIN_COMUNICADOS_RECIBIDOS_ALUMNO]: (state, { mes_fin_filtro_recibidos_alumnos }) => {
        return {
            ...state,
            mes_fin_filtro_recibidos_alumnos,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    mes_inicio_filtro_enviados: moment().startOf('month'),
    mes_fin_filtro_enviados: moment().endOf('month'),
    // Comunicados del profesor recibidos
    data_recibidos: {
        results: [],
        count: 0,
    },
    page_recibidos: 1,
    ordering_recibidos: '',
    search_recibidos: '',
    mes_inicio_filtro_recibidos: moment().startOf('month'),
    mes_fin_filtro_recibidos: moment().endOf('month'),
    // Comunicados del profesor recibidos
    data_recibidos_alumnos: {
        results: [],
        count: 0,
    },
    page_recibidos_alumnos: 1,
    ordering_recibidos_alumnos: '',
    search_recibidos_alumnos: '',
    mes_inicio_filtro_recibidos_alumnos: moment().startOf('month'),
    mes_fin_filtro_recibidos_alumnos: moment().endOf('month'),
};

export default handleActions(reducers, initialState);
