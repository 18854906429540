import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/profesor/alumnosProfesor';
import FormsAlumnosGrid from './FormsAlumnosGrid';


const ms2p = (state) => {
  return {
    me: state.login.me,
    ...state.alumnosProfesor,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(FormsAlumnosGrid);
