import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import {AsyncSelectField, renderField, SelectField, renderCurrency, renderNumber, renderPasswordField, renderFieldRadio, renderDatePicker, renderTimeField} from '../../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { generos, EJERCICIO, EXAMEN } from "../../../../../utility/constants";
import CardArchivo from 'Utils/Cards/CardArchivo';
let PasoTresForm = props => {
    const { handleSubmit, setStep, ver_password, change_ver_password, tipo_ejercicio, previousStep, elegirArchivos} = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Selecciona los archivos que se cargaran en el formulario</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-12 px-0 m-0">
                    {
                        (props.archivos) && (
                            props.archivos.map((item, index) => (
                                <div className="col-md-3 col-6 mt-3" key={index}>
                                    <CardArchivo
                                        index={index}
                                        item={item.archivo}
                                        nombre={item.archivo.nombre}
                                        tipo={item.archivo.tipo}
                                        extension={item.archivo.extension}
                                        // seleccionar={props.seleccionar}
                                        // seleccionado={seleccionado ? seleccionado.id == item.id : false }
                                        eliminar={props.eliminarArchivo}
                                    />
                                </div>
                            ))
                        )
                    }
                    <div className="row col-12 d-flex justify-content-center mt-5 px-0 m-0">
                        <button type="button" onClick={elegirArchivos} className="btn btn-dark">Seleccionar archivo</button>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <button onClick={previousStep} className="btn btn-secondary btn-block">Regresar</button>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};

const selector = formValueSelector('formularioForm');

PasoTresForm = reduxForm({
    form: 'formularioForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(PasoTresForm);

const mstp = state => {
    const archivos = selector(state, 'archivos');

    return {
        archivos,
    }
};

export default connect(mstp, null)(PasoTresForm);
