import { ENLACE, EXTENSIONES, EJERCICIO } from './constants';


export const enlace = require('../../assets/img/iconos/enlace.png');
export const excel = require('../../assets/img/iconos/excel.png');
export const otro = require('../../assets/img/iconos/otro_archivo.png');
export const pdf = require('../../assets/img/iconos/pdf.png');
export const presentacion = require('../../assets/img/iconos/presentacion.png');
export const video = require('../../assets/img/iconos/video.png');
export const word = require('../../assets/img/iconos/word.png');

export function getIcono(tipo, extension) {
    if ( tipo == ENLACE ) {
        return enlace;
    } else {
        const item = _.find(EXTENSIONES, { value: extension });
        if (item) {
            if (item.label == 'excel') return excel;
            if (item.label == 'pdf') return pdf;
            if (item.label == 'presentacion') return presentacion;
            if (item.label == 'video') return video;
            if (item.label == 'word') return word;
        }
        return otro;
    }
}

export const ejercicio = require('../../assets/img/iconos/ejercicio.png');
export const examen = require('../../assets/img/iconos/examen.png');

export function getIconoForm(tipo) {
    if ( tipo == EJERCICIO ) {
        return ejercicio;
    }
    return examen;
}
