import React, {Component, Fragment} from 'react';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux'
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import LoadMask from "../LoadMask/LoadMask";
import StarIcon from '@material-ui/icons/Star';
import { renderTextArea, renderRating, renderField, SelectField } from '../renderField/renderField';
import { CLASE_INDIVIDUAL_CUPONES, CLASE_GRUPAL_CUPONES } from '../../../../utility/constants';
import moment from 'moment';

let EnviarCuponForm = props => {
    const { loader,
        handleSubmit,
        open,
        onClose,
        cupon,
        cursos_grupales,
        tipo_curso,
        fecha_final,
        item,
    } = props;

    const _cursos_grupales = [];
    cursos_grupales.forEach(option => {
        _cursos_grupales.push({label: option["curso_grupal"]["titulo"], value: option["curso_grupal"]["id"] });
    });
    return(
        <Modal open={open} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={false} classNames={{modal: "d-flex align-items-center"}}>
            <div className="col-12">
                <LoadMask loading={loader} blur>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group grid-container">
                            <div className="col-12">
                                <span className="text-sm azul-claro bold">Envio de codigo {cupon}</span>
                                <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                            </div>
                            <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                                <div className="col-md-12 p-0">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="email" className="m-0">Correo *</label>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <Field name="email" component={renderField} type="email" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            {tipo_curso === CLASE_GRUPAL_CUPONES && (
                                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                                    <div className="col-md-12 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="curso_grupal" className="m-0">Curso *</label>
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <Field
                                                name="curso_grupal"
                                                component={SelectField}
                                                options={_cursos_grupales}
                                                placeholder="Seleccione un curso..."
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                                <div className="col-md-12 p-0">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="texto" className="m-0">Contenido *</label>
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <Field name="texto" component={renderTextArea} />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                                <div className="col-md-6 col-12">
                                    <button className="btn btn-secondary btn-block"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onClose();
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                </div>
                                <div className="col-md-6 col-12 mt-md-0 mt-3">
                                    <button type="submit" className="btn btn-primary btn-block">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </LoadMask>
            </div>
        </Modal>
    )
}

EnviarCuponForm = reduxForm({
    form: 'EnviarCuponForm',
    validate: data => {
        return validate(data, {
            'email': validators.exists()('Campo requerido.')
        })
    },
})(EnviarCuponForm);

const selector = formValueSelector('EnviarCuponForm');
EnviarCuponForm = connect((state, props) => {
    const form = state.form.EnviarCuponForm;
    if (form && form.values) {
        const values = form.values;
        values.cupon = props.cupon ? props.cupon : null;
        values.fecha_final = props.fecha_final ? props.fecha_final : null;
    }
})(EnviarCuponForm);

export default EnviarCuponForm;
