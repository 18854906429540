import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_CREDITOS_ALUMNO';
const DATA = 'DATA_CREDITOS_ALUMNO';
const DATA2 = 'DATA2_CREDITOS_ALUMNO';
const DATA3 = 'DATA3_CREDITOS_ALUMNO';
const PAGE3 = 'PAGE3_CREDITOS_ALUMNO';
const ALUMNO = 'ALUMNO_CREDITOS_ALUMNO';
const AULA = 'AULA_CREDITOS_ALUMNO';
const FECHA_INICIO = 'FECHA_INICIO_CREDITOS_ALUMNO';
const FECHA_FIN = 'FECHA_FIN_CREDITOS_ALUMNO';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});


// ------------------------------------
// Actions
// ------------------------------------

const listarGrafica = () => (dispatch, getStore) => {
    const resource = getStore().creditosAlumno;
    const params = {};
    if (resource.alumno)
        params.alumno = resource.alumno.id;
    if (resource.aula)
        params.aula = resource.aula.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format('YYYY-MM-DD');
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format('YYYY-MM-DD');
    dispatch(setLoader(true));
    api.get('reportes/creditos_grafica', params).then((response) => {
        let titulo = ['Mes'];
        let data = [];
        response.results.forEach((item, index) => {
            const pos = [];
            for (const prop in item) {
                if (index == 0) {
                    if (prop != 'fecha') titulo.push(prop);
                }
                if (prop == 'fecha') {
                    pos.push(moment(item[prop]).format('MMM/YYYY'));
                } else {
                    pos.push(item[prop]);
                }
            }
            data.push(pos);
        });
        titulo = [ titulo ]
        data = titulo.concat(data)
        console.log("datita ", data);
        dispatch(setData(DATA, data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarTabla = () => (dispatch, getStore) => {
    const resource = getStore().creditosAlumno;
    const params = {};
    if (resource.alumno)
        params.alumno = resource.alumno.id;
    if (resource.aula)
        params.aula = resource.aula.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format('YYYY-MM-DD');
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format('YYYY-MM-DD');
    dispatch(setLoader(true));
    api.get('reportes/creditos_tabla', params).then((response) => {
        console.log(response);
        const data = [];
        for (const item of response) {
            for (const mes of item.creditos) {
                data.push({...item, fecha: mes.fecha, creditos: mes.creditos})
            }
        }
        dispatch(setData(DATA2, data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarForms = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().creditosAlumno;
    const me = getStore().login.me;
    const params = { page, calificado: true };
    if (resource.alumno)
        params.alumno = resource.alumno.id;
    if (resource.aula)
        params.formulario__aula = resource.aula.id;
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).startOf('month').format('YYYY-MM-DD');
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).endOf('month').format('YYYY-MM-DD');
    dispatch(setLoader(true));
    api.get('form_respuesta', params).then((response) => {
        dispatch(setData(DATA3, response));
        dispatch(setData(PAGE3, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeAlumno = value => (dispatch) => {
    dispatch(setData(ALUMNO, value));
    dispatch(listarGrafica());
    // dispatch(listarTabla());
    dispatch(listarForms());
};

const changeAula = value => (dispatch) => {
    dispatch(setData(AULA, value));
    dispatch(listarGrafica());
    // dispatch(listarTabla());
    dispatch(listarForms());
};

const changeFechaInicio = value => (dispatch) => {
    dispatch(setData(FECHA_INICIO, value));
    dispatch(listarGrafica());
    // dispatch(listarTabla());
    dispatch(listarForms());
};

const changeFechaFin = value => (dispatch) => {
    dispatch(setData(FECHA_FIN, value));
    dispatch(listarGrafica());
    // dispatch(listarTabla());
    dispatch(listarForms());
};

const resetearData = () => (dispatch) => {
    dispatch(setData(ALUMNO, null));
    dispatch(setData(AULA, null));
    dispatch(setData(DATA, [['Mes', 'Creditos'],['Sin data', 0]]));
    dispatch(setData(DATA2, []));
    dispatch(setData(DATA3, {
        results: [],
        count: 0,
    }));

}

const miAvance = () => (dispatch) => {
    dispatch(resetearData());
    dispatch(listarGrafica());
    dispatch(listarTabla());
}

export const actions = {
    listarGrafica,
    listarTabla,
    changeAlumno,
    changeAula,
    changeFechaInicio,
    changeFechaFin,
    resetearData,
    miAvance
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [DATA3]: (state, { data }) => {
        return {
            ...state,
            data3: data,
        };
    },
    [PAGE3]: (state, { data }) => {
        return {
            ...state,
            page3: data,
        };
    },
    [ALUMNO]: (state, { data }) => {
        return {
            ...state,
            alumno: data,
        };
    },
    [AULA]: (state, { data }) => {
        return {
            ...state,
            aula: data,
        };
    },
    [FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: [['Mes', 'Creditos'],['Sin data', 0]],
    data2: [],
    data3: {
        results: [],
        count: 0,
    },
    page3: 1,
    alumno: null,
    aula: null,
    fecha_inicio: moment().startOf('M').subtract(1, 'M'),
    fecha_fin: moment().startOf('M'),
};

export default handleActions(reducers, initialState);

