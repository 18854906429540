import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {NotificationManager} from "react-notifications";
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import moment from 'moment';


export default class PrecargarExamen extends Component {

    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }

    handleSubmit = () => {

    }

    realizarExamen = () => {
        const id = this.props.match.params.id;
        const { item2 } = this.props;
        const habilitacion = moment(item2.fecha_hora_examen);
        const limite = moment(item2.fecha_hora_examen).add(item2.tiempo_maximo, 'h');

        if (moment() < habilitacion) {
            NotificationManager.error("El exámen aun no está disponible", 'ERROR');
        } else if (moment() >= limite) {
            NotificationManager.error("El exámen ya no se encuentra disponible", 'ERROR');
        } else {
            this.props.history.push(`/examen/${id}/resolver`);
        }
    }

    render() {
        const { loader, item2 } = this.props;
        const id = this.props.match.params.id;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={'12'}>
                    <LoadMask loading={loader} blur>
                        <div className="form-validate mb-lg">
                            <div className="d-flex flex-lg-row flex-column bg-celeste-claro px-lg-4 px-3 py-3"
                                style={{ borderRadius: "15px" }}>
                                <div className="d-flex flex-3 flex-column">
                                    <span className="azul-claro bold h5">Nombre de la clase</span>
                                    <label className="m-0">{item2.clase_text}</label>
                                </div>
                                <div className="d-flex flex-2 flex-column">
                                    <span className="azul-claro bold h5">Fecha</span>
                                    <label className="m-0">{moment(item2.fecha_hora_examen).format('DD/MM/YYYY')}</label>
                                </div>
                                <div className="d-flex flex-2 flex-column">
                                    <span className="azul-claro bold h5">Hora Inicio</span>
                                    <label className="m-0">{moment(item2.fecha_hora_examen).format('HH:mm')}</label>
                                </div>
                                <div className="d-flex flex-2 flex-column">
                                    <span className="azul-claro bold h5">Tiempo máximo</span>
                                    <label className="m-0">
                                        <RenderNumber value={item2.tiempo_maximo} decimalScale={2} suffix=" horas" />
                                    </label>
                                </div>
                                <div className="d-flex flex-2 flex-column align-items-center justify-content-center">
                                    <button type="button" onClick={this.realizarExamen} className="btn btn-primary">Iniciar exámen</button>
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-5">
                                <div className="row col-12">
                                    <div className="col-12 mb-3">
                                        <span className="text-sm azul-claro bold">
                                            {`Exámen: ${item2.formulario ? item2.formulario.titulo : ''}`}
                                        </span>
                                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                                    </div>
                                    <div className="col-12 mb-3 text-center py-5">
                                        <h2>
                                            Debes de hacer click en el botón para iniciar examen
                                        </h2>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
