import React, {Component, Fragment} from 'react';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux'
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import LoadMask from "../LoadMask/LoadMask";
import StarIcon from '@material-ui/icons/Star';
import { renderTextArea, renderRating } from '../renderField/renderField';
import moment from 'moment';

const avatar = require("assets/img/avatar-placeholder.png");

let ComentarioForm = props => {
    const { loader, handleSubmit, item, open, onClose, tipo} = props;
    const clase_a_calificar = item && item.clase_a_calificar ? item.clase_a_calificar : [];
    const profesor = item && item.clase_a_calificar && item.clase_a_calificar.aula && item.clase_a_calificar.aula.profesor ? item.clase_a_calificar.aula.profesor : [];
    return(
        <Modal open={open} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={false} classNames={{modal: "d-flex align-items-center"}}>
            <div className="col-12">
                <LoadMask loading={loader} blur>
                    <form onSubmit={handleSubmit}>
                        <div className="col-12 d-flex justify-content-center m-0 comentarios">
                            <div className="col-sm-12 col-12 px-0">
                                <div className="col-12 mt-3">
                                    <div className="px-0 h-100 d-flex justify-content-between">
                                        <div className="col-5 d-flex align-items-center justify-content-center">
                                            <img className='img-avatar-comentario' src={profesor.avatar || avatar} alt="Avatar" />
                                        </div>
                                        <div className="d-flex bg-gris px-0" style={{width: '3px'}}/>
                                        <div className="col-5 d-flex align-items-center">
                                            <div className="col-12 d-flex flex-column px-0">
                                                <span className="mt-2 bold azul">Profesor</span>
                                                <span className="">{profesor && profesor.nombres && profesor.apellidos ? profesor.nombres + ' ' + profesor.apellidos : ""}</span>
                                                <span className="mt-2 bold azul">Curso</span>
                                                <span className="">{clase_a_calificar && clase_a_calificar.titulo ? clase_a_calificar.titulo : ""}</span>
                                                <span className="mt-2 bold azul">Fecha y hora fin</span>
                                                <span className="">{clase_a_calificar && clase_a_calificar.fecha_hora_fin ? moment(clase_a_calificar.fecha_hora_fin).format('DD-MM-YYYY HH:mm') : ""}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center mt-5">
                                    <div className="col-md-9 col-12 text-center px-0">
                                        <h3 className="bold azul">La calidad educativa siempre la tenemos en cuenta.</h3>
                                        <span className="bold">{item.nombres}, ¿Podríais indicar tu puntuación de la clase PASADA? Muchas gracias.</span>
                                    </div>
                                </div>
                                <div className="mt-4 col-12">
                                    <div className="col-12 px-0 text-center">
                                        <span className="bold text-comentario">Experiencia</span>
                                    </div>
                                    <div className="col-12 px-0 text-center">
                                        <Field component={renderRating} name="calificacion" size='large'/>
                                    </div>
                                </div>
                                <div className="mt-3 col-12">
                                    <div className="col-12 px-0">
                                        <span className="bold text-comentario">Comentario</span>
                                    </div>
                                    <div className="col-12 px-0 text-center">
                                        <Field component={renderTextArea} name="comentario" rows={5}/>
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="row">
                                        {/* <div className="col-6">
                                            <button className="btn btn-secondary btn-block"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    onClose();
                                                }}
                                            >Cancelar</button>
                                        </div> */}
                                        <div className="col-md-3 col-12"></div>
                                        <div className="col-md-6 col-12">
                                            <button type="submit" className="btn btn-primary btn-block">Guardar comentario</button>
                                        </div>
                                        <div className="col-md-3 col-12"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </LoadMask>
            </div>
        </Modal>
    )
}

ComentarioForm = reduxForm({
    form: 'ComentarioForm',
    validate: data => {
        return validate(data, {
            'calificacion': validators.exists()('Campo requerido.')
        })
    },
    initialValues: {
        calificacion: 1
    }
})(ComentarioForm);

const selector = formValueSelector('ComentarioForm');
ComentarioForm = connect((state, props) => {
    const form_comment = state.form.ComentarioForm;

    if (form_comment && form_comment.values) {
        const values = form_comment.values;
        values.clase = props.item && props.item.clase_a_calificar && props.item.clase_a_calificar.id ? props.item.clase_a_calificar.id : null;
    }
})(ComentarioForm);

export default ComentarioForm;
