import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { SelectField, renderTimeField, renderEditorField, renderDatePicker } from '../../../Utils/renderField';
import { DIAS_SEMANA } from '../../../../../utility/constants';


const EventoEditForm = (props) => {
    const url = window.location.href;
    const { handleSubmit, cancelar, evento } = props;
    return (
        <form name="EventoEditForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group grid-container mx-md-5">
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-12 mb-3">
                        <span className="text-sm azul-claro bold">{`Temario para la clase ${evento && evento.titulo}`}</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="d-flex flex-md-row flex-column align-items-center w-100 px-4 px-md-0">
                        <div className="d-flex flex-1 flex-column mr-3 w-100 mx-3">
                            <div className="flex-1">
                                <label htmlFor="fecha" className="m-0">Fecha</label>
                            </div>
                            <div className="flex-1">
                                <Field name="fecha" component={renderDatePicker} />
                            </div>
                        </div>
                        <div className="d-flex flex-1 flex-column mx-2 w-100">
                            <div className="flex-1">
                                <label htmlFor="hora_inicio" className="m-0">Hora inicio</label>
                            </div>
                            <div className="flex-1">
                                <Field name="hora_inicio" component={renderTimeField} />
                            </div>
                        </div>
                        <div className="d-flex flex-1 flex-column mx-3 w-100">
                            <div className="flex-1">
                                <label htmlFor="hora_fin" className="m-0">Hora fin</label>
                            </div>
                            <div className="flex-1">
                                <Field name="hora_fin" component={renderTimeField} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end mt-3 px-0">
                    <div className="d-flex flex-row align-items-center w-100 mt-3">
                        <div className="d-flex flex-1 justify-content-center align-items-center">
                            <Field name="contenido" height="10%" height2="70%" component={renderEditorField} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-5">
                    <div className="col-md-4 col-12">
                        {
                            url.includes("editar") ? (
                                <Link to={`/cursos/${evento.aula}`} type="button" className="btn btn-secondary btn-block">Cancelar</Link>
                            ) : (
                                <Link to="/" type="button" className="btn btn-secondary btn-block">Cancelar</Link>
                            )

                        }
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Siguiente</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'EventoEditForm',
    asyncBlurFields: [],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: data => {
        return validate(data, {
            'dia': validators.exists()('Campo requerido.'),
            'hora_inicio': validators.exists()('Campo requerido.'),
            'hora_fin': validators.exists()('Campo requerido.'),
        })
    }
})(EventoEditForm);


