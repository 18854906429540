import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/profesor/horasTrabajadas';
import DetalleHorasTrabajadas from './DetalleHorasTrabajadas';


const ms2p = (state) => {
    return {
        ...state.horasTrabajadas,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleHorasTrabajadas);
