import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form'
import alumnos from "./modules/alumnos";
import cursosAlumno from "./modules/alumnos/cursos";
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import pasarela from './modules/cuenta/pasarela';
import notificaciones from './modules/cuenta/notificaciones';
import tarjetas from './modules/cuenta/tarjetas';
import usuarios from './modules/usuarios/usuarios';
import configComercial from './modules/configComercial';
import profesor from './modules/profesor';
import alumnosProfesor from './modules/profesor/alumnosProfesor';
import formsProfesor from './modules/profesor/formularios';
import puntuaciones from './modules/profesor/puntuaciones';
import horasTrabajadas from './modules/profesor/horasTrabajadas';
import cursosProfe from './modules/cursos/cursosProfe';
import creditosAlumno from './modules/cursos/creditosAlumno';
import formsAlumno from './modules/alumnos/formularios';
import colaboradores from './modules/colaboradores';
import reporteCupones from './modules/colaboradores/reporteCupones';
import niveles from './modules/biblioteca';
import archivos from './modules/biblioteca/archivos';
import formularios from './modules/biblioteca/formularios';
import perfil from './modules/perfil';
import comunicados from './modules/comunicados';

export default combineReducers({
    form: formReducer,
    login,
    alumnos,
    cursosAlumno,
    register,
    profile,
    pasarela,
    usuarios,
    profesor,
    colaboradores,
    reporteCupones,
    notificaciones,
    configComercial,
    cursosProfe,
    alumnosProfesor,
    formsProfesor,
    puntuaciones,
    horasTrabajadas,
    comunicados,
    formsAlumno,
    creditosAlumno,
    niveles,
    archivos,
    formularios,
    perfil,
    tarjetas,
    routing,
});
