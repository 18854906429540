import React, { Component } from 'react';
import { api } from "api";

export default class HeaderFiltros extends Component {
    render() {
        const { filtro1, filtro2, filtro3, filtro4, filtro5 } = this.props;

        return (
            <div className="d-flex w-100">
                <div className="d-flex flex-1 flex-column flex-lg-row row col-12 mb-3 px-1">
                    <div className="d-flex flex-column flex-1 mx-1">
                        {filtro1 && filtro1}
                    </div>
                    <div className="d-flex flex-column flex-1 mx-1 my-2 my-lg-0">
                        {filtro2 && filtro2}
                    </div>
                    <div className="d-flex flex-column flex-1 mx-1 my-2 my-lg-0">
                        {filtro3 && filtro3}
                    </div>
                    <div className="d-flex flex-column flex-1 mx-1 my-2 my-lg-0">
                        {filtro4 && filtro4}
                    </div>
                    <div className="d-flex flex-column flex-1 mx-1">
                        {filtro5 && filtro5}
                    </div>
                </div>
            </div>
        )
    }
}
