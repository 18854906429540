import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PasoUnoForm from './PasoUnoForm';
import PasoDosForm from './PasoDosForm';
import PasoTresForm from './PasoTresForm';
import PasoPagoForm from './PasoPagoForm';
import CardForm from '../../Utils/Cards/CardForm';
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { capitalize } from "../../../../utility/helpers";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { api } from 'api';
import queryString from 'query-string'


const logo = require("assets/img/logo.png");
const mundo = require("assets/img/Isotipo.png");
const check = require("assets/img/icons/check.png");

class Registro extends Component {
    state = {
        step: 1,
        ver_password: false,
        permitir_guardar: false,
        curso_grupal: true,
        curso: null
    };

    crearAlumno = (values) => {
        const idCurso = this.props.match.params.id;
        const data = { ...values };
        if (idCurso) {
            data.id_curso = idCurso;
        }
        this.props.crear(data)
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            this.setState({ curso_grupal: true });
            const idCurso = this.props.match.params.id
            api.get(`curso/${idCurso}`).then(response => {
                this.setState({ curso: response });
            }).catch();
        }

        // Parametros mandados desde la frontpage o desde el correo que mando el colaborador.
        const value = queryString.parse(this.props.location.search);
        if (value.cupon) {
            localStorage.setItem('cupon', value.cupon);
        }
        if (value.id_curso) {
            localStorage.setItem('curso', value.id_curso);
        }
        if (value.tipo_curso) {
            localStorage.setItem('tipo_curso', value.tipo_curso);
        }
    }

    crearAlumno = (data) => {
        if (this.props.match.params.id) {
            this.setState({ step: this.state.step + 1 })
            this.props.crear(data, this.props.match.params.id)
        }
        else {
            this.props.crear(data)
        }
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep = () => {
        this.setState({ step: this.state.step - 1 })
    };

    change_ver_password = () => {
        this.setState({ ver_password: !this.state.ver_password });
    }

    componentWillUnmount() {
        this.props.cleanForm()
    }

    callbackFacebookLogin = (data) => {
        const { socialLoginRegistro } = this.props;
        if (!!data && data.status !== "unknown") {
            const body = {
                email: data.email,
                nombres: capitalize(data.first_name.toLowerCase()),
                apellidos: capitalize(data.last_name.toLowerCase()),
            }
            socialLoginRegistro(body, 'Facebook');
            console.log("Facebook", body)
        }
    }

    handleGoogleSuccess = (data) => {
        const { socialLoginRegistro } = this.props;
        if (data) {
            const body = {
                email: data.profileObj.email,
                nombres: capitalize(data.profileObj.givenName.toLowerCase()),
                apellidos: capitalize(data.profileObj.familyName.toLowerCase()),
            }
            socialLoginRegistro(body, 'Google');
            console.log("Google", body)
        }
    }

    handleGoogleFailure = (data) => {
        console.log("Error", data);
    }

    onChangeCaptch = (value) => {
        this.setState({ permitir_guardar: true });
    }

    onExpiredCaptch = () => {
        this.setState({ permitir_guardar: false });
    }

    onErroredCaptch = () => {
        this.setState({ permitir_guardar: false });
    }

    render() {
        const { loader, crear } = this.props;
        const { step } = this.state;
        const idCurso = this.props.match.params.id;

        if (sessionStorage.getItem('token')) {
            return (<Redirect to={idCurso ? `/inicio/${idCurso}` : "/"} />);
        }
        return (
            <div className="container">
                <div className="main-navbar-fixed bg-celeste">
                    <div className="p-0">
                        <nav className="align-items-stretch flex-md-nowrap p-0 navbar">
                            <div className="w-100 mr-md-4 mr-sm-4 ml-lg-4">
                                <div className="d-flex align-items-center justify-content-between w-100 mt-2 mt-sm-3">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <h1 className="ml-1 azul-claro">Registro / <span className="text-lg regular">Registro de Alumno</span></h1>
                                    </div>
                                    <img src={mundo} alt="" width="30" className="mr-2 d-sm-inline d-md-none d-lg-none" />
                                    <img src={logo} alt="" width="120" className="mr-1 d-xsm-none d-sm-none d-md-inline d-lg-inline" />
                                </div>
                                <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="mb-4 col-12">
                    <div className="mb-5 mt-5 col-12 row d-flex justify-content-center">
                        <div className="col-sm-8 col-10 px-0">
                            <ProgressBar
                                percent={step === 1 ? 0 : step === 2 ? 50 : 100}
                                filledBackground="#686A75"
                                unfilledBackground="#686A75"
                                stepPositions={[0, 50, 100]}
                                height={5}
                            >
                                <Step transition="scale">
                                    {({ accomplished, index, position }) => (
                                        <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                {index + 1 === step ?
                                                    <div className="step-number">
                                                        <div className="step-dot" />
                                                    </div>
                                                    :
                                                    (!!accomplished ?
                                                        <div className="step-checked d-flex align-items-center justify-content-center">
                                                            <img src={check} alt="" width="15" height="15" />
                                                        </div>
                                                        :
                                                        <div className="step-number">
                                                        </div>
                                                    )
                                                }
                                                <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Contraseña y usuario</span>
                                            </div>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished, index, position }) => (
                                        <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                {index + 1 === step ?
                                                    <div className="step-number">
                                                        <div className="step-dot" />
                                                    </div>
                                                    :
                                                    (!!accomplished ?
                                                        <div className="step-checked d-flex align-items-center justify-content-center">
                                                            <img src={check} alt="" width="15" height="15" />
                                                        </div>
                                                        :
                                                        <div className="step-number">
                                                        </div>
                                                    )
                                                }
                                                <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Datos personales</span>
                                            </div>
                                        </div>
                                    )}
                                </Step>
                                <Step transition="scale">
                                    {({ accomplished, index, position }) => (
                                        <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                            <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: 110 }}>
                                                {index + 1 === step ?
                                                    <div className="step-number">
                                                        <div className="step-dot" />
                                                    </div>
                                                    :
                                                    (!!accomplished ?
                                                        <div className="step-checked d-flex align-items-center">
                                                            <img src={check} alt="" width="15" height="15" />
                                                        </div>
                                                        :
                                                        <div className="step-number">
                                                        </div>
                                                    )
                                                }
                                                <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul ocultar-texto-movil' : 'gris-oscuro ocultar-texto-movil')}`}>Objetivo de la clase</span>
                                            </div>
                                        </div>
                                    )}
                                </Step>
                            </ProgressBar>
                            {/* <div className="mt-5 d-flex justify-content-center">
                                <button className="btn btn-secondary mr-3" onClick={this.previousStep}>Anterior</button>
                                <button className="btn btn-primary" onClick={this.nextStep}>Siguiente</button>
                            </div> */}
                        </div>
                    </div>
                    <CardForm>
                        <LoadMask loading={loader} blur>
                            <Fragment>
                                {step === 1 && (<PasoUnoForm onSubmit={this.nextStep}
                                    change_ver_password={this.change_ver_password}
                                    ver_password={this.state.ver_password}
                                    onFacebookLogin={this.callbackFacebookLogin}
                                    onGoogleSuccess={this.handleGoogleSuccess}
                                    onGoogleFailure={this.handleGoogleFailure}
                                    idCurso={idCurso}/>
                                )}
                                {step === 2 && (<PasoDosForm onSubmit={this.nextStep} previousStep={this.previousStep} />)}
                                {step === 3 && (<PasoTresForm
                                    onSubmit={this.crearAlumno}
                                    permitir_guardar={this.state.permitir_guardar}
                                    onChangeCaptch={this.onChangeCaptch}
                                    onExpiredCaptch={this.onExpiredCaptch}
                                    onErroredCaptch={this.onErroredCaptch}
                                    previousStep={this.previousStep} />
                                )}
                            </Fragment>
                        </LoadMask>
                    </CardForm>
                </div>
            </div>
        );
    }
}

export default Registro;
