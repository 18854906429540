import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import { ENLACE, ARCHIVO } from '../../../../../utility/constants';
import { getIcono } from '../../../../../utility/iconos';
import CardArchivo from '../../../Utils/Cards/CardArchivo';
import moment from 'moment';

import EjercicioForm from './Form';

const descargar = require('../../../../../../assets/img/iconos/descargar.png');
const link = require('../../../../../../assets/img/iconos/link.png');

export default class ResolverEjercicio extends Component {

    state = {
        seleccionado: null,
    }

    componentWillMount() {
        this.props.crearObtenerForm(this.props.match.params.id);
    };

    seleccionar = (index, item) => {
        this.setState({ seleccionado: item });
    };

    handleSubmit = (data) => {
        const { enviarForm, item } = this.props;
        enviarForm(item.id, data);
    };

    guardarExamen = () => {
        console.log("guardar")
        const { guardarEjercicio, item } = this.props;
        const { form: { values } } = this.props;
        guardarEjercicio(item.id, values);
    }

    render() {
        const { loader, item } = this.props;
        const { seleccionado } = this.state;
        const id = this.props.match.params.id;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={'12'}>
                    <LoadMask loading={loader} blur>
                        <div className="form-validate mb-lg">
                            <div className="d-flex flex-lg-row flex-column bg-celeste-claro px-lg-4 px-3 py-3"
                                style={{ borderRadius: "15px" }}>
                                <div className="d-flex flex-3 flex-column justify-content-center">
                                    <span className="azul-claro bold h5">
                                        {item.formulario ? item.formulario.formulario.titulo : ''}
                                    </span>
                                </div>
                                <div className="d-flex flex-3 flex-column justify-content-center">
                                    <span className="azul-claro bold h5">Nombre de la clase</span>
                                    <label className="m-0">{item.formulario ? item.formulario.clase ? item.formulario.clase.titulo : 'Asignación individual' : ''}</label>
                                </div>
                                <div className="d-flex flex-2 flex-column justify-content-center">
                                    <span className="azul-claro bold h5">Punteo</span>
                                    <label className="m-0">
                                        <RenderNumber
                                            value={item.formulario ? item.formulario.formulario.punteo : 0}
                                            decimalScale={2}
                                        />
                                    </label>
                                </div>
                                <div className="d-flex flex-2 flex-column justify-content-center">
                                    <span className="azul-claro bold h5">Fecha y hora limite</span>
                                    <label className="m-0">
                                        {item.formulario ? moment(item.formulario.fecha_hora_entrega).format("DD/MM/YYYY HH:mm") : ''}
                                    </label>
                                </div>
                            </div>
                            {/* ARCHIVOS */}
                            <div className="d-flex flex-row mt-4">
                                <div className="col-12 px-0">
                                    <div className="col-12 mb-3">
                                        <span className="text-sm azul-claro bold">Archivos</span>
                                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                                    </div>
                                    <div className="col-12 mb-3 text-center borde-gris-archivos py-2">
                                        {/* ARCHIVOS */}
                                        <div className="row col 12 px-0">
                                            <div className="col-md-5 p-0 px-lg-4 archivo-group pt-md-5">
                                                <div className="row col-12 align-items-center justify-content-center px-0 mx-0">
                                                    <div className="col-lg-2 col-2 px-1">
                                                        {
                                                            seleccionado && (
                                                                <img style={{ width: "100%", height: "100%" }} src={getIcono(seleccionado.archivo.tipo, seleccionado.archivo.extension)} />
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-lg-8 col-8 px-lg-2 px-1 ">
                                                        {
                                                            seleccionado && (
                                                                <div className="form-control">
                                                                    <span>{seleccionado.archivo.nombre}</span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="col-lg-2 col-2 px-lg-2 px-0">
                                                        {
                                                            (seleccionado) && (
                                                                (seleccionado.archivo.tipo == ARCHIVO) ? (
                                                                    <div className="d-flex flex-md-row flex-column">
                                                                        <a
                                                                            download
                                                                            target="_blank"
                                                                            style={{ cursor: "pointer" }}
                                                                            href={seleccionado.archivo.archivo}
                                                                            className="d-flex flex-1"
                                                                        >
                                                                            <img className="icono-tool-archivo" src={descargar} />
                                                                        </a>
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex flex-md-row flex-column">
                                                                        <a
                                                                            target="_blank"
                                                                            style={{ cursor: "pointer" }}
                                                                            href={seleccionado.archivo.enlace}
                                                                            className="d-flex flex-1"
                                                                        >
                                                                            <img className="icono-tool-archivo" src={link} />
                                                                        </a>
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-7 p-0 mt-3 mt-md-0 " style={{ height: "25vh", overflowY: "auto" }}>
                                                <div className="row col-12">
                                                    {
                                                        (item.archivos) && (
                                                            item.archivos.map((item, index) => (
                                                                <div className="col-md-3 col-6 mt-3" key={index}>
                                                                    <CardArchivo
                                                                        index={index}
                                                                        item={item}
                                                                        nombre={item.archivo.nombre}
                                                                        tipo={item.archivo.tipo}
                                                                        extension={item.archivo.extension}
                                                                        seleccionar={this.seleccionar}
                                                                        seleccionado={seleccionado ? seleccionado.id == item.id : false}
                                                                    />
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NOTAS */}
                            <div className="d-flex flex-row mt-4">
                                <div className="d-flex flex-row w-100 px-0">
                                    <EjercicioForm
                                        item={item}
                                        onSubmit={this.handleSubmit}
                                        guardarExamen={this.guardarExamen}
                                    />
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
