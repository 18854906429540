import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import Header from "Utils/Grid/Header";

export default class RecuperacionesAlumno extends Component{

    componentWillMount() {
        const { listarRecuperaciones } = this.props;
        listarRecuperaciones();
    }

    render() {
        const { listarRecuperaciones: onPageChange, onSortChange } = this.props; //Funciones
        const { data2, loader, page2 } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header />
                <Card>
                    <Grid hover striped data={data2} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page2}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='10%'
                            dataFormat={standardActions({})}
                        />
                        <TableHeaderColumn
                            dataField="aula"
                        >
                            Curso
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="profesor"
                            dataFormat={ cell => cell.nombres}
                        >
                            Profesor
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="horas_pendientes"
                            dataFormat={ cell => <RenderNumber value={cell} suffix=" horas" /> }
                        >
                            Tiempo
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
