import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment from 'moment';
import { ESTADOS_EVENTOS } from '../../../../../utility/constants';

export default class CursosAlumno extends Component{

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.listar_clases(this.props.match.params.id);
            this.props.obtener_aula(this.props.match.params.id);
        }
    }

    onPageChange = (page) =>{
        const id = this.props.match.params.id;
        this.props.listar_clases(id, page);
    }

    searchChange = (search) => {
        const id = this.props.match.params.id;
        this.props.searchClaseChange(search, id);
    };

    changeEstadoEvento = (value) => {
        const id = this.props.match.params.id;
        this.props.changeEstadoEvento(value, id);
    };

    changeFiltroFecha = (value) => {
        const id = this.props.match.params.id;
        this.props.changeFiltroFecha(value, id);
    };

    render() {
        const { listar_cursos: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page, item } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <div className="col-12 d-flex justify-content-start align-items-center">
                    <h1 className="azul-claro bold">{item && item.nombre ? item.nombre : ""}</h1>
                </div>
                <Header
                    {...this.props}
                    search={this.props.search_clase}
                    searchChange={this.searchChange}
                    filtro1={
                        <Select
                            isClearable={true}
                            className={classNames('react-select-container')}
                            backspaceRemovesValue={false}
                            isSearchable={true}
                            options={ESTADOS_EVENTOS}
                            placeholder={"Filtrar por estado"}
                            value={this.props.estado_evento}
                            onChange={this.changeEstadoEvento}
                        />
                    }
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Filtrar por fecha"
                            onChange={(value) => {this.changeFiltroFecha(value)}}
                            selected={this.props.filtro_fecha}
                        />
                    }
                />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={this.onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='10%'
                            dataFormat={standardActions({ ver: "clase" })}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            Nombre de la clase
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha"
                            dataSort
                            dataFormat={ cell => moment(cell).format('DD/MM/YY') }
                        >
                            Fecha
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_hora_inicio"
                            dataSort
                            dataFormat={ cell => moment(cell).format('HH:mm') }
                        >
                            Hora inicio
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_hora_fin"
                            dataSort
                            dataFormat={ cell => moment(cell).format('HH:mm') }
                        >
                            Hora fin
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="estado_evento_text"
                            dataSort
                        >
                            Estado
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
