import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderTimeField, renderEditorField, renderDatePicker } from '../../../Utils/renderField';
import moment from 'moment';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';


const ReposicionForm = (props) => {
    const { handleSubmit, detalle_aula } = props;
    return (
        <form name="EventoEditForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group grid-container mx-md-5">
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-12 mb-3">
                        <span className="text-sm azul-claro bold">
                            {`Clase de recuperación para ${detalle_aula ? detalle_aula.nombre : ''}`}
                        </span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="row col-12 px-md-5">
                        <div className="col-md-6 p-0 pl-md-5">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="fecha" className="m-0">Fecha</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="fecha" component={renderDatePicker} />
                            </div>
                        </div>
                        <div className="col-md-6 p-0 pr-md-5">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="hora_inicio" className="m-0">Hora inicio</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="hora_inicio" component={renderTimeField} />
                            </div>
                        </div>
                    </div>
                    <div className="row col-12 px-md-5">
                        <div className="col-md-6 p-0 pl-md-5">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="hora_fin" className="m-0">Hora fin</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="hora_fin" component={renderTimeField} />
                            </div>
                        </div>
                        <div className="col-md-6 p-0 pr-md-5 d-flex justify-content-center align-items-center">
                            <div className="d-flex flex-md-row flex-column justify-content-center align-items-center">
                                <h2 className="mr-2">Horas pendientes</h2>
                                <RenderNumber
                                    value={detalle_aula ? detalle_aula.horas_pendientes_recuperar : 0}
                                    className="h2 rosa"
                                    suffix=' horas'
                                    decimalScale={2}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end mt-3 px-0">
                    <div className="d-flex flex-row align-items-center w-100 mt-3">
                        <div className="flex-1">
                            <Field name="contenido" height="25rem" height2="20rem" component={renderEditorField} />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5 px-md-5">
                    <Link to={`/cursos/${detalle_aula ? detalle_aula.id : 0}/`} type="button" className="btn btn-secondary mr-md-5 mr-3">Cancelar</Link>
                    <button type="submit" className="btn btn-primary">Siguiente</button>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'ReposicionForm',
    asyncBlurFields: [],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    initialValues: {
        fecha: moment()
    },
    validate: data => {
        return validate(data, {
            'dia': validators.exists()('Campo requerido.'),
            'hora_inicio': validators.exists()('Campo requerido.'),
            'hora_fin': validators.exists()('Campo requerido.'),
        })
    }
})(ReposicionForm);


