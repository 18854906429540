import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import DatePicker from 'react-datepicker';
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';

import moment from 'moment';


export default class FacturacionAlumno extends Component{

    componentWillMount() {
        const { listarFacturas } = this.props;
        listarFacturas();
    }


    render() {
        const { listarFacturas: onPageChange, onSortChange, anular } = this.props; //Funciones
        const { data_facturacion, loader, page_facturacion } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    {...this.props}
                    search={this.props.search_facturacion}
                    searchChange={this.props.searchFacturacionChange}
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Filtrar por fecha..."
                            onChange={this.props.changeFechaFacturacion}
                            selected={this.props.fecha_facturacion}
                        />
                    }
                />
                <Card>
                    <Grid
                        hover
                        striped
                        data={data_facturacion}
                        loading={loader}
                        onPageChange={onPageChange}
                        onSortChange={onSortChange}
                        page={page_facturacion}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='12%'
                            dataFormat={ (cell, row) => (
                                <div className="d-flex justify-content-center">
                                    <a target="_blank" href={row.documento} className="px-1" title="Ver factura" >
                                        <i className="material-icons ver-action">visibility</i>
                                    </a>
                                </div>
                            )}
                        />
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={(cell, row) => moment(cell).format("DD/MM/YYYY")}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="serie_factura"
                            dataFormat={(cell, row) => `${cell.prefijo}-${row.correlativo}`}
                        >
                            NO. FACTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="aula"
                            dataFormat={cell => cell ? cell.nombre : '-----'}
                        >
                            Curso
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="total"
                            dataAlign="right"
                            tdStyle={{paddingRight: "2.5rem"}}
                            thStyle={{paddingRight: "2.5rem"}}
                            dataFormat={(cell, row) => <RenderCurrency value={cell}  />}
                        >
                            MONTO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
