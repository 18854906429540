import React, {Component} from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import PendientesGrid from './PendientesGrid';
import CalificadosGrid from './CalificadosGrid';
// import CALIFICADOSGrid from './CALIFICADOSGrid';
// import ArchivosGrid from './ArchivosGrid';
// import FormsGrid from './FormsGrid';


export default class DetalleCurso extends Component{

    render() {
        const { item } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <h1 className="azul-claro bold">{item.nombre}</h1>
                <Tabs
                    defaultActiveKey="PENDIENTES"
                    tabBarPosition="top"
                    onChange={this.callback}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="PENDIENTES" key="PENDIENTES">
                        <PendientesGrid {...this.props} />
                    </TabPane>
                    <TabPane tab="CALIFICADOS" key="CALIFICADOS">
                        <CalificadosGrid {...this.props} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
