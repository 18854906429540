import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import {renderTextArea} from '../../Utils/renderField/renderField';
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { generos } from "../../../../utility/constants";

let PasoUnoForm = props => {
    const { handleSubmit, setStep, ver_password, change_ver_password, previousStep, onChangeCaptch, permitir_guardar, onExpiredCaptch, onErroredCaptch } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Objetivos de la clase</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="objetivos" className="m-0">Objetivos de Aprendizaje *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="objetivos" component={renderTextArea} type="text" rows="7" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <ReCAPTCHA
                        sitekey="6LclhM0ZAAAAAJbBxvNlWk0wea8gQRrxWZmAGVTb"
                        onChange={onChangeCaptch}
                        onExpired={onExpiredCaptch}
                        onErrored={onErroredCaptch}
                    />
                </div>
                <div className="buttons-box mt-3">
                    <div className="col-md-8 col-12 row">
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <button className="btn btn-secondary btn-block" onClick={previousStep}>Regresar</button>
                        </div>
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <button type="submit" disabled={!permitir_guardar} className="btn btn-primary btn-block">Crear</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

PasoUnoForm = reduxForm({
    form: 'AlumnoRegistroForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'objetivos': validators.exists()('Campo requerido.'),
        })
    }
})(PasoUnoForm);

export default PasoUnoForm;
