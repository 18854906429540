import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {push} from "react-router-redux";
import moment from 'moment';
import { DIAS_SEMANA } from '../../../utility/constants';
import _ from "lodash";
import { setMe } from '../cuenta/login';
import { USER_ALUMNO } from '../../../utility/constants';

const LOADER = 'LOADER_ALUMNOS';
const DATA = 'DATA_ALUMNOS';
const ITEM_DATA = 'ITEM_ALUMNOS';
const PAGE = 'PAGE_ALUMNOS';
const ORDERING = 'ORDERING_ALUMNOS';
const SEARCH = 'SEARCH_ALUMNOS';
const EVENTOS = 'EVENTOS_ALUMNOS';
const EVENTOS2 = 'EVENTOS2_ALUMNOS';
const TIPO_EVENTO = 'TIPO_EVENTO_ALUMNOS';
const FECHA_INICIO = 'FECHA_INICIO_ALUMNOS';
const FECHA_FIN = 'FECHA_FIN_ALUMNOS';
const ALUMNO = 'ALUMNO_ALUMNOS';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});


// ------------------------------------
// Actions
// ------------------------------------

const crear = (data, idCurso = null) => (dispatch) => {
    data.tipo_usuario = USER_ALUMNO;
    if (idCurso){
        data.id_curso = idCurso;
    }
    dispatch(setLoader(true));
    api.post('user', data).then((response) => {
        dispatch(cleanForm()); // Limpiar el formulario
        const mensaje = `¡Bienvenido y gracias por registrarte en SIDIOMS!, ahora escribe el usuario y contraseña con el que te acabas de registrar y entrarás a la plataforma`
        if (idCurso) {
            NotificationManager.success(mensaje, 'Éxito', 3000);
            dispatch(push(`/login/${idCurso}`));
        } else {
            NotificationManager.success(mensaje, 'Éxito', 3000);
            dispatch(push('/login'));
        }
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const inscribirme = (id) => (dispatch) => {
    console.log("dfdfdf")
    dispatch(setLoader(true));
    api.put('user/pre_inscripcion', { id }).then((response) => {
        dispatch(setMe(response));
        dispatch(push("/"));
        NotificationManager.success('Se ha realizado la inscripción, ahora proceda a pagar', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error("Error al realizar la inscripción", 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cancelarInscripcion = () => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar('user/cancelar_inscripcion').then((response) => {
        dispatch(setMe(response));
        dispatch(push("/"));
        NotificationManager.success('Se ha cancelado la inscripcion', 'Éxito', 3000);
        localStorage.removeItem('cupon');
        localStorage.removeItem('id_curso');
        localStorage.removeItem('tipo_curso');
    }).catch((error) => {
        NotificationManager.error("Error al cancelar la suscripcion", 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerActividades = (id) => (dispatch, getStore) => {
    const resource = getStore().alumnos;
    const params = { alumno: id };
    if (resource.tipo_evento)
        params.tipo_evento = resource.tipo_evento.value;
    if (resource.fecha_inicio)
        params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get('clase/get_clases_alumno', params).then((response) => {
        let eventos = response.results;
        eventos = eventos.map(item => {
            item.start = new Date(moment(item.fecha_hora_inicio));
            item.end = new Date(moment(item.fecha_hora_fin));
            item.tipo = item.tipo_evento;
            item.title = item.titulo;
            return item;
        })
        dispatch(setData(EVENTOS, eventos));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerAgenda = (id) => (dispatch, getStore) => {
    const resource = getStore().alumnos;
    const params = { alumno: id, agenda: true };
    params.fecha_inicial = moment().format("YYYY-MM-DD");
    params.fecha_fin = moment().add(7, 'd').format("YYYY-MM-DD");
    if (resource.tipo_evento)
        params.tipo_evento = resource.tipo_evento.value;
    dispatch(setLoader(true));
    api.get('clase/get_clases_alumno', params).then((response) => {
        dispatch(setData(EVENTOS2, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const iniciarFechas = () => (dispatch) => {
    const fecha = moment();
    const fecha2 = moment();
    if (fecha.get('d') == 0) {
        dispatch(setData(FECHA_INICIO, fecha.day(-6)));
        dispatch(setData(FECHA_FIN, fecha2));
    } else {
        dispatch(setData(FECHA_INICIO, fecha.day(1)));
        dispatch(setData(FECHA_FIN, fecha2.day(7)));
    }
};

const changeTipoEvento = (value, id, agenda=false) => (dispatch) => {
    dispatch(setData(TIPO_EVENTO, value));
    if (agenda) {
        dispatch(obtenerAgenda(id));
    } else {
        dispatch(obtenerActividades(id));
    }
};

const changeFechas = (value, id) => (dispatch) => {
    const fecha1 = moment(value[0]);
    const fecha2 = moment(value[6]);
    dispatch(setData(FECHA_INICIO, fecha1));
    dispatch(setData(FECHA_FIN, fecha2));
    dispatch(obtenerActividades(id));
};

const setearFormEventoDetalle = (evento) => (dispatch) => {

    const blocksFromHtml = htmlToDraft(evento.contenido ? evento.contenido : "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    const data = { ...evento, contenido: EditorState.createWithContent(contentState)};
    data.dia = moment(evento.fecha_hora_inicio).get('d');
    data.dia = _.find(DIAS_SEMANA, { id: data.dia }).value;
    data.hora_inicio = moment(evento.fecha_hora_inicio).format("HH:mm:ss");
    data.hora_fin = moment(evento.fecha_hora_fin).format("HH:mm:ss");
    dispatch(initializeForm('EventoEditForm', data));

};

const anularClase = (id, data, idA) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`clase/${id}/anular_clase`, {motivo_anulacion: data.motivo_anulacion}).then(() => {
        dispatch(obtenerActividades(idA));
        dispatch(obtenerAgenda(idA));
        NotificationManager.success('Clase cancelada', 'Éxito', 3000);
    }).catch((error) => {
        let mensaje = 'Error al cancelar la clase';
        if (error.detail)
            mensaje = error.detail;
        NotificationManager.error(mensaje, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('AlumnoRegistroForm', {}));
};

export const actions = {
    inscribirme,
    cancelarInscripcion,
    obtenerActividades,
    obtenerAgenda,
    iniciarFechas,
    changeTipoEvento,
    changeFechas,
    setearFormEventoDetalle,
    anularClase,
    crear,
    cleanForm
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [EVENTOS]: (state, { data }) => {
        return {
            ...state,
            eventos: data,
        };
    },
    [EVENTOS2]: (state, { data }) => {
        return {
            ...state,
            eventos2: data,
        };
    },
    [TIPO_EVENTO]: (state, { data }) => {
        return {
            ...state,
            tipo_evento: data,
        };
    },
    [FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data,
        };
    },
    [ALUMNO]: (state, { data }) => {
        return {
            ...state,
            alumno: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    eventos: [],
    eventos2: [],
    tipo_evento: null,
    fecha_inicio: null,
    fecha_fin: null,
    alumno: null,
};

export default handleActions(reducers, initialState);

