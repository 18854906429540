import React, { useState } from 'react';
import Select, { Creatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import { DatePicker as AntDatepicker, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'antd/dist/antd.css';
import _ from "lodash";
import moment from 'moment';
import Rating from '@material-ui/lab/Rating';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/dist/antd.css';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


export const renderField = ({
    input, placeholder, disabled, type, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                className={classNames('form-control', { 'is-invalid': invalid })}
                disabled={disabled ? disabled : false}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderPasswordField = ({
    input, placeholder, type, meta: { touched, error }, ver_password, change_ver_password
}) => {
    const invalid = touched && error;
    return (
        <div>
            <div>
                <input
                    {...input}
                    placeholder={placeholder}
                    type={type}
                    className={classNames('form-control', { 'is-invalid': invalid })}
                />
                {invalid && (
                    <div className="invalid-feedback">
                        {error}
                    </div>
                )}
            </div>
            {
                !invalid && (
                    <button type="button" className="btn-password" onClick={change_ver_password}>
                        <i className={`material-icons ${ver_password ? "azul-claro" : "gris-oscuro"}`}>remove_red_eye</i>
                    </button>
                )
            }
        </div>
    );
};

export const renderTextArea = ({
    input, placeholder, rows, disabled, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: 'none' }}
                rows={rows || 3}
                className={classNames('form-control', { 'is-invalid': invalid })}
                disabled={disabled ? disabled : false}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumber = ({
    input, decimalScale, placeholder, meta: { touched, error }, prefix = "", suffix = "", numberFormat, disabled
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
                disabled={disabled ? disabled : false}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCodigoPostal = ({
    input, decimalScale, placeholder, meta: { touched, error }, prefix = "", suffix = "", numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator={false}
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrency = ({
    input, meta: { touched, error }, prefix = "Q ", placeholder,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitch = ({
    input, meta: { touched, error }, label, disabled,
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#007bff"
                height={18}
                width={36}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldRadio = ({ input, label, value, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldRadio2 = ({ input, label, checked, value, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const SelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e[valueKey] : null); }}
                value={value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const AsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        onCambio,
        valueKey,
        labelKey,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder="Seleccionar.."
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (onCambio) onCambio(e);
                }}
                value={input.value}
                isDisabled={disabled}
                getOptionValue={(option) => (option[valueKey])}
                getOptionLabel={(option) => (option[labelKey])}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const RenderImageUpload = ({ setFile, aspect, grid, multiple, className }) => {
    const [fileList, setFileList] = useState([
    ]);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        if (multiple) {
            setFile(newFileList);
        } else {
            setFile(newFileList[0].originFileObj);
        }
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    return (
        <div className={classNames('image-input', `${className}`, 'text-center')}>
            <ImgCrop
                rotate
                aspect={aspect ? aspect : 1}
                modalOk="Seleccionar"
                modalCancel="Cancelar"
                modalTitle="Recortar imagen"
                grid={grid ? grid : false}
            >
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    className="avatar-uploader"
                    // showUploadList={false}
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    multiple={multiple ? multiple : false}

                >
                    {(multiple) || fileList.length == 0 && (
                        <div className="d-flex flex-column align-items-center">
                            <h5 className="text-center" style={{ color: "#686A75" }}>Arrastra una imagen para subir</h5>
                            <div className="btn-image-picker mt-2">
                                Seleccione una imagen
                            </div>
                            <span className="gris text-center mt-3">Tamaño máximo de archivo: 10 MB</span>
                        </div>
                    )}
                </Upload>
            </ImgCrop>

        </div>
    );
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({ photo, setFile, className, multiple = false, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                disabled={disabled}
                img={!!photo ? photo : null}
                multiple={multiple}
                // onFileChange={(e, file) => {
                //     file = file || e.target.files[0];
                //     const reader = new FileReader();
                //     reader.onload = (e) => {
                //         input.onChange(reader.result);
                //         if (!!setFile) {
                //             setFile(file);
                //         }
                //     };
                //     reader.readAsDataURL(file);
                // }}
                onFileChange={setFile}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDayPicker = ({ className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDatePicker = ({ className, disabled, onCambio, maxDate, minDate, todayButton, addClass, dateFormat, placeholder, showPopperArrow, fecha_inicial, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                showPopperArrow={showPopperArrow ? showPopperArrow : false}
                dateFormat={dateFormat ? dateFormat : "DD/MM/YYYY"}
                placeholderText={placeholder}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e)
                    }
                }}
                selected={input.value ? moment(input.value) : fecha_inicial}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                todayButton={todayButton}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    )
};

export const renderMonthPicker = ({className, disabled, onCambio, allowClear, addClass, placeholder, fecha_inicial, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <AntDatepicker
                format="MMMM/yyyy"
                picker="month"
                placeholder={placeholder ? placeholder : 'Seleccionar'}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e)
                    }
                }}
                allowClear={allowClear ? allowClear : false}
                disabled={disabled ? disabled : false}
                locale={locale}
                value={input.value ? moment(input.value) : fecha_inicial}
                className={classNames('form-control month-picker ', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    )
};

export const renderTimeField = ({ required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames({ 'is-invalid': invalid })}>
            <TimePicker
                maxDetail="minute"
                locale="es"
                disableClock={true}
                clearIcon={null}
                // clearIcon={<CloseIcon fontSize="small" color="error" />}
                className={classNames({ 'is-invalid': invalid }, className)}
                name={name}
                value={input.value}
                onChange={(value) => {
                    console.log("value ", value);
                    input.onChange(value);
                }}
                disabled={disabled}
            />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

export const renderRating = ({name, input, readonly, disabled, size, meta: { touched, error }}) => {
    let invalid = input.value === '' ? true : false;
    return (
        <div>
            <Rating
                name={'rating'}
                value={input.value}
                onChange={(value) => {
                    input.onChange(value);
                }}
                size={'large'}
                disabled={disabled}
                readOnly={readonly}
            />
            {invalid && (
                <div className="error-rating">
                    <span>{error}</span>
                </div>
            )}
        </div>
    );
};

export const renderEditorField = ({ defaultValue, input, disabled, height, height2, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    const op_toolbar = disabled ? [] : ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'history'];
    return (
        <div className={classNames({ 'is-invalid': invalid })}>
            <Editor
                editorState={defaultValue ? defaultValue : input.value ? input.value : EditorState.createEmpty()}
                wrapperClassName="editor-container"
                wrapperStyle={disabled ? { height: height ? height : "20rem", border: "none" } : { height: height ? height : "20rem" }}
                toolbarClassName={`editor-toolbar ${disabled && 'disabled'}`}
                editorClassName="editor-content"
                editorStyle={{ height: height2 ? height2 : height ? height : "20rem" }}
                onEditorStateChange={(value) => input.onChange(value)}
                toolbar={{
                    options: op_toolbar,
                    colorPicker: {
                        // icon: color,
                        className: "editor-colorpicker",
                        // component: undefined,
                        // popupClassName: undefined,
                        colors: [
                            'rgb(28, 55, 94)', 'rgb(3, 54, 181)', 'rgb(54, 106, 237)',
                            'rgb(83, 165, 19)', 'rgb(90, 206, 0)', 'rgb(104, 106, 117)',
                            'rgb(140, 143, 155)', 'rgb(186, 191, 206)', 'rgb(209, 216, 236)',
                            'rgb(219, 228, 252)', 'rgb(233, 32, 106)', 'rgb(255, 255, 255)',
                            'rgb(0, 0, 0)'
                        ],
                    },
                    link: {
                        className: "editor-link",
                    },
                }}
                readOnly={disabled ? disabled : false}
            />
            {invalid && <div className="invalid-feedback" >
                {error}
            </div>}
        </div>
    )
};

const multiSelecCustomStyles = {
    // Tomado de https://github.com/JedWatson/react-select/issues/1322#issuecomment-436615179
    control: base => ({
        ...base,
        minHeight: 30,
        borderRadius: 25,
    }),
    // Tomado de https://react-select.com/styles#cx-and-custom-components
    multiValue: (styles, { data }) => {
        return {
            ...styles,
        };
    },
};

export const MultiAsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        onCambio,
        valueKey,
        labelKey,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                styles={multiSelecCustomStyles}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                // loadOptions={(search) => getMateriasSinTipo(search)}
                placeholder={placeholder ? placeholder : 'Seleccionar...'}
                isMulti
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (onCambio) onCambio(e);
                }}
                // onChange={selected => {
                //     let values = [];
                //     selected && selected.map(selec => { values.push(selec['id']); });
                //     filtroProductosChange(values);
                // }}
                value={input.value}
                // value={getProductosByIDS(productos_ids)}
                isDisabled={disabled}
                getOptionValue={(option) => (option[valueKey])}
                getOptionLabel={(option) => (option[labelKey])}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const RenderField = {
    renderField,
    renderPasswordField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    renderFieldRadio2,
    MultiAsyncSelectField,
    RenderImageUpload,
    renderFilePicker,
};
