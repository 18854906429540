import React, { Component } from 'react'
import { Redirect } from 'react-router';
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import PasarelaForm from './PasarelaForm';

export default class PasarelaPago extends Component {

    componentWillMount() {
        const { me } = this.props;
        if (this.props.match.params.id) {
            console.log("Inscribirme ??")
            this.props.inscribirme(this.props.match.params.id);
        }
        if (localStorage.getItem('cupon') && me && me.pago_pendiente) {
            this.props.validarCupon(me.pago_pendiente.curso.id)
        }
        console.log("me ", me);
    }

    componentWillUnmount() {
        this.props.resetUrlBankia();
    }

    realizarPago = (values) => {
        this.props.generarPago(values);
    }

    render() {
        const { loader, me, cupon, url } = this.props;
        if (me && me.pago_pendiente) {
            return (
                <div className="mb-4 col-12 mt-4">
                    <CardForm col={11}>
                        <LoadMask loading={loader} blur>
                            <PasarelaForm {...this.props}
                                initialValues={{
                                    cupon: localStorage.getItem('cupon') ? localStorage.getItem('cupon') : null
                                }}
                                onSubmit={this.realizarPago}
                            />
                            <form name="PagoBankiaForm" action={url} method="POST">
                            </form>
                        </LoadMask>
                    </CardForm>
                </div>
            )

        } else {
            return (<Redirect to={'/'} />)
        }
    }
}
