import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import CardArchivo from '../../../Utils/Cards/CardArchivo';


let EventoEditForm = (props) => {
    const { handleSubmit, cancelar, evento, elegirArchivos } = props;
    return (
        <form name="EventoEditForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group grid-container mx-md-5">
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-12 mb-3">
                        <span className="text-sm azul-claro bold">{`Selecciona los archivos para la clase: ${evento && evento.titulo}`}</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>

                    {
                        <div className="row col-12 px-5">
                        {
                            (props.archivos) && (
                                props.archivos.map((item, index) => (
                                    <div className="col-md-2 col-6 mt-3" key={index}>
                                        <CardArchivo
                                            index={index}
                                            item={item.archivo}
                                            nombre={item.archivo.nombre}
                                            tipo={item.archivo.tipo}
                                            extension={item.archivo.extension}
                                            // seleccionar={props.seleccionar}
                                            // seleccionado={seleccionado ? seleccionado.id == item.id : false }
                                            eliminar={props.eliminarArchivo}
                                        />
                                    </div>
                                ))
                            )
                        }
                        </div>
                    }
                    <div className="row col-12 justify-content-center mt-5">
                        <button type="button" onClick={elegirArchivos} className="btn btn-dark">Seleccionar archivo</button>
                    </div>

                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <button type="button" onClick={cancelar} className="btn btn-secondary btn-block">Anterior</button>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Siguiente</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

const selector = formValueSelector('EventoEditForm');

EventoEditForm = reduxForm({
    form: 'EventoEditForm',
    asyncBlurFields: [],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: data => {
        return validate(data, {
            'dia': validators.exists()('Campo requerido.'),
            'hora_inicio': validators.exists()('Campo requerido.'),
            'hora_fin': validators.exists()('Campo requerido.'),
        })
    }
})(EventoEditForm);

const mstp = state => {
    const archivos = selector(state, 'archivos');

    return {
        archivos,
    }
};

export default connect(mstp, null)(EventoEditForm);
