import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { renderEditorField, renderField, renderTextArea } from '../../../Utils/renderField';
import { NORMAL } from '../../../../../utility/constants';
import { renderFieldRadio } from '../../../Utils/renderField/renderField';


const renderPreguntas = ({ fields, respuestas }) => (
    <div className="d-flex flex-column">
        <div className="col-md-12 col-12 px-0">
            {fields.map((item, index) => {
                // let todos = fields.getAll();
                // const dato_actual = fields.get(index);
                // todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                return (
                    <div key={index} style={{ border: "1px solid #707070", borderRadius: "0.75rem" }} className="mb-3 col-12 py-3 px-lg-5">
                        <div className="col-12 d-flex justify-content-between align-items-center mb-2 px-md-0">
                            <h4 className="azul-claro bold">{respuestas ? respuestas[index].pregunta.pregunta : ''}</h4>
                        </div>
                        <div className="row">
                            <div className="col-12 px-0">
                                <div className="col-md-12 col-12 px-0">
                                    <label htmlFor={`${item}.respuesta_normal`} className="m-0 mb-1">Respuesta</label>
                                </div>
                                {
                                    (respuestas) && (
                                        (respuestas[index].pregunta.tipo == NORMAL) ? (
                                            <div className="col-md-12 col-12 px-0">
                                                <Field name={`${item}.respuesta_normal`} component={renderTextArea} type="text" className="form-control" />
                                            </div>
                                        ) : (
                                            <div className="col-md-8 col-12 px-0">
                                                {
                                                    respuestas[index].pregunta.respuestas.map((opcion, _index) => {
                                                        return (
                                                            <div key={_index} className="col-12 mt-2 px-0">
                                                                <div className="d-flex flex-row">
                                                                    <Field
                                                                        name={`${item}.respuesta_multiple`}
                                                                        label=''
                                                                        value={opcion.id}
                                                                        parse={val => +val}
                                                                        type="radio"
                                                                        component={renderFieldRadio}
                                                                    />
                                                                    <label htmlFor="nuevo" className="m-0">
                                                                        {opcion.respuesta}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
);

const ExamenForm = (props) => {
    const { handleSubmit, item } = props;
    return (
        <form name="ExamenForm" className="form-validate mb-lg w-100" onSubmit={handleSubmit}>
            <div className="form-group grid-container mx-0">
                <div className="col-12 px-0">
                    <span className="text-sm azul-claro bold">Notas</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="rd-flex flex-column align-items-end px-0 mt-3 px-0">
                    <div className="flex-1 w-100 borde-gris-notas">
                        <Field name="notas_form" height="20rem" disabled component={renderEditorField} />
                    </div>
                </div>

                <div className="col-12 mt-3 px-0">
                    <span className="text-sm azul-claro bold">Exámen</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="d-flex flex-column align-items-end px-0 mt-3">
                    <div className="flex-1 w-100">
                        <FieldArray name="respuestas" component={renderPreguntas} respuestas={item.respuestas} />
                    </div>
                </div>

                <div className="buttons-box mt-3">
                    {/* <Link className="btn btn-secondary mr-5" to="/profesores">Cancelar</Link> */}
                    <button type="submit" style={{width: "10rem"}} className="d-none d-lg-block btn btn-primary">Finalizar exámen</button>
                    <button type="submit" className="d-block d-lg-none w-100 btn btn-primary">Finalizar exámen</button>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'ExamenForm',
    asyncBlurFields: [],
})(ExamenForm);


