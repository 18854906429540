import React, { Component } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { TIPOS_EVENTOS } from '../../../../utility/constants';
import FiltroEventos from './FiltroEventos';
import moment from 'moment';
import _ from 'lodash';


const localizer = momentLocalizer(moment)

const formats = {
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
    dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
}

// ICONOS PARA EL CALENDARIO
const botonLeft = require('../../../../../assets/img/iconos/izquierda-gris.png');
const botonRight = require('../../../../../assets/img/iconos/derecha-gris.png');
const conTemario = require('../../../../../assets/img/iconos/con-temario.png');
const sinTemario = require('../../../../../assets/img/iconos/sin-temario.png');


class Calendario extends Component {

    eventStyleGetter = (event, start, end, isSelected) => {
        const item = _.find(TIPOS_EVENTOS, { value: event.tipo });
        let style = {
            backgroundColor: item ? item.color : '#366AED',
            color: item ? item.texto : '#151A34',
            fontWeight: "bold",
        };

        return {
            style: style
        };
    }

    render() {
        const { eventos, defaultView, toolbar, onlySemana, onSelectEvent, onSelectSlot, height,
            tipo_evento, changeTipoEvento, changeFechas, refrescar } = this.props;
        return (
            <Calendar
                selectable
                localizer={localizer}
                events={eventos}
                defaultView={defaultView ? defaultView : Views.WEEK}
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={onSelectEvent ? onSelectEvent : (e) => console.log('e ', e)}
                onSelectSlot={onSelectSlot}
                onNavigate={(e) => { console.log("navigate ", e) }}
                onRangeChange={changeFechas}
                toolbar={toolbar ? toolbar : true}
                style={{ height: height ? height : 500 }}
                views={['week']}
                formats={onlySemana ? formats : null}
                // components={{
                //     toolbar : onlySemana ? NoneToolbarActions : <CustomToolbar opcion={this.state.opcion} {...this.props}  />
                // }}
                components={{
                    event: eventProps => ( <CustomEvent onlySemana={onlySemana} {...eventProps}  />),
                    toolbar: onlySemana ? NoneToolbarActions : toolProps => (
                        <CustomToolbar {...toolProps} tipo_evento={tipo_evento} changeTipoEvento={changeTipoEvento} refrescar={refrescar} />),
                }}
                eventPropGetter={(this.eventStyleGetter)}
            />
        )
    }
};

const CustomToolbar = (toolbar) => {

    const label = () => {
        const date = moment(toolbar.date);
        if (date.get('d') == 0) {
            return (
                <span><b>{`${date.day(-6).format('ddd DD')} - `}</b><span> {date.format('ddd DD')}</span></span>
            );
        } else {
            return (
                <span><b>{`${date.day(1).format('ddd DD')} - `}</b><span> {date.day(7).format('ddd DD')}</span></span>
            );
        }
    };

    const navigate = action => {
        toolbar.onNavigate(action);
    }

    return (
        <div className='rbc-toolbar calendario-toolbar py-2'>
            <div className="rbc-toolbar-label d-flex flex-md-row flex-column">
                <div className="flex-1">
                    <FiltroEventos
                        label="Todos"
                        options={TIPOS_EVENTOS}
                        value={toolbar.tipo_evento}
                        changeValue={toolbar.changeTipoEvento}
                    />
                </div>
                <div className="flex-1"></div>
                <div className="d-flex flex-row justify-content-center align-items-center flex-1">
                    <a onClick={toolbar.refrescar} className="btn btn-alter1">Refrescar</a>
                </div>
                {/* Filtros */}
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center rbc-btn-group my-1 mr-4">
                <button type="button" className="d-flex flex-1 rbc-btn mr-3 justify-content-center align-self-center" onClick={() => navigate('PREV')}>
                    <img src={botonLeft} className="w-100 h-100" />
                </button>
                <button type="button" className="d-flex flex-1 rbc-btn" onClick={() => navigate('NEXT')}>
                    <img src={botonRight} className="w-100 h-100" />
                </button>
                <label className="d-flex flex-1 my-0 mr-0 rbc-label-fecha ml-3">{label()}</label>
            </div>
        </div >
    );
};

const NoneToolbarActions = (toolbar) => {

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
        );
    };

    return (
        <div className='rbc-toolbar'>
            <div className="rbc-btn-group">
            </div>
            <label className="rbc-toolbar-label">{label()}</label>
        </div >
    );
};

const CustomEvent = (props) => {
    return (
        <div className="d-flex flex-column h-100">
            <div className="mr-md-4 mr-3">{props.event.titulo ? props.event.titulo : props.title}</div>
            {
                !props.onlySemana && (
                    <div className="rbc-temario">
                        {
                            props.event.contenido ? (
                                <img src={conTemario} className="w-100 h-100" />
                            ) : (
                                <img src={sinTemario} className="w-100 h-100" />
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}


export default Calendario;
