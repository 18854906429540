import React, { Fragment, Component } from 'react';
import { Async } from 'react-select';
import classNames from 'classnames';
import CardArchivo from '../../../Utils/Cards/CardArchivo';
import SearchInput from '../../../Utils/Grid/SearchInput';
import { api } from "api";

const getNiveles = (search) => {
    return api.get("asignatura_niveles", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

export default class ModalArchivos extends Component {

    state = {
        seleccionado: null
    }

    seleccionar = (index, item) => {
        this.setState({ seleccionado: item });
    }

    render() {
        const { archivos, cancelar, seleccionar, search, searchChange } = this.props;
        const { seleccionado } = this.state;
        return (
            <form>
                <div className="form-group grid-container">
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6 col-12 pr-3 mb-3 mt-4">
                            <SearchInput search={search} searchChange={searchChange} placeholder="Buscar archivo" />
                        </div>
                        <div className="col-md-6 col-12 pr-3 mb-3 mt-4">
                            <Async
                                isClearable={true}
                                backspaceRemovesValue={false}
                                value={this.props.nivel}
                                isSearchable={true}
                                loadOptions={getNiveles}
                                getOptionValue={(option) => (option["id"])}
                                getOptionLabel={(option) => (option["titulo"])}
                                type="text"
                                onChange={(e) => this.props.changeNivel(e)}
                                multi={false}
                                autoload={false}
                                cache={false}
                                className={classNames('react-select-container ')}
                                defaultOptions={true}
                                placeholder="Filtrar por nivel.."
                            />
                        </div>
                        <div className="col-md-12 p-0 mt-3 mt-md-0 " style={{ height: "50vh", overflowY: "auto" }}>
                            <div className="row col-12">
                                {
                                    (archivos) && (archivos.length) ?(
                                        archivos.map((item, index) => (
                                            <div className="col-md-3 col-6 mt-3" key={index}>
                                                <CardArchivo
                                                    index={index}
                                                    item={item}
                                                    nombre={item.nombre}
                                                    tipo={item.tipo}
                                                    extension={item.extension}
                                                    seleccionar={this.seleccionar}
                                                    seleccionado={seleccionado ? seleccionado.id == item.id : false}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <React.Fragment>
                                            <div className="col-md-3 col-6 mt-3 mx-4"></div>
                                            <div className="col-md-3 col-6 mt-3 mx-4"></div>
                                            <div className="col-md-3 col-6 mt-3 mx-4"></div>
                                            <div className="col-md-3 col-6 mt-3 mx-4"></div>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="d-flex buttons-box mt-5">
                        <button type="button" onClick={cancelar} className="btn btn-secondary mr-5">Cancelar</button>
                        <button
                            type="button"
                            onClick={() => seleccionar(seleccionado)}
                            className="btn btn-primary"
                        >Seleccionar</button>
                    </div>
                </div>
            </form>
        )
    }
}
