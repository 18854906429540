import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { actions } from '../../../../../redux/modules/profesor';
import CalendarioProfesor from './Calendario';


const ms2p = (state) => {
  return {
    me: state.login.me,
    ...state.profesor,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(withRouter(CalendarioProfesor));
