import React, { Fragment, Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderDatePicker, renderTimeField, renderNumber } from '../../../Utils/renderField';
import { CardSmallEjercicio } from '../../../Utils/Cards/CardEjercicio';
import { EJERCICIO, EXAMEN } from '../../../../../utility/constants';
import moment from 'moment';
import SearchInput from '../../../Utils/Grid/SearchInput';

class ModalArchivos extends Component {

    state = {
        seleccionado: null
    }

    seleccionar = (index, item) => {
        this.setState({ seleccionado: item });
    }

    render() {
        const { forms, cancelar, seleccionar, search, searchChange } = this.props;
        const { seleccionado } = this.state;
        return (
            <form>
                <div className="form-group grid-container">
                    <div className="row">
                        <div className="col-md-6 archivo-group">
                            <div className="col-12 mb-4 px-0 mt-2">
                                <span className="text-sm azul-claro bold">Datos Formulario</span>
                                <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                            </div>
                            {
                                (seleccionado) && (
                                    <div className="col-12 p-0 ">
                                        {
                                            seleccionado.tipo == EJERCICIO ? (
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="tipo" className="m-0">Fecha de entrega</label>
                                                </div>
                                            ) : (
                                                    <div className="col-md-12 col-sm-12">
                                                        <label htmlFor="tipo" className="m-0">Fecha de habilitación</label>
                                                    </div>
                                                )
                                        }
                                        <div className="col-md-12 d-flex flex-row  form-group">
                                            <div className="d-flex flex-1">
                                                <Field
                                                    name="fecha"
                                                    component={renderDatePicker}
                                                />
                                            </div>
                                            <div className="d-flex flex-1">
                                                <Field name="hora" component={renderTimeField} />
                                            </div>
                                        </div>
                                    </div>

                                )
                            }
                            {
                                (seleccionado) && (seleccionado.tipo == EXAMEN) && (
                                    <div className="col-12 p-0 ">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="tipo" className="m-0">Duración del exámen</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                name="tiempo_maximo"
                                                component={renderNumber}
                                                suffix=' horas'
                                                type="text"
                                                decimalScale={2}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="d-md-flex d-none buttons-box mt-5 mx-4">
                                <button type="button" onClick={cancelar} className="btn btn-secondary mr-5">Cancelar</button>
                                <button
                                    type="button"
                                    onClick={() => seleccionar(seleccionado)}
                                    className="btn btn-primary"
                                >Seleccionar</button>
                            </div>
                        </div>
                        <div className="col-md-6 p-0 px-md-3">
                            <div className="col-12 mb-4 px-0 mt-2">
                                <span className="text-sm azul-claro bold">Selecciona un exámen o ejercicio</span>
                                <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                            </div>
                            <div className="col-12 pr-3 mb-3 mt-4">
                                <SearchInput search={search} searchChange={searchChange} placeholder="Buscar ejercicio o examen" />
                            </div>
                            <div className="col-md-12 p-0 mt-3 mt-md-0 " style={{ height: "50vh", overflowY: "auto" }}>
                                <div className="row col-12">
                                    {
                                        (forms) && (
                                            forms.map((item, index) => (
                                                <div className="col-md-4 col-6 mt-3" key={index}>
                                                    <CardSmallEjercicio
                                                        index={index}
                                                        item={item}
                                                        nombre={item.titulo}
                                                        tipo={item.tipo}
                                                        seleccionar={this.seleccionar}
                                                        seleccionado={seleccionado ? seleccionado.id == item.id : false}
                                                    />
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-md-none d-flex buttons-box mt-5">
                        <button type="button" onClick={cancelar} className="btn btn-secondary mr-5">Cancelar</button>
                        <button
                            type="button"
                            onClick={() => seleccionar(seleccionado)}
                            className="btn btn-primary"
                        >Seleccionar</button>
                    </div>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'FormulariosForm',
    initialValues: {
        fecha: moment(),
    },
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'fecha': validators.exists()('Campo requerido.'),
            'hora': validators.exists()('Campo requerido.'),
            'tiempo_maximo': validators.exists()('Campo requerido.'),
        })
    }
})(ModalArchivos);
