import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_PUNTUACIONES';
const DATA = 'DATA_PUNTUACIONES';
const FECHA_INICIO = 'FECHA_INICIO_PUNTUACIONES';
const FECHA_FIN = 'FECHA_FIN_PUNTUACIONES';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});


// ------------------------------------
// Actions
// ------------------------------------

const listarGrafica = () => (dispatch, getStore) => {
    const me = getStore().login.me;
    const resource = getStore().puntuaciones;
    const params = { profesor: me.id };
    if (resource.fecha_inicio)
        params.fecha_inicio = moment(resource.fecha_inicio).format('YYYY-MM-DD');
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format('YYYY-MM-DD');
    dispatch(setLoader(true));
    api.get('reportes/puntuaciones_grafica', params).then((response) => {
        const data = [['Fecha', 'Promedio']];
        for (const item of response.results) {
            data.push([moment(item.fecha_inicio).format("DD/MM/YYYY"), item.promedio]);
        }
        dispatch(setData(DATA, data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const changeFechaInicio = value => (dispatch) => {
    dispatch(setData(FECHA_INICIO, value));
    dispatch(listarGrafica());
    dispatch(listarTabla());
};

const changeFechaFin = value => (dispatch) => {
    dispatch(setData(FECHA_FIN, value));
    dispatch(listarGrafica());
    dispatch(listarTabla());
};

export const actions = {
    listarGrafica,
    changeFechaInicio,
    changeFechaFin
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: [['Fecha', 'Promedio'],[0, 0]],
    fecha_inicio: moment().subtract(7, 'd'),
    fecha_fin: moment(),
};

export default handleActions(reducers, initialState);

