import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { Checkbox } from 'antd';
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import Swal from 'sweetalert2';


export default class ListarTarjetas extends Component {

    componentWillMount() {
        const { listarTarjetas } = this.props;
        listarTarjetas();
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    }

    generarLinkAgregarTarjeta = () => {
        return () => {
            Swal.fire({
                title: 'Crear nueva tarjeta',
                text: 'Para crear una nueva tarjeta se te redirigira a la pagina de Bankia, pero no te preocupes, no se te realizara ningún cobro.',
                type: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ir a la pagina',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.generarLinkAgregarTarjeta()
                }
            });
        }
    }

    editar = (id, por_defecto) => {
        if (!por_defecto) {
            return () => {
                Swal.fire({
                    title: '¿Cambiar la tarjeta por defecto?',
                    text: '¡A esta nueva tarjeta es que se harán sus próximos cobros!',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: '¡Sí, cambiar!',
                    cancelButtonText: 'No, cancelar',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        this.props.editarDefecto(id)
                    }
                });
            }

        }
    }


    render() {
        const { listarTarjetas: onPageChange } = this.props; //Funciones
        const { data, loader, page, url } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <div className="d-flex w-100">
                    <div className="d-flex flex-1 row col-12 mb-3 px-1">
                        <div className={`col-md-4 col-6 px-0 px-md-4`}>
                            <button type="button" onClick={this.generarLinkAgregarTarjeta()} className="btn btn-primary mb-3">Agregar tarjeta</button>
                        </div>
                    </div>
                </div>
                <form name="AgregarTarjetaForm" action={url} method="POST">
                </form>
                <Card>
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='12%'
                            dataFormat={(cell, row) => (

                                !row.por_defecto || true ? (
                                    <div className="d-flex justify-content-center">
                                        <a className="px-1" style={{ cursor: "pointer", color: "#c4183c" }} title="Eliminar" onClick={this.eliminar(cell)}>
                                            <i className="material-icons azul-marino eliminar-action">clear</i>
                                        </a>
                                    </div>
                                ) : ('')
                            )}
                        />
                        <TableHeaderColumn
                            dataField="por_defecto"
                            width='20%'
                            dataFormat={(cell, row) => (
                                <div className="ml-3">
                                    <Checkbox onChange={this.editar(row.id, cell)} checked={cell}></Checkbox>
                                </div>
                            )}
                        >
                            TARJETA PRINCIPAL *
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_tarjeta_text"
                        >
                            TIPO TARJETA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_expiracion"
                            dataFormat={cell => cell ? `${cell.substring(2, 4)}/${cell.substring(0, 2)}` : ''}
                        >
                            FECHA DE EXPIRACIÓN
                        </TableHeaderColumn>
                    </Grid>
                    <div className="col-12 text-right mb-3">
                        <span className="azul-marino text-xsm">* Solo realizaremos cobros automaticos a la tarjeta principal.</span>
                    </div>
                </Card>
            </div>
        )
    }
}
