import React, { Component } from 'react'

const bienvenidaImg = require("../../../../../../assets/img/bienvenida.png");

export default class HomeAlumno extends Component {

    componentWillMount() {
        this.props.leer();
    }

    render() {
        const { item } = this.props;
        return (
            <div className="d-flex flex-1 flex-column w-100 mt-5 mt-md-4" style={{height: "calc(100vh - 7.5rem)"}}>
                <div className="d-flex flex-column bienvenida justify-content-center align-items-center">
                    <div className="titulo-bienvenida">{item.bienvenida_titulo}</div>
                    <div className="contenido-bienvenida">{item.bienvenida_contenido}</div>
                </div>
                <div className="d-flex img-bienvenida justify-content-center align-items-center">
                    <img style={{width: "65%"}} src={bienvenidaImg} alt="Bienvenida" />
                </div>
            </div>
        )
    }
}
