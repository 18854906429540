import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, AsyncSelectField, SelectField, renderFilePicker } from '../../../Utils/renderField';
import { ENLACE } from '../../../../../utility/constants';
import { getIcono } from '../../../../../utility/iconos';
import CardArchivo from '../../../Utils/Cards/CardArchivo'

const eliminar = require('../../../../../../assets/img/iconos/delete.png');
const descargar = require('../../../../../../assets/img/iconos/descargar.png');

const DetalleArchivos = (props) => {
    const { handleSubmit, nivel, seleccionado, idNivel, me } = props;
    return (
        <form name="ArchivosForm" onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 mb-4 px-md-5">
                    <span className="text-sm azul-claro bold">{`${nivel.asignatura} Nivel ${nivel.nombre}`}</span>
                    <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-md-5 p-0 px-4 archivo-group pt-md-5">
                        <div className="d-flex flex-row align-items-center justify-content-center mb-3">
                            <div className="">
                                <label htmlFor="nombre_enlace" className="m-0 text-sm">Tipo de carga:</label>
                            </div>
                            <div className="">
                                <label htmlFor="nombre_enlace" className="m-0 text-sm">{seleccionado && seleccionado.tipo_text}</label>
                            </div>
                        </div>
                        <div className="row col-12 align-items-center justify-content-center px-0 mx-0">
                            <div className="col-2 p-1">
                                {
                                    seleccionado && (
                                        <img  style={{width: "100%", height: "100%"}} src={getIcono(seleccionado.tipo, seleccionado.extension)} />
                                    )
                                }
                            </div>
                            <div className="col-9 px-2">
                                <div className="">
                                    <label htmlFor="nombre" className="m-0">Nombre</label>
                                </div>
                                <div className="">
                                    <Field name="nombre" disabled={seleccionado ? seleccionado.subido_por == me.id ? false : true : true} component={renderField} type="text" className="form-control" />
                                </div>
                                {
                                    (seleccionado) && (seleccionado.tipo == ENLACE) && (
                                        <Fragment>
                                            <div className="mt-2">
                                                <label htmlFor="nombre_enlace" className="m-0">Link</label>
                                            </div>
                                            <div className="d-flex flex-column ml-1 ">
                                                <a href={seleccionado.enlace} target="_blank" className="">{seleccionado.enlace}</a>
                                            </div>
                                        </Fragment>
                                    )
                                }
                            </div>
                            <div className="col-1 px-0">
                                {
                                    seleccionado && (
                                        <div className="d-flex flex-md-row flex-column">
                                            <a
                                                download
                                                target="_blank"
                                                style={{cursor: "pointer"}}
                                                href={seleccionado.archivo}
                                                className="d-flex flex-1 ml-1 mt-md-3 mt-1"
                                            >
                                                <img className="icono-tool-archivo" src={descargar} />
                                            </a>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {
                            (!seleccionado) && (
                                <div className="row col-12 align-items-center justify-content-center px-0 mx-0 mt-3">
                                    <span className="bold rosa">
                                        *Selecciona un archivo
                                    </span>
                                </div>
                            )
                        }
                        <div className="d-md-flex d-none buttons-box mt-5">
                            <Link className="btn btn-secondary mr-5" to="/niveles">Regresar</Link>
                            <button type="submit" disabled={seleccionado ? seleccionado.subido_por == me.id ? false : true : true} className="btn btn-primary">Guardar</button>
                        </div>
                        <div className="d-md-flex d-none buttons-box mt-5 px-5">
                            <Link
                                className="btn btn-dark d-flex flex-1"
                                to={`/biblioteca_archivos/${idNivel}/crear`}
                            >Subir nuevos archivos</Link>
                        </div>
                    </div>
                    <div className="col-md-7 p-0 mt-3 mt-md-0 " style={{ height: "60vh", overflowY: "auto" }}>
                        <div className="row col-12">
                            {
                                (props.archivos) && (
                                    props.archivos.map((item, index) => (
                                        <div className="col-md-3 col-6 mt-3" key={index}>
                                            <CardArchivo
                                                index={index}
                                                item={item}
                                                nombre={item.nombre}
                                                tipo={item.tipo}
                                                extension={item.extension}
                                                seleccionar={props.seleccionar}
                                                seleccionado={seleccionado ? seleccionado.id == item.id : false }
                                            />
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="d-md-none d-flex flex-column buttons-box mt-5">
                    <Link className="w-100 btn btn-secondary mb-3" to="/niveles">Regresar</Link>
                    <button type="submit" disabled={seleccionado ? false: true} className="w-100 btn btn-primary">Guardar</button>
                </div>
                <div className="d-md-none d-flex buttons-box mt-5">
                    <Link className="w-100 btn btn-dark d-flex flex-1" to={`/biblioteca_archivos/${idNivel}/crear`}>Subir nuevos archivos</Link>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'DetalleArchivosForm', // a unique identifier for this form
    asyncBlurFields: [],
    // validate: data => {
    // return validate(data, {
    // 'nombre': validators.exists()('Campo requerido.'),
    // 'asignatura': validators.exists()('Campo requerido.'),
    // })
    // }
})(DetalleArchivos);
