import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { ProgressBar, Step } from "react-step-progress-bar";
import Modal from 'react-responsive-modal';
import "react-step-progress-bar/styles.css";
import { NotificationManager } from "react-notifications";

import ModalArchivos from './ModalArchivos';
import ModalForms from './ModalForms';
import FormTemario from './FormTemario';
import FormArchivos from './FormArchivos';
import FormExamenes from './FormExamenes';

const check = require("assets/img/icons/check.png");

export default class RecuperarClase extends Component {

    state = {
        step: 1,
        modalArchivos: false,
        modalForms: false,
    };

    componentWillMount() {
        this.props.obtenerDetalleAula(this.props.match.params.id);
        this.props.obtenerArchivosProfesor();
        this.props.obtenerFormsProfesor();
    };

    guardar = (data) => {
        // console.log("guardar ", data);
        this.props.crearRecuperacion(data, this.props.match.params.id);
    };

    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    };

    previousStep =() => {
        this.setState({ step: this.state.step - 1 })
    };

    openModalArchivos = () => {
        this.setState({modalArchivos: true});
    };

    openModalForms = () => {
        this.setState({modalForms: true});
    };

    closeModal = () => {
        this.setState({modalArchivos: false, modalForms: false});
    }

    agregarFormulario = (item) => {
        const { form_examen: {values} } = this.props;
        console.log("form ", values);

        if (!values.fecha || !values.hora) {
            NotificationManager.error('Campos de fecha y hora son requeridos', 'ERROR', 0);
        } else if (item.tipo == 20 && !values.tiempo_maximo) {
            NotificationManager.error('Campo de duración es requerido', 'ERROR', 0);
        } else {
            this.props.setearFormulario(item, values, this.closeModal, true);
        }

    }

    renderModalArchivos = () => {
        return (
            <Modal
                open={this.state.modalArchivos}
                onClose={this.closeModal}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <span className="text-sm azul-claro bold">Selecciona un archivo para agregarlo</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12 mx-0">
                            <ModalArchivos
                                archivos={this.props.archivos}
                                cancelar={this.closeModal}
                                onSubmit={this.closeModal}
                                seleccionar={(item) => {
                                    this.props.setearArchivo(item, this.closeModal, true);
                                    // this.closeModal();
                                }}
                                search={this.props.search_archivos}
                                searchChange={this.props.searchArchivos}
                            />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    renderModalForms = () => {
        return (
            <Modal
                open={this.state.modalForms}
                onClose={this.closeModal}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <ModalForms
                                forms={this.props.forms}
                                cancelar={this.closeModal}
                                onSubmit={this.closeModal}
                                seleccionar={this.agregarFormulario}
                                search={this.props.search_forms}
                                searchChange={this.props.searchForms}
                            />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    render() {
        const { loader, item } = this.props;
        const { step } = this.state;

        return (
            <div className="mb-4 col-12 mt-4">
                {this.renderModalArchivos()}
                {this.renderModalForms()}
                <div className="mb-5 mt-5 col-12 row d-flex justify-content-center">
                    <div className="col-sm-8 col-10 px-0">
                        <ProgressBar
                            percent={step === 1 ? 0 : step === 2 ? 50 : 100}
                            filledBackground="#686A75"
                            unfilledBackground="#686A75"
                            stepPositions={[0, 50, 100]}
                            height={5}
                        >
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot" />
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center justify-content-center">
                                                        <img src={check} alt="" width="15" height="15" />
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul' : 'gris-oscuro')}`}>
                                                Temario
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot" />
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center justify-content-center">
                                                        <img src={check} alt="" width="15" height="15" />
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul' : 'gris-oscuro')}`}>
                                                Carga de archivos
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                            <Step transition="scale">
                                {({ accomplished, index, position }) => (
                                    <div style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }} className="mt-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {index + 1 === step ?
                                                <div className="step-number">
                                                    <div className="step-dot" />
                                                </div>
                                                :
                                                (!!accomplished ?
                                                    <div className="step-checked d-flex align-items-center">
                                                        <img src={check} alt="" width="15" height="15" />
                                                    </div>
                                                    :
                                                    <div className="step-number">
                                                    </div>
                                                )
                                            }
                                            <span className={`mt-2 ${index + 1 === step ? 'azul' : (!!accomplished ? 'azul' : 'gris-oscuro')}`}>
                                                Formularios
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </Step>
                        </ProgressBar>
                    </div>
                </div>
                <CardForm col={'12'}>
                    <LoadMask loading={loader} blur>
                        <Fragment>
                            {step === 1 && (
                                <FormTemario
                                    onSubmit={this.nextStep}
                                    detalle_aula={this.props.detalle_aula}
                                />
                            )}
                            {step === 2 && (
                                <FormArchivos
                                    onSubmit={this.nextStep}
                                    cancelar={this.previousStep}
                                    detalle_aula={this.props.detalle_aula}
                                    eliminarArchivo={(index) => this.props.eliminarArchivo(index, true)}
                                    elegirArchivos={this.openModalArchivos}
                                />
                            )}
                            {step === 3 && (
                                <FormExamenes
                                    onSubmit={this.guardar}
                                    cancelar={this.previousStep}
                                    detalle_aula={this.props.detalle_aula}
                                    elegirForms={this.openModalForms}
                                    eliminarFormulario={ index => this.props.eliminarFormulario(index, true)}
                                />
                            )}
                        </Fragment>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
