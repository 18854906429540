import { createAction, handleActions } from 'redux-actions';
import {NotificationManager} from "react-notifications";
import { api } from "api";
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_HORAS_PROFE';
const DATA = 'DATA_HORAS_PROFE';
const GRAFICA_BARRAS = 'GRAFICA_BARRAS_HORAS_PROFE';
const GRAFICA_LINEAS = 'GRAFICA_LINEAS_HORAS_PROFE';
const ITEM = 'ITEM_HORAS_PROFE';
const FECHA = 'FECHA_HORAS_PROFE';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});


// ------------------------------------
// Actions
// ------------------------------------

const listarResumen = () => (dispatch, getStore) => {
    const resource = getStore().horasTrabajadas;
    const me = getStore().login.me;
    const params = {profesor: me.id};
    if (resource.fecha) {
        params.fecha_inicio = moment(resource.fecha).startOf('month').format('YYYY-MM-DD');
        params.fecha_fin = moment(resource.fecha).endOf('month').format('YYYY-MM-DD');
    }
    dispatch(setLoader(true));
    api.get('reportes/horas_trabajadas_graficas', params).then((response) => {
        const { horas_trabajadas, maximo_horas, data } = response;
        const horas = [['Día', 'Horas trabajadas'], [0, 0]];
        console.log("response ", response);
        dispatch(setData(GRAFICA_BARRAS, {horas_trabajadas, maximo_horas }));
        dispatch(setData(DATA, data));
        for (const item of data) {
            horas.push([item.dia, item.total]);
        }
        dispatch(setData(GRAFICA_LINEAS, horas));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = fecha => (dispatch, getStore) => {
    const resource = getStore().horasTrabajadas;
    const me = getStore().login.me;
    const params = { fecha, profesor: me.id };
    dispatch(setLoader(true));
    api.get('reportes/horas_detalle_trabajadas', params).then((response) => {
        dispatch(setData(ITEM, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const descargarExcel = fecha => (dispatch, getStore) => {
    const resource = getStore().horasTrabajadas;
    const me = getStore().login.me;

    if (resource.fecha) {
        const profesor = me.id;
        const fecha_inicio = moment(resource.fecha).startOf('month').format('YYYY-MM-DD');
        const fecha_fin = moment(resource.fecha).endOf('month').format('YYYY-MM-DD');

        window.open(
            `/api/reportes/horas_trabajadas_pdf?profesor=${profesor}&fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}&auth_token=${sessionStorage.getItem("token")}`,
            '_blank'
        );
    } else {
        NotificationManager.error("No se ha seleccionado un profesor", 'ERROR');
    }
}

const changeFecha = value => (dispatch) => {
    dispatch(setData(FECHA, value));
    dispatch(listarResumen());
};

export const actions = {
    listarResumen,
    leer,
    descargarExcel,
    changeFecha,
};


// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [GRAFICA_BARRAS]: (state, { data }) => {
        return {
            ...state,
            grafica_barras: data,
        };
    },
    [GRAFICA_LINEAS]: (state, { data }) => {
        return {
            ...state,
            grafica_lineas: data,
        };
    },
    [ITEM]: (state, { data }) => {
        return {
            ...state,
            item: data,
        };
    },
    [FECHA]: (state, { data }) => {
        return {
            ...state,
            fecha: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: [],
    grafica_barras: {
        horas_trabajadas: 0,
        maximo_horas: 0,
    },
    grafica_lineas: [['Día', 'Horas trabajadas'],['Sin data', 0]],
    page: 1,
    item: [],
    fecha: moment(),
};

export default handleActions(reducers, initialState);
