import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderTextArea } from '../../../Utils/renderField';


const ModalSolicitudForm = (props) => {
    const { handleSubmit, cancelar } = props;
    return (
        <form name="SolicitudForm" className="form-validate mb-lg w-100" onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="d-flex flex-column align-items-center w-100">
                        <div className="flex-1 w-100">
                            <label htmlFor="mensaje" className="m-0">Mensaje</label>
                        </div>
                        <div className="flex-1 w-100">
                            <Field name="mensaje" component={renderTextArea} rows={5} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-none d-lg-flex mt-5 mx-5">
                <button type='button' style={{width: '10rem'}} onClick={cancelar} className="btn btn-secondary mx-2">Cancelar</button>
                <button type="submit" style={{width: '10rem'}} className="btn btn-primary mx-2">Enviar</button>
            </div>
            <div className="d-flex d-lg-none flex-column mt-5">
                <button type='button' onClick={cancelar} className="w-100 btn btn-secondary mb-3">Cancelar</button>
                <button type="submit" className="w-100 btn btn-primary">Enviar</button>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'SolicitudForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'mensaje': validators.exists()('Campo requerido.'),
        })
    }
})(ModalSolicitudForm);


