import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/profesor';

import RecuperarClase from './RecuperarClase';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.profesor,
        form_examen: state.form.FormulariosForm
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(RecuperarClase);
