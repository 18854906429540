import React from 'react';
import NumberFormat from 'react-number-format';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const RenderNumber = ({ value, decimalScale, className, suffix }) => {
  return (
    <NumberFormat
      className={className}
      decimalScale={decimalScale || 0}
      fixedDecimalScale
      value={value}
      thousandSeparator
      prefix=""
      suffix={suffix}
      displayType="text"
    />
  );
};

export const RenderEditor = ({ value, height}) => {
    // const op_toolbar = disabled ? [] : ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'history'];
    const op_toolbar = [];
    return (
        <Editor
            editorState={value ? value : EditorState.createEmpty()}
            wrapperClassName="editor-container"
            wrapperStyle={{height: height ? height : "20rem", border: "none"}}
            toolbarClassName={`editor-toolbar disabled`}
            editorClassName="editor-content"
            editorStyle={{height: height ? height : "20rem"}}
            // onEditorStateChange={(value) => input.onChange(value)}
            toolbar={{
                options: op_toolbar,
                colorPicker: {
                    // icon: color,
                    className: "editor-colorpicker",
                    // component: undefined,
                    // popupClassName: undefined,
                    colors: [
                        'rgb(28, 55, 94)', 'rgb(3, 54, 181)', 'rgb(54, 106, 237)',
                        'rgb(83, 165, 19)', 'rgb(90, 206, 0)', 'rgb(104, 106, 117)',
                        'rgb(140, 143, 155)', 'rgb(186, 191, 206)', 'rgb(209, 216, 236)',
                        'rgb(219, 228, 252)', 'rgb(233, 32, 106)', 'rgb(255, 255, 255)',
                        'rgb(0, 0, 0)'
                    ],
                },
                link: {
                    className: "editor-link",
                },
            }}
            readOnly={true}
        />
    )
};

export const RenderCurrency = ({ value, className }) => {
  return (
    <NumberFormat
      className={className}
      decimalScale={2}
      fixedDecimalScale
      value={value}
      thousandSeparator
    //   prefix="Q "
      displayType="text"
      suffix=" €"
    />
  );
};

export const RenderDateTime = ({ value, className }) => {
  if (value) {
    const fecha = new Date(value);
    return (
      <span className={className}>
        {fecha.toLocaleDateString()}
        {' '}
        {fecha.toLocaleTimeString()}
      </span>
    );
  }
  return (<span className={className}>{value}</span>);
};

export const ReadFields = {
  renderCurrency: RenderCurrency,
  renderNumber: RenderNumber,
};
