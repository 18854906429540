import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import {USER_ALUMNO, USER_PROFESOR} from '../../../../../utility/constants';
import HomeAlumno from './HomeAlumno';
import { CalendarioProfesor } from '../../CalendarioProfesor';
import { CalendarioAlumno } from '../../CalendarioAlumno';


export default class HomeContainer extends Component {

    // componentDidMount() {
    //     console.log('props ', this.props)
    //     const idCurso = this.props.match.params.id;
    //     console.log("parametros ", idCurso)
    // }

    render() {
        const { me } = this.props;
        const idCurso = this.props.match.params.id;

        if (me.tipo_usuario == USER_ALUMNO && !me.activar_plataforma) {
            return <HomeAlumno {...this.props} />
        }
        if (me.tipo_usuario == USER_ALUMNO && me.activar_plataforma && !!me.pago_realizado) {
            return (
                <div className="mb-4 col-12 mt-4">
                    {/* <CalendarioProfesor /> */}
                    <CalendarioAlumno />
                </div>
            )
        }
        if (me.tipo_usuario == USER_PROFESOR) {
            return (
                <div className="mb-4 col-12 mt-4">
                    <CalendarioProfesor />
                </div>
            )
        }
        return (
            <div className="mb-4 col-12 mt-4">
            </div>
        )
    }
}
