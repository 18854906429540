import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';

const LOADER = 'LOADER_CURSOS_PROFESOR';
const DATA = 'DATA_CURSOS_PROFESOR';
const DATA2 = 'DATA2_CURSOS_PROFESOR';
const DATA3 = 'DATA3_CURSOS_PROFESOR';
const DATA4 = 'DATA4_CURSOS_PROFESOR';
const DATA5 = 'DATA5_CURSOS_PROFESOR';
const DATA6 = 'DATA6_CURSOS_PROFESOR';
const ITEM_DATA = 'ITEM_CURSOS_PROFESOR';
const PAGE = 'PAGE_CURSOS_PROFESOR';
const PAGE2 = 'PAGE2_CURSOS_PROFESOR';
const PAGE3 = 'PAGE3_CURSOS_PROFESOR';
const PAGE4 = 'PAGE4_CURSOS_PROFESOR';
const PAGE5 = 'PAGE5_CURSOS_PROFESOR';
const PAGE6 = 'PAGE6_CURSOS_PROFESOR';
const ORDERING = 'ORDERING_CURSOS_PROFESOR';
const SEARCH = 'SEARCH_CURSOS_PROFESOR';
const SEARCH2 = 'SEARCH2_CURSOS_PROFESOR';
const SEARCH3 = 'SEARCH3_CURSOS_PROFESOR';
const SEARCH4 = 'SEARCH4_CURSOS_PROFESOR';
const SEARCH5 = 'SEARCH5_CURSOS_PROFESOR';
const ESTADO_EVENTO = 'ESTADO_EVENTO_CURSOS_PROFESOR';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = (type, page) => ({
    type,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = (type, search) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------


const listar = (page = 1) => (dispatch, getStore) => {
    const me = getStore().login.me;
    const resource = getStore().cursosProfe;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.profesor = me.id;
    dispatch(setLoader(true));
    api.get('aula', params).then((response) => {
        dispatch(setData(DATA ,response));
        dispatch(setPage(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarClases = (page = 1, idAula) => (dispatch, getStore) => {
    const resource = getStore().cursosProfe;
    const params = { page, aula: idAula };
    params.search = resource.search2;
    if (resource.estado_evento)
        params.estado_evento = resource.estado_evento.value;
    dispatch(setLoader(true));
    api.get('clase', params).then((response) => {
        dispatch(setData(DATA2 ,response));
        dispatch(setPage(PAGE2, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarAlumnos = (page = 1, idAula) => (dispatch, getStore) => {
    const resource = getStore().cursosProfe;
    const params = { page, aula: idAula };
    params.search = resource.search3;
    dispatch(setLoader(true));
    api.get(`aula/alumnos_asignados`, params).then((response) => {
        dispatch(setData(DATA3 ,response));
        dispatch(setPage(PAGE3, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarArchivos = (page = 1, idAula) => (dispatch, getStore) => {
    const resource = getStore().cursosProfe;
    const params = { page, clase__aula: idAula };
    params.search = resource.search4;
    dispatch(setLoader(true));
    api.get(`archivos_asignados`, params).then((response) => {
        dispatch(setData(DATA4 ,response));
        dispatch(setPage(PAGE4, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarForms = (page = 1, idAula) => (dispatch, getStore) => {
    const resource = getStore().cursosProfe;
    const params = { page, aula: idAula };
    params.search = resource.search5;
    dispatch(setLoader(true));
    api.get(`formularios_asignados`, params).then((response) => {
        dispatch(setData(DATA5 ,response));
        dispatch(setPage(PAGE5, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`aula/${id}`).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    data.tipo_usuario = USER_ALUMNO;
    dispatch(setLoader(true));
    api.post('user', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/CURSOS_PROFESOR'));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (data, id) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'user'}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/CURSOS_PROFESOR'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const eliminarArchivo = (id, idAula) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`archivos_asignados/${id}`).then(() => {
        dispatch(listarArchivos(1, idAula));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminarForm = (id, idAula) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`formularios_asignados/${id}`).then(() => {
        dispatch(listarForms(1, idAula));
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('EditarAlumnoForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(SEARCH, search));
    dispatch(listar());
};

const searchChange2 = (search, idAula) => (dispatch) => {
    dispatch(setSearch(SEARCH2, search));
    dispatch(listarClases(1, idAula));
};

const searchChange3 = (search, idAula) => (dispatch) => {
    dispatch(setSearch(SEARCH3, search));
    dispatch(listarAlumnos(1, idAula));
};

const searchChange4 = (search, idAula) => (dispatch) => {
    dispatch(setSearch(SEARCH4, search));
    dispatch(listarArchivos(1, idAula));
};

const searchChange5 = (search, idAula) => (dispatch) => {
    dispatch(setSearch(SEARCH5, search));
    dispatch(listarForms(1, idAula));
};

const changeEstadoEvento = (value, idAula) => (dispatch) => {
    dispatch(setData(ESTADO_EVENTO, value));
    dispatch(listarClases(1, idAula));
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().cursosProfe.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const asignarArchivo = (data, idAula) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('archivos_asignados', data).then(() => {
        NotificationManager.success('Archivo Asignado', 'Éxito', 3000);
        dispatch(push(`/cursos/${idAula}/`));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const asignarForm = (data, idAula) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('formularios_asignados', data).then(() => {
        NotificationManager.success('Archivo Asignado', 'Éxito', 3000);
        dispatch(push(`/cursos/${idAula}/`));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error) {
            if (error.detail) {
                mensaje = error.detail;
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const listarRecuperaciones = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().cursosProfe;
    const params = { page };
    dispatch(setLoader(true));
    api.get('reportes/recuperaciones', params).then((response) => {
        dispatch(setData(DATA6, response));
        dispatch(setPage(PAGE6, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    listar,
    listarClases,
    listarAlumnos,
    listarArchivos,
    listarForms,
    leer,
    crear,
    editar,
    eliminarArchivo,
    eliminarForm,
    searchChange,
    searchChange2,
    searchChange3,
    searchChange4,
    searchChange5,
    changeEstadoEvento,
    onSortChange,
    cleanForm,
    asignarArchivo,
    asignarForm,
    listarRecuperaciones,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA2]: (state, { data }) => {
        return {
            ...state,
            data2: data,
        };
    },
    [DATA3]: (state, { data }) => {
        return {
            ...state,
            data3: data,
        };
    },
    [DATA4]: (state, { data }) => {
        return {
            ...state,
            data4: data,
        };
    },
    [DATA5]: (state, { data }) => {
        return {
            ...state,
            data5: data,
        };
    },
    [DATA6]: (state, { data }) => {
        return {
            ...state,
            data6: data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE2]: (state, { page }) => {
        return {
            ...state,
            page2: page,
        };
    },
    [PAGE3]: (state, { page }) => {
        return {
            ...state,
            page3: page,
        };
    },
    [PAGE4]: (state, { page }) => {
        return {
            ...state,
            page4: page,
        };
    },
    [PAGE5]: (state, { page }) => {
        return {
            ...state,
            page5: page,
        };
    },
    [PAGE6]: (state, { page }) => {
        return {
            ...state,
            page6: page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SEARCH2]: (state, { search }) => {
        return {
            ...state,
            search2: search,
        };
    },
    [SEARCH3]: (state, { search }) => {
        return {
            ...state,
            search3: search,
        };
    },
    [SEARCH4]: (state, { search }) => {
        return {
            ...state,
            search4: search,
        };
    },
    [SEARCH5]: (state, { search }) => {
        return {
            ...state,
            search5: search,
        };
    },
    [ESTADO_EVENTO]: (state, { data }) => {
        return {
            ...state,
            estado_evento: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data2: {
        results: [],
        count: 0,
    },
    data3: {
        results: [],
        count: 0,
    },
    data4: {
        results: [],
        count: 0,
    },
    data5: {
        results: [],
        count: 0,
    },
    data6: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    page2: 1,
    page3: 1,
    page4: 1,
    page5: 1,
    page6: 1,
    ordering: '',
    search: '',
    search2: '',
    search3: '',
    search4: '',
    search5: '',
    estado_evento: null,
};

export default handleActions(reducers, initialState);
