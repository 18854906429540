import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/biblioteca/formularios';
import FormulariosList from './Formulario';


const ms2p = (state) => {
    return {
        ...state.formularios,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(FormulariosList);
