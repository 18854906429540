import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import moment from 'moment';

export default class AlumnosList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ ver: "cursos" })}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            Cursos
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="clase_grupal"
                            dataSort
                            dataFormat={ cell => cell ? 'Grupal' : 'Individual' }
                        >
                            Tipo
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio"
                            dataSort
                            dataFormat={ cell => moment(cell).format('DD/MM/YY') }
                        >
                            Fecha inicio
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_fin"
                            dataSort
                            dataFormat={ cell => moment(cell).format('DD/MM/YY') }
                        >
                            Fecha Fin
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn
                            dataAlign="left"
                            dataField="id"
                            width='8%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn> */}
                    </Grid>
                </Card>
            </div>
        )
    }
}
