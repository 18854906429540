import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import {NotificationManager} from "react-notifications";
import { api } from "api";
import moment from 'moment';
import { EXAMEN } from '../../../../../utility/constants';

import FormularioAsignarForm from './AsignarForm';

export default class FormularioAsignar extends Component {

    state = {
        seleccionado: null,
        forms: [],
        search: '',
        nivel: null,
    }

    componentWillMount() {
        api.get('formulario/list_forms').then((response) => {
            this.setState({forms: response});
        }).catch(() => {
        });
    }

    getCursos = search => {
        const { me } = this.props;
        const id = this.props.match.params.id;
        return api.get("aula/cursos_alumnos", { search, profesor: me.id, alumno: id }).then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
    }

    searchChange = search => {
        this.setState({search});
        const params = { search };
        if (this.state.nivel)
            params.nivel = this.state.nivel.id;
        api.get('formulario/list_forms', params).then((response) => {
            this.setState({forms: response});
        }).catch(() => {
        });
    };

    changeNivel = nivel => {
        this.setState({nivel});
        const params = { search: this.state.search };
        if (nivel)
            params.nivel = nivel.id;
        api.get('formulario/list_forms', params).then((response) => {
            this.setState({forms: response});
        }).catch(() => {
        });
    };

    seleccionar = (index, item) => {
        this.setState({seleccionado: item});
    }

    onSubmit = (values) => {
        const { seleccionado } = this.state;
        const id = this.props.match.params.id;
        if (this.state.seleccionado == null) {
            NotificationManager.error("Seleccione un archivo", 'ERROR');
        } else {
            const data = {
                aula: values.aula.id,
                formulario: seleccionado.id,
                alumno: id,
                profesor: this.props.me.id
            }
            const fecha = moment(`${moment(values.fecha).format('YYYY-MM-DD')} ${values.hora}`);
            if (seleccionado.tipo == EXAMEN) {
                data.fecha_hora_examen = fecha.format();
                data.tiempo_maximo = values.tiempo_maximo
            } else {
                data.fecha_hora_entrega = fecha.format();
            }
            console.log(data);
            this.props.asignarForm(data, id);
        }
    }

    render() {
        const { crear, update } = this.props;
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm col={12}>
                    <LoadMask loading={loader} blur>
                        <FormularioAsignarForm
                            {...this.props}
                            onSubmit={this.onSubmit}
                            forms={this.state.forms}
                            seleccionado={this.state.seleccionado}
                            seleccionar={this.seleccionar}
                            idAlumno={this.props.match.params.id}
                            nivel={this.state.nivel}
                            changeNivel={this.changeNivel}
                            getCursos={this.getCursos}
                            searchChange={this.searchChange}
                            search={this.state.search}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
