import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm, change } from "redux-form";
import { EditorState, convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { DIAS_SEMANA, EXAMEN, CLASE_RECUPERACION } from '../../../utility/constants';
import moment from 'moment';
import _ from "lodash";

const LOADER = 'LOADER_PROFESOR';
const EVENTOS = 'EVENTOS_PROFESOR';
const EVENTOS2 = 'EVENTOS2_ALUMNOS';
const ITEM_DATA = 'ITEM_PROFESOR';
const TIPO_EVENTO = 'TIPO_EVENTO_PROFESOR';
const FECHA_INICIO = 'FECHA_INICIO_PROFESOR';
const FECHA_FIN = 'FECHA_FIN_PROFESOR';
const ARCHIVOS = 'ARCHIVOS_PROFESOR';
const SEARCH_ARCHIVOS = 'SEARCH_ARCHIVOS_PROFESOR';
const NIVEL_ARCHIVOS = 'NIVEL_ARCHIVOS_PROFESOR';
const FORMS = 'FORMS_PROFESOR';
const SEARCH_FORMS = 'SEARCH_FORMS_PROFESOR';
const NIVEL_FORMS = 'NIVEL_FORMS_PROFESOR';
const DETALLE_AULA = 'DETALLE_AULA_PROFESOR';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data
})

const setItem = item => ({
    type: ITEM_DATA,
    item,
});


// ------------------------------------
// Actions
// ------------------------------------


const obtenerActividades = (id) => (dispatch, getStore) => {
    const resource = getStore().profesor;
    const params = { profesor: id };
    if (resource.tipo_evento)
        params.tipo_evento = resource.tipo_evento.value;
    if (resource.fecha_inicio)
        params.fecha_inicial = moment(resource.fecha_inicio).format("YYYY-MM-DD")
    if (resource.fecha_fin)
        params.fecha_fin = moment(resource.fecha_fin).format("YYYY-MM-DD")
    dispatch(setLoader(true));
    api.get('clase/get_clases_profesor', params).then((response) => {
        let eventos = response.results;
        eventos = eventos.map(item => {
            item.start = new Date(moment(item.fecha_hora_inicio));
            item.end = new Date(moment(item.fecha_hora_fin));
            item.tipo = item.tipo_evento;
            item.title = item.titulo;
            return item;
        })
        dispatch(setData(EVENTOS, eventos));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerAgenda = (id) => (dispatch, getStore) => {
    const resource = getStore().profesor;
    const params = { profesor: id, agenda: true };
    params.fecha_inicial = moment().format("YYYY-MM-DD");
    params.fecha_fin = moment().add(7, 'd').format("YYYY-MM-DD");
    if (resource.tipo_evento)
        params.tipo_evento = resource.tipo_evento.value;
    dispatch(setLoader(true));
    api.get('clase/get_clases_profesor', params).then((response) => {
        dispatch(setData(EVENTOS2, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`clase/${id}`).then((evento) => {
        const blocksFromHtml = htmlToDraft(evento.contenido ? evento.contenido : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        const data = { ...evento, contenido: EditorState.createWithContent(contentState) };
        data.dia = moment(evento.fecha_hora_inicio).get('d');
        data.dia = _.find(DIAS_SEMANA, { id: data.dia }).value;
        data.hora_inicio = moment(evento.fecha_hora_inicio).format("HH:mm:ss");
        data.hora_fin = moment(evento.fecha_hora_fin).format("HH:mm:ss");
        data.fecha = moment(data.fecha_hora_inicio);
        dispatch(setItem(data));
        dispatch(initializeForm('EventoEditForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = (data, id, closeModal) => (dispatch) => {
    dispatch(setLoader(true));
    const dia = moment(data.fecha).format("YYYY-MM-DD")
    data.fecha_hora_inicio = moment(`${dia} ${data.hora_inicio}`).format();
    data.fecha_hora_fin = moment(`${dia} ${data.hora_fin}`).format();
    api.post('clase', data).then(() => {
        NotificationManager.success('Clase de recuperacion creada', 'Éxito', 3000);
        dispatch(obtenerActividades(id));
        closeModal();
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error) {
            if (error.detail) {
                mensaje = error.detail;
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}


const crearRecuperacion = (data, idAula) => (dispatch) => {
    dispatch(setLoader(true));
    const draw = data.contenido;
    const body = {...data, tipo_evento: CLASE_RECUPERACION, aula: idAula};
    if (draw) {
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        console.log("editor ", html);
        body.contenido = html;
    } else {
        body.contenido = null;
    }
    const dia = moment(body.fecha).format("YYYY-MM-DD")
    body.fecha_hora_inicio = moment(`${dia} ${body.hora_inicio}`).format("YYYY-MM-DD HH:mm:ss");
    body.fecha_hora_fin = moment(`${dia} ${body.hora_fin}`).format("YYYY-MM-DD HH:mm:ss");
    api.post('clase', body).then((response) => {
        NotificationManager.success('Clase de recuperacion creada', 'Éxito', 3000);
        dispatch(push(`/cursos/${idAula}`));
    }).catch((error) => {
        let mensaje = "Error en la creación";
        if (error) {
            if (error.detail) {
                mensaje = error.detail;
            }
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const editar = (id, data) => (dispatch) => {
    console.log("putoo")
    const url = window.location.href;
    dispatch(setLoader(true));
    const draw = data.contenido;
    const body = {...data};
    if (draw) {
        const html = draftToHtml(convertToRaw(draw.getCurrentContent()))
        console.log("editor ", html);
        body.contenido = html;
    } else {
        body.contenido = null;
    }
    const dia = moment(body.fecha).format("YYYY-MM-DD")
    body.fecha_hora_inicio = moment(`${dia} ${body.hora_inicio}`).format("YYYY-MM-DD HH:mm:ss");
    body.fecha_hora_fin = moment(`${dia} ${body.hora_fin}`).format("YYYY-MM-DD HH:mm:ss");
    api.put(`clase/${id}`, body).then((response) => {
        NotificationManager.success('Temario agregado', 'Éxito', 3000);
        if (url.includes("editar")) {
            dispatch(push(`/cursos/${data.aula}/`));
        } else {
            dispatch(push('/'));
        }
    }).catch((error) => {
        let mensaje = "Error en la edición";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const iniciarFechas = () => (dispatch) => {
    const fecha = moment();
    const fecha2 = moment();
    if (fecha.get('d') == 0) {
        dispatch(setData(FECHA_INICIO, fecha.day(-6)));
        dispatch(setData(FECHA_FIN, fecha2));
    } else {
        dispatch(setData(FECHA_INICIO, fecha.day(1)));
        dispatch(setData(FECHA_FIN, fecha2.day(7)));
    }
};

const changeTipoEvento = (value, id, agenda=false) => (dispatch) => {
    dispatch(setData(TIPO_EVENTO, value));
    if (agenda) {
        dispatch(obtenerAgenda(id));
    } else {
        dispatch(obtenerActividades(id));
    }
};

const changeFechas = (value, id) => (dispatch) => {
    const fecha1 = moment(value[0]);
    const fecha2 = moment(value[6]);
    dispatch(setData(FECHA_INICIO, fecha1));
    dispatch(setData(FECHA_FIN, fecha2));
    dispatch(obtenerActividades(id));
};

const setearFormEventoDetalle = (evento) => (dispatch) => {

    const blocksFromHtml = htmlToDraft(evento.contenido ? evento.contenido : "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    const data = { ...evento, contenido: EditorState.createWithContent(contentState)};
    data.dia = moment(evento.fecha_hora_inicio).get('d');
    data.dia = _.find(DIAS_SEMANA, { id: data.dia }).value;
    data.hora_inicio = moment(evento.fecha_hora_inicio).format("HH:mm:ss");
    data.hora_fin = moment(evento.fecha_hora_fin).format("HH:mm:ss");
    dispatch(initializeForm('EventoEditForm', data));

};

const setearArchivo = (archivo, cerrarModal, recuperacion=false) => (dispatch, getStore) => {
    const resource = recuperacion ? getStore().form.ReposicionForm.values : getStore().form.EventoEditForm.values;
    const archivos = resource.archivos ? _.cloneDeep(resource.archivos) : [];

    const archi = _.find(archivos, item => item.archivo.id == archivo.id);

    if (archi) {
        NotificationManager.error('Este archivo ya se encuentra asignado', 'ERROR', 0);
    } else {
        archivos.push({
            archivo: {...archivo}
        });

        if (recuperacion) {
            dispatch(change('ReposicionForm', 'archivos', archivos));
        } else {
            dispatch(change('EventoEditForm', 'archivos', archivos));
        }
        cerrarModal();
    }

};

const eliminarArchivo = (index, recuperacion=false) => (dispatch, getStore) => {
    const resource = recuperacion ? getStore().form.ReposicionForm.values : getStore().form.EventoEditForm.values;
    let archivos = _.cloneDeep(resource.archivos);

    archivos = _.filter(archivos, (item, _index) => index != _index);

    if (recuperacion) {
        dispatch(change('ReposicionForm', 'archivos', archivos));
    } else {
        dispatch(change('EventoEditForm', 'archivos', archivos));
    }
};

const setearFormulario = (form, values, cerrarModal, recuperacion=false) => (dispatch, getStore) => {
    const resource = recuperacion ? getStore().form.ReposicionForm.values : getStore().form.EventoEditForm.values;
    const forms = resource.formularios ? _.cloneDeep(resource.formularios) : [];

    const formi = _.find(forms, item => item.formulario.id == form.id);

    if (formi) {
        NotificationManager.error('Este formulario ya se encuentra asignado', 'ERROR', 0);
    } else {
        const fecha = moment(`${moment(values.fecha).format('YYYY-MM-DD')} ${values.hora}`);

        if (form.tipo == EXAMEN) {
            forms.push({
                formulario: {...form},
                // fecha_hora_examen_label: fecha.format("YYYY-MM-DD HH:mm:ss"),
                fecha_hora_examen: fecha.format(),
                tiempo_maximo: values.tiempo_maximo ? values.tiempo_maximo : null,
            });
        } else {
            forms.push({
                formulario: {...form},
                // fecha_hora_entrega_label: fecha.format("YYYY-MM-DD HH:mm:ss"),
                fecha_hora_entrega: fecha.format(),
            });
        }

        if (recuperacion) {
            dispatch(change('ReposicionForm', 'formularios', forms));
        } else {
            dispatch(change('EventoEditForm', 'formularios', forms));
        }

        cerrarModal();
    }
};

const eliminarFormulario = (index, recuperacion=false) => (dispatch, getStore) => {
    const resource = recuperacion ? getStore().form.ReposicionForm.values : getStore().form.EventoEditForm.values;
    let forms = resource.formularios ? _.cloneDeep(resource.formularios) : [];

    forms = _.filter(forms, (item, _index) => index != _index);

    if (recuperacion) {
        dispatch(change('ReposicionForm', 'formularios', forms));
    }else {
        dispatch(change('EventoEditForm', 'formularios', forms));
    }
};

const searchArchivos = search => (dispatch) => {
    dispatch(setData(SEARCH_ARCHIVOS, search));
    dispatch(obtenerArchivosProfesor());
};

const changeNivelArchivo = value => (dispatch) => {
    dispatch(setData(NIVEL_ARCHIVOS, value));
    dispatch(obtenerArchivosProfesor());
};

const obtenerArchivosProfesor = () => (dispatch, getStore) => {
    const resource = getStore().profesor;
    const params = { search: resource.search_archivos  };
    if (resource.nivel_archivo)
        params.nivel = resource.nivel_archivo.id;
    dispatch(setLoader(true));
    api.get('archivos', params).then((response) => {
        dispatch(setData(ARCHIVOS, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchForms = search => (dispatch) => {
    dispatch(setData(SEARCH_FORMS, search));
    dispatch(obtenerFormsProfesor());
};

const changeNivelForm = value => (dispatch) => {
    dispatch(setData(NIVEL_FORMS, value));
    dispatch(obtenerFormsProfesor());
};

const obtenerFormsProfesor = () => (dispatch, getStore) => {
    const resource = getStore().profesor;
    const params = { search: resource.search_forms  };
    if (resource.nivel_form)
        params.nivel = resource.nivel_form.id;
    dispatch(setLoader(true));
    api.get('formulario/list_forms', params).then((response) => {
        dispatch(setData(FORMS, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const obtenerDetalleAula = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`aula/${id}/horas_pendientes_recuperar`).then((response) => {
        dispatch(setData(DETALLE_AULA, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const resetDetalleAula = () => (dispatch) => {
    dispatch(setData(DETALLE_AULA, null));
};

const preCargarEvento = (evento) => (dispatch) => {
    const start = moment(evento.start);
    const end = moment(evento.end);
    // const dia = _.find(DIAS_SEMANA, { id: start.get('d') });
    const data = {
        fecha: start,
        hora_inicio: start.format('HH:mm'),
        hora_fin: end.format('HH:mm')
    }
    dispatch(initializeForm('CrearEventoForm', data));
};

const generarLinks = id => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`clase/${id}/crear_links`).then((response) => {
        window.open(response.link, "_blank");
    }).catch( error => {
        let mensaje = "Error al obtener el link de la clase";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });

}


export const actions = {
    obtenerActividades,
    obtenerAgenda,
    leer,
    crear,
    crearRecuperacion,
    editar,
    iniciarFechas,
    changeTipoEvento,
    changeFechas,
    setearFormEventoDetalle,
    obtenerArchivosProfesor,
    searchArchivos,
    changeNivelArchivo,
    obtenerFormsProfesor,
    searchForms,
    changeNivelForm,
    setearArchivo,
    eliminarArchivo,
    setearFormulario,
    eliminarFormulario,
    obtenerDetalleAula,
    preCargarEvento,
    resetDetalleAula,
    generarLinks
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [EVENTOS]: (state, { data }) => {
        return {
            ...state,
            eventos: data,
        };
    },
    [EVENTOS2]: (state, { data }) => {
        return {
            ...state,
            eventos2: data,
        };
    },
    [TIPO_EVENTO]: (state, { data }) => {
        return {
            ...state,
            tipo_evento: data,
        };
    },
    [FECHA_INICIO]: (state, { data }) => {
        return {
            ...state,
            fecha_inicio: data,
        };
    },
    [FECHA_FIN]: (state, { data }) => {
        return {
            ...state,
            fecha_fin: data,
        };
    },
    [ARCHIVOS]: (state, { data }) => {
        return {
            ...state,
            archivos: data,
        };
    },
    [SEARCH_ARCHIVOS]: (state, { data }) => {
        return {
            ...state,
            search_archivos: data,
        };
    },
    [NIVEL_ARCHIVOS]: (state, { data }) => {
        return {
            ...state,
            nivel_archivo: data,
        };
    },
    [FORMS]: (state, { data }) => {
        return {
            ...state,
            forms: data,
        };
    },
    [SEARCH_FORMS]: (state, { data }) => {
        return {
            ...state,
            search_forms: data,
        };
    },
    [NIVEL_FORMS]: (state, { data }) => {
        return {
            ...state,
            nivel_form: data,
        };
    },
    [DETALLE_AULA]: (state, { data }) => {
        return {
            ...state,
            detalle_aula: data,
        };
    },
};

export const initialState = {
    loader: false,
    eventos: [],
    eventos2: [],
    item: {},
    tipo_evento: null,
    fecha_inicio: null,
    fecha_fin: null,
    archivos: [],
    search_archivos: '',
    nivel_archivo: null,
    forms: [],
    search_forms: '',
    nivel_form: null,
    detalle_aula: null,
};

export default handleActions(reducers, initialState);
