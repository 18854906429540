import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/alumnos";
import NotificacionPermanente from './NotificacionPermanente';


const ms2p = (state) => {
  return {
    me: state.login.me,
    ...state.alumnos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(NotificacionPermanente);
