import React, { Component, cloneElement } from "react";
import Chart from "react-google-charts";
import Grid from "../../../Utils/Grid";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from "Utils/Cards/Card";
import MonthPicker from "../../../Utils/MonthPicker";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import HeaderFiltros from "../../../Utils/Grid/HeaderFiltros";
import moment from 'moment';


export default class ReporteHorasTrabajadas extends Component {

    componentWillMount() {
        this.props.listarResumen();
    }

    render() {
        const { loader, data, grafica_barras, grafica_lineas } = this.props;

        return (
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <MonthPicker
                            value={this.props.fecha}
                            onChange={this.props.changeFecha}
                            placeholder="Fecha"
                        />
                    }
                />
                <Card>
                    <LoadMask loading={loader} blur>
                        <div className="d-flex flex-column flex-1 mt-3 mb-2">
                            <div className="flex-1">
                                <div className="row col-12 mx-0">
                                    <span className="text-sm azul-claro bold">
                                        Gráfica de horas trabajadas
                                    </span>
                                    <div
                                        className="d-flex w-100 bg-gris-claro"
                                        style={{ height: "3px" }}
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <Chart
                                    width={"100%"}
                                    height={"40vh"}
                                    chartType="ColumnChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        [
                                            "No.",
                                            "Máximo de horas",
                                            "Horas trabajadas",
                                        ],
                                        ["", grafica_barras.maximo_horas, grafica_barras.horas_trabajadas],
                                    ]}
                                    options={{
                                        chartArea: { width: "60%" },
                                        bar: { groupWidth: "35%" },
                                        colors: ["#53A513", "#366AED"],
                                        hAxis: {
                                            title: "",
                                            minValue: 0,
                                        },
                                        vAxis: {
                                            title: "Horas trabajadas",
                                            baselineColor: "#1C375E",
                                            titleTextStyle: {
                                                color: "#1C375E",
                                                bold: true,
                                                italic: false,
                                            },
                                            textStyle: {
                                                color: "#1C375E",
                                                bold: false,
                                                italic: false,
                                            },
                                        },
                                    }}
                                    rootProps={{ "data-testid": "4" }}
                                />
                            </div>
                            <div className="flex-1">
                                <Chart
                                    width="100%"
                                    height={"45vh"}
                                    chartType="LineChart"
                                    loader={<LoadMask loading={loader} blur />}
                                    // data={data}
                                    data={grafica_lineas}
                                    options={{
                                        hAxis: {
                                            title: "Días",
                                            baselineColor: "#1C375E",
                                            titleTextStyle: {
                                                color: "#1C375E",
                                                bold: true,
                                                italic: false,
                                            },
                                            textStyle: {
                                                color: "#1C375E",
                                                bold: false,
                                                italic: false,
                                            },
                                        },
                                        vAxis: {
                                            title: "Horas trabajadas",
                                            position: "top",
                                            baselineColor: "#1C375E",
                                            titleTextStyle: {
                                                color: "#1C375E",
                                                bold: true,
                                                italic: false,
                                            },
                                            textStyle: {
                                                color: "#1C375E",
                                                bold: false,
                                                italic: false,
                                            },
                                            minValue: 0,
                                        },
                                        colors: ["#366AED"],
                                        pointSize: 7,
                                        curveType: "function",
                                        chartArea: {
                                            width: "80%",
                                            height: "75%",
                                        },
                                        legend: { position: "none" },
                                    }}
                                    rootProps={{ "data-testid": "2" }}
                                />
                            </div>
                        </div>
                    </LoadMask>
                    <div className="mt-3 px-lg-3">
                        <div className="mb-4 col-12 mt-4">
                            <Card>
                                <div className='d-flex flex-1 justify-content-end align-items-center mb-3'>
                                    <div className='text-right'>
                                        <button type='button' onClick={this.props.descargarExcel} className="btn btn-primary">Descargar PDF</button>
                                    </div>
                                </div>
                                <Grid
                                    hover
                                    striped data={{ results: data, count: 1 }}
                                    loading={loader}
                                    pagination={false}
                                >
                                    <TableHeaderColumn
                                        dataField="fecha"
                                        dataAlign="center"
                                        isKey
                                        width='15%'
                                        dataFormat={standardActions({ ver: "horas_trabajadas" })}
                                    />
                                    <TableHeaderColumn
                                        dataField="fecha"
                                        dataFormat={cell => moment(cell).format("LL")}
                                    >
                                        FECHA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="total"
                                    >
                                        TOTAL HORAS
                                    </TableHeaderColumn>
                                </Grid>
                            </Card>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}
