import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/configComercial';
import HomeContainer from './HomeContainer';


const ms2p = (state) => {
  return {
    me: state.login.me,
    ...state.configComercial,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(HomeContainer);
