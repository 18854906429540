import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment from 'moment';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import { ESTADOS_EVENTOS, EJERCICIO } from '../../../../../utility/constants';
import { RenderEditor } from '../../../Utils/renderField/renderReadField';

import { ARCHIVO } from '../../../../../utility/constants';
import { Link } from 'react-router-dom';

const descargar = require("../../../../../../assets/img/iconos/descargar.png");
const link = require("../../../../../../assets/img/iconos/link.png");

export default class CursosAlumno extends Component{

    componentWillMount(){
        if(this.props.match.params.id){
            this.props.obtener_clase(this.props.match.params.id_clase);
            this.props.obtener_archivos_clase(this.props.match.params.id_clase);
            this.props.obtener_formularios_clase(this.props.match.params.id_clase);
        }
    }

    onPageArchivosChange = (page) =>{
        const id = this.props.match.params.id;
        this.props.obtener_archivos_clase(id, page);
    }

    onPageFormulariosChange = (page) =>{
        const id = this.props.match.params.id;
        this.props.obtener_formularios_clase(id, page);
    }

    resolverExamen = (data) => {
        this.props.actions2.validarExamen(data);
    }

    render() {
        const { listar_cursos: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page_archivos, page_formularios, item, archivos_clase, formularios_clase, } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <div className="col-12 d-flex justify-content-start align-items-center">
                    <h1 className="azul-claro bold">{item && item.titulo ? item.titulo : "Titulo"}</h1>
                </div>
                <Card>
                    <div className="col-12 d-flex flex-column align-items-center justify-content-center mb-3">
                        <div className="col-md-11 col-12 bg-celeste-claro card-gris-encabezado">
                            <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                                <div className="col-md-9 col-12 row">
                                    <div className="col-md-4 col-12 d-flex flex-column">
                                        <span className="azul-claro bold">Nombre de la clase</span>
                                        <span className="black">{item && item.titulo ? item.titulo : ""}</span>
                                    </div>
                                    <div className="col-md-3 col-12 d-flex flex-column">
                                        <span className="azul-claro bold">Fecha</span>
                                        <span className="black">{item && item.fecha ? moment(item.fecha).format("DD/MM/YYYY") : ""}</span>
                                    </div>
                                    <div className="col-md-5 col-12 row px-0">
                                        <div className="col-md-6 col-12 d-flex flex-column">
                                            <span className="azul-claro bold">Hora inicio</span>
                                            <span className="black">{item && item.fecha_hora_inicio ? moment(item.fecha_hora_inicio).format("HH:mm") : ""}</span>
                                        </div>
                                        <div className="col-md-6 col-12 d-flex flex-column">
                                            <span className="azul-claro bold">Hora fin</span>
                                            <span className="black">{item && item.fecha_hora_fin ? moment(item.fecha_hora_fin).format("HH:mm") : ""}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-12 mt-md-0 mt-3">
                                    {item && item.link_grabacion && item.link_grabacion.recording ?
                                        (
                                            <a href={item.link_grabacion.recording.playback.format.url} target="_blank" className="btn btn-block btn-primary clickable-element" download >Ver grabacion de la clase</a>
                                        )
                                        :
                                        (
                                            <button className="btn btn-block btn-primary" disabled="disabled">Ver grabacion de la clase</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-11 col-12 mb-4 mt-5 px-0">
                            <span className="text-sm azul-claro bold">Temario de la clase</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                            {item && item.contenido ?
                                (
                                    <div className="d-flex flex-column w-100 mt-3">
                                        <div className="flex-1">
                                            <RenderEditor value={item.contenido} height="20rem"/>
                                        </div>
                                    </div>
                                )
                                :
                                (
                                    <div className="d-flex justify-content-center align-items-center w-100 mt-3">
                                        <span className="rosa bold">NO HAY TEMARIO</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="col-md-11 col-12 mb-4 mt-5 px-0">
                            <Tabs
                                defaultActiveKey="FORMULARIOS"
                                tabBarPosition="top"
                                onChange={this.callback}
                                renderTabBar={() => <ScrollableInkTabBar />}
                                renderTabContent={() => <TabContent />}
                            >
                                <TabPane tab="Ejercicio / Examen" key="FORMULARIOS">
                                    <div className="mb-4 col-12 mt-4">
                                        <Grid hover striped data={formularios_clase} loading={loader} onPageChange={this.onPageFormulariosChange} page={page_formularios}>
                                            <TableHeaderColumn
                                                dataField="id"
                                                dataAlign="center"
                                                dataSort
                                                isKey
                                                width='20%'
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <div className="d-flex justify-content-center">
                                                            {
                                                                row.respuesa_formulario.enviado ? (
                                                                    <Link to={`/formulario/${row.respuesa_formulario.id}`} className="" >
                                                                        <i className="material-icons ver-action">remove_red_eye</i>
                                                                    </Link>
                                                                ) : (
                                                                    (row.formulario.tipo == EJERCICIO) ? (
                                                                        (moment() <= moment(row.fecha_hora_entrega).add(2, 'd')) && (
                                                                            <Link to={`/ejercicio/${cell}/resolver`} className="" >
                                                                                <i className="material-icons verde editar-action">content_paste</i>
                                                                            </Link>
                                                                        )
                                                                    ) : (
                                                                        <a className="px-1" style={{cursor: "pointer", color: "#c4183c"}} onClick={() => this.resolverExamen(row)}>
                                                                            <i className="material-icons verde editar-action">content_paste</i>
                                                                        </a>
                                                                    )
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }}
                                            >
                                                ACCIONES
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="formulario"
                                                dataSort
                                                dataFormat={(cell) => cell && cell.titulo ? cell.titulo : ""}
                                            >
                                                Nombre
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="formulario"
                                                dataSort
                                                dataFormat={(cell) => cell && cell.tipo_text ? cell.tipo_text : ""}
                                            >
                                                Tipo
                                            </TableHeaderColumn>
                                        </Grid>
                                    </div>
                                </TabPane>
                                <TabPane tab="Archivos" key="ARCHIVOS">
                                    <div className="mb-4 col-12 mt-4">
                                        <Grid hover striped data={archivos_clase} loading={loader} onPageChange={this.onPageArchivosChange} page={page_archivos}>
                                            <TableHeaderColumn width="5%" />
                                            <TableHeaderColumn
                                                dataField="archivo"
                                                dataSort
                                                isKey
                                                dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}
                                            >
                                                NOMBRE DEL ARCHIVO
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="archivo"
                                                dataFormat={ cell => cell && cell.tipo_text ? cell.tipo_text : ""}
                                            >
                                                TIPO ARCHIVO
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataField="archivo"
                                                dataAlign="center"
                                                dataFormat={ cell => {
                                                    if (cell.tipo == ARCHIVO) {
                                                        return (
                                                            <div className="d-flex flex-row align-items-center justify-content-center">
                                                                <a href={cell.archivo} target="_blank" download>
                                                                    <img src={descargar} style={{width: "24px", marginRight: "0.5rem"}} />
                                                                    <label className="azul-claro m-0 p-0">Descargar</label>
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    return (
                                                        <div className="d-flex flex-row align-items-center justify-content-center">
                                                            <a href={cell.enlace} target="_blank">
                                                                <img src={link} style={{width: "24px", marginRight: "0.5rem"}} />
                                                                <label className="azul-claro m-0 p-0">Link</label>
                                                            </a>
                                                        </div>
                                                    )
                                                }}
                                            >
                                                ACCEDER A ESTE RECURSO
                                            </TableHeaderColumn>
                                        </Grid>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}
