import React, { Component, Fragment } from 'react';
import { Modal } from 'react-responsive-modal';
import LoadMask from "../LoadMask/LoadMask";
import { api } from 'api';
import { Link } from 'react-router-dom';


class InscripcionCursoModal extends Component {


    state = {
        curso: null
    }

    componentDidMount() {
        console.log("props ", this.props)
        const { idCurso } = this.props;
        api.get(`curso/${idCurso}`).then( response => {
            this.setState({curso: response});
        }).catch();
    }

    render() {
        const { open, onClose, loader, idCurso, inscribirme } = this.props;
        const { curso } = this.state;
        return (
            <Modal open={open} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} showCloseIcon={false} classNames={{ modal: "d-flex align-items-center" }}>
            <div className="col-12">
                <LoadMask loading={loader} blur>
                    <div className="col-12 mx-0">
                        <h2 className="azul-claro bold">Inscripción a un curso</h2>
                        <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                    </div>
                    <div className="col-12 mx-0 my-5">
                       <span className="text-md">
                           ¿Desea inscribirse al curso&nbsp;<span className="text-md bold">{curso ? curso.titulo : ''}</span>?
                       </span>
                    </div>
                    <div className="d-none d-lg-flex buttons-box mt-5">
                        <Link to="/" style={{ width: '10rem' }} className="btn btn-secondary mr-3" >Cancelar</Link>
                        <button type="button" onClick={() => { console.log("df"); inscribirme(idCurso)}} style={{ width: '10rem' }} className="btn btn-primary">Inscribirme</button>
                    </div>
                    <div className="d-flex d-lg-none flex-column buttons-box mt-5">
                        <Link className="btn btn-secondary d-flex flex-1 w-100" to="/">Cancelar</Link>
                        <button type="button" onClick={() => { console.log("df"); inscribirme(idCurso)}} className="btn btn-primary d-flex flex-1 w-100 my-3">Inscribirme</button>
                    </div>
                </LoadMask>
            </div>
        </Modal>
        );
    }

}

export default InscripcionCursoModal;

