import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import _ from "lodash";


const LOADER = 'LOADER_ASIGNATURANIVEL';
const DATA = 'DATA_ASIGNATURANIVEL';
const ITEM = 'ITEM_ASIGNATURANIVEL';
const PAGE = 'PAGE_ASIGNATURANIVEL';
const ORDERING = 'ORDERING_ASIGNATURANIVEL';
const SEARCH = 'SEARCH_ASIGNATURANIVEL';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data,
});

const setItem = (type, item) => ({
    type,
    item,
});

const setPage = (type, page) => ({
    type,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = (type, search) => ({
    type,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------


const listarNiveles = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().niveles;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    dispatch(setLoader(true));
    api.get('asignatura_niveles', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerNivel = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`asignatura_niveles/${id}`).then((response) => {
        dispatch(setItem(ITEM, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const searchChangeNivel = search => (dispatch) => {
    dispatch(setSearch(SEARCH, search));
    dispatch(listarNiveles());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().niveles.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listarNiveles());
};

export const actions = {
    listarNiveles,
    leerNivel,
    searchChangeNivel,
    onSortChange
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
