import React, { Component, Fragment } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Calendario from '../../../Utils/Calendario/Calendario';
import Modal from 'react-responsive-modal';
import { api } from 'api';
import moment from 'moment';
import _ from "lodash";

import AgendaProfesor from './Agenda';
import EventoDetalleModal from './EventoModal';
import ModalCrearEvento from './ModalCrearEvento';


export default class CalendarioProfesor extends Component {

    state = {
        modalDetalle: false,
        modalCrear: false,
        evento: null,
        error: null,
    }

    componentWillMount() {
        const { me } = this.props;
        this.props.iniciarFechas();
        this.props.obtenerActividades(me.id);
    };

    componentWillUnmount() {
        this.props.iniciarFechas();
    };

    refrescar = () => {
        const { me } = this.props;
        this.props.obtenerActividades(me.id);
    }

    openModalDetalle = (evento) => {
        console.log("evento ", evento);
        this.props.setearFormEventoDetalle(evento);
        this.setState({ modalDetalle: true, evento: evento });
    };

    openModalCrear = (event) => {
        console.log("evento ", event);
        this.props.preCargarEvento(event);
        this.setState({ modalCrear: true });
    };

    closeModal = (e) => {
        this.setState({ modalDetalle: false, evento: null, modalCrear: false, error: null });
        this.props.resetDetalleAula();
    };

    editarEvento = (id) => {
        this.closeModal(null);
        setTimeout(() => {
            this.props.history.push(`/temario/${id}`);
        }, 700);
    }


    renderModalDetalle = () => {
        const { evento } = this.state;
        return (
            <Modal
                open={this.state.modalDetalle}
                onClose={this.closeModal}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <span className="text-sm azul-claro bold">{evento ? evento.titulo : ""}</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12 mx-0">
                            <EventoDetalleModal
                                evento={this.state.evento}
                                cancelar={this.closeModal}
                                onSubmit={this.closeModal}
                                editarEvento={this.editarEvento}
                                generarLinks={this.props.generarLinks}
                            />
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    crearEvento = (values) => {
        const { detalle_aula, me } = this.props;
        const hora_inicio = moment(values.hora_inicio, "HH:mm");
        const hora_fin = moment(values.hora_fin, "HH:mm");
        const dif = (hora_fin - hora_inicio) / 3600000;

        // Comprobamos si se pueden realizar las recuperaciones
        let bandera = true;
        for (const item of values.alumnos) {
            const alumno = _.find(detalle_aula.alumnos_asignados, { id: item.alumno.id });
            if (alumno.horas_pendientes_recuperar < dif)
                bandera = false;
        }
        if (bandera) {
            const data = { ...values, aula: values.aula.id };
            data.alumnos = data.alumnos.map(item => item.alumno.id)
            console.log(data);
            this.props.crear(data, me.id, this.closeModal);
        } else {
            this.setState({ error: "*Error: El tiempo a reponer no coincide con el tiempo pendiente de los alumnos seleccionados" })
        }
    }

    renderModalCrear = () => {
        const { loader } = this.props;
        return (
            <Modal
                open={this.state.modalCrear}
                onClose={this.closeModal}
                closeIcon={false}
                showCloseIcon={false}
                center
            >
                <Fragment>
                    <div className="my-4">
                        <div className="row col-12 mx-0">
                            <span className="text-sm azul-claro bold">Crear clase</span>
                            <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                        </div>
                        <div className="row col-12 mx-0">
                            <LoadMask loading={loader} blur>
                                <ModalCrearEvento
                                    cancelar={this.closeModal}
                                    onSubmit={this.crearEvento}
                                    getCursos={this.getCursos}
                                    obtenerDetalleAula={this.props.obtenerDetalleAula}
                                    detalle_aula={this.props.detalle_aula}
                                    errorModal={this.state.error}
                                />
                            </LoadMask>
                        </div>
                    </div>
                </Fragment>
            </Modal>
        )
    };

    changeFechas = (value) => {
        const { me } = this.props;
        this.props.changeFechas(value, me.id);
    };

    changeTipoEvento = (value) => {
        const { me } = this.props;
        this.props.changeTipoEvento(value, me.id);
    };

    getCursos = (search) => {
        const { me } = this.props;
        let params = { profesor: me.id, clase_grupal: false }
        if (search) {
            params.search = search;
        }
        return api.get("aula", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    render() {
        const { eventos, tipo_evento, loader } = this.props;
        return (
            <div>
                {this.renderModalDetalle()}
                {this.renderModalCrear()}
                <LoadMask loading={loader} blur>
                    <Tabs
                        defaultActiveKey="CALENDARIO"
                        tabBarPosition="top"
                        onChange={this.callback}
                        renderTabBar={() => <ScrollableInkTabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="CALENDARIO" key="CALENDARIO">
                            <div className="d-flex flex-1 flex-column w-100 mt-5 mt-md-4 px-1" style={{ height: "calc(100vh - 7.5rem)" }}>
                                <Calendario
                                    eventos={eventos}
                                    toolbar={true}
                                    onlySemana={false}
                                    height="100%"
                                    tipo_evento={tipo_evento}
                                    changeTipoEvento={this.changeTipoEvento}
                                    changeFechas={this.changeFechas}
                                    onSelectEvent={this.openModalDetalle}
                                    onSelectSlot={this.openModalCrear}
                                    refrescar={this.refrescar}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="AGENDA" key="AGENDA">
                            <div className="d-flex flex-1 flex-column w-100 mt-5 mt-md-4 px-1">
                                <AgendaProfesor {...this.props} openModalDetalle={this.openModalDetalle} />
                            </div>
                        </TabPane>
                    </Tabs>
                </LoadMask>
            </div>
        )
    }
}
