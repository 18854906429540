import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { actions } from '../../../../../redux/modules/cursos/cursosProfe';
import DetalleCurso from './DetalleCurso';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.cursosProfe,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(DetalleCurso);
