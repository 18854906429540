import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderFieldRadio, RenderImageUpload, renderFieldCheck, MultiAsyncSelectField, renderTextArea } from '../../../Utils/renderField/renderField';
import { COMUNICADO_ALUMNOS, COMUNICADO_PROFESORES, USER_ALUMNO, USER_PROFESOR } from '../../../../../utility/constants';
import { api } from '../../../../../utility/api';

const getAlumnos = (search) => {
    let params = { }
    if (search) {
        params.search = search;
    }
    return api.get("user/estudiantes_profesor", params).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
}

let ComunicadoForm = props => {
    const { handleSubmit, setImagen, tipo_destinatario, desactivar_seleccion} = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Selecciona uno o varios alumnos *</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12">
                            <Field
                                component={renderFieldCheck}
                                className="form-control"
                                name="mandar_todos_alumnos"
                                label=" Seleccionar a todos"
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="destinatarios" className="m-0">Alumnos</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="destinatarios"
                                loadOptions={getAlumnos}
                                type="text"
                                component={MultiAsyncSelectField}
                                valueKey="id"
                                labelKey="titulo"
                                isClearable={false}
                                className="form-control"
                                disabled={desactivar_seleccion}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <span className="text-sm azul-claro bold">Mensaje</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Titulo *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="titulo" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="titulo" className="m-0">Imagen *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <RenderImageUpload aspect={2 / 1} multiple={false} setFile={setImagen} />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mensaje" className="m-0">Mensajes *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="mensaje" component={renderTextArea} type="text" className="form-control" rows={5} />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to="/comunicados_profesor">Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    )
};
ComunicadoForm = reduxForm({
    form: 'ComunicadoForm',
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            'titulo': validators.exists()('Campo requerido.'),
            'mensaje': validators.exists()('Campo requerido.')
        })
    },
    initialValues: {
        mandar_todos_alumnos: false,
        mandar_todos_profesores: false,
    },
})(ComunicadoForm);

const selector = formValueSelector('ComunicadoForm');
ComunicadoForm = connect(state => {
    const form = state.form.ComunicadoForm;
    let desactivar_seleccion = false;

    if (form && form.values) {
        const values = form.values;
        if (values.mandar_todos_alumnos ){
            desactivar_seleccion = true;
            values.destinatarios = null;
        }
    }
    return {
        desactivar_seleccion,
    }
})(ComunicadoForm);

export default ComunicadoForm;
