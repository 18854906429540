import React from 'react';
import classNames from 'classnames';
import chroma from 'chroma-js';
import Select from 'react-select';

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        };
    },
    input: styles => ({ ...styles, borderRadius: "15px" }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const FiltroEventos = (props) => {
    const { defaultValue, options, label, value, changeValue } = props;
    return (
        <Select
            isClearable={true}
            className={classNames('react-select-container')}
            backspaceRemovesValue={false}
            isSearchable={true}
            options={options}
            placeholder={label}
            value={value}
            onChange={changeValue}
            styles={colourStyles}
        />
    )
}

export default FiltroEventos;
