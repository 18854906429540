import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { formatActivo } from "Utils/renderField/renderReadField"

export default class FormulariosList extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page, me } = this.props; //Constantes
        return (
            <div className="mb-4 col-12 mt-4">
                <Header to="biblioteca_formulario/crear" textBtn="Nuevo ejercicio / examen" {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                        page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='10%'
                            dataFormat={(cell, row) => {
                                return (
                                    <div className="d-flex justify-content-center">
                                        {
                                            (me) && (row.subido_por) && (row.subido_por.id == me.id) && (
                                                <Link className="px-1" to={`biblioteca_formulario/${cell}/editar`} title="Editar" >
                                                    <i className="material-icons verde editar-action">edit</i>
                                                </Link>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        />
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            TITULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="asignatura"
                            dataSort
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}
                        >
                            NOMBRE DE ASIGNATURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nivel"
                            dataSort
                            dataFormat={(cell) => cell && cell.nombre ? cell.nombre : ""}
                        >
                            NIVEL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_text"
                            dataSort
                        >
                            TIPO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
