import React, {Component, Fragment} from 'react';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import Select from 'react-select';
import classNames from 'classnames';
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import { EJERCICIO } from '../../../../../utility/constants';

import moment from 'moment';
import { Link } from 'react-router-dom';

const ESTADOS_FORM = [
    {value: 1, label: 'Pendientes'},
    {value: 2, label: 'Enviados'},
    {value: 3, label: 'Calificados'},
]


export default class FormsAsignadosGrid extends Component{

    componentWillMount() {

        this.props.listar_forms();
    }

    resolverExamen = (data) => {
        this.props.validarExamen(data);
    }

    render() {
        const { data, loader, page, search, item, listar_forms, searchChange } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <h1 className="azul-claro bold">{item.nombres}</h1>
                <div className="mt-4">
                    <Header
                        {...this.props}
                        searchChange={searchChange}
                        search={search}
                        filtro1={
                            <Select
                                isClearable={true}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={ESTADOS_FORM}
                                placeholder={"Filtrar por estado"}
                                value={this.props.estado_form}
                                onChange={this.props.changeEstadoForm}
                            />
                        }
                    />
                    <Card>
                        <Grid
                            hover striped
                            data={data}
                            loading={loader}
                            onPageChange={listar_forms}
                            page={page}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                width='8%'
                                dataFormat={(cell, row) => {
                                    return (
                                        <div className="d-flex justify-content-center">
                                            {
                                                row.respuesa_formulario.enviado ? (
                                                    <Link to={`/formulario/${row.respuesa_formulario.id}`} className="" >
                                                        <i className="material-icons ver-action">remove_red_eye</i>
                                                    </Link>
                                                ) : (
                                                    (row.formulario.tipo == EJERCICIO) ? (
                                                        (moment() <= moment(row.fecha_hora_entrega).add(2, 'd')) && (
                                                            <Link to={`/ejercicio/${cell}/resolver`} className="" >
                                                                <i className="material-icons verde editar-action">content_paste</i>
                                                            </Link>
                                                        )
                                                    ) : (
                                                        <a className="px-1" style={{cursor: "pointer", color: "#c4183c"}} onClick={() => this.resolverExamen(row)}>
                                                            <i className="material-icons verde editar-action">content_paste</i>
                                                        </a>
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                }}
                            />
                            <TableHeaderColumn
                                dataField="clase"
                                dataFormat={ (cell, row) => {
                                    return (
                                        <div>
                                            {
                                                cell ? (
                                                    <Fragment>
                                                        <span className="bold azul-marino">Clase: </span>
                                                        <span>{cell.titulo}</span>
                                                        <br />
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <span className="bold azul-marino">Curso: </span>
                                                        <span>{row.aula.nombre}</span>
                                                        <br />
                                                    </Fragment>
                                                )
                                            }
                                            {
                                                cell && (
                                                    <Fragment>
                                                        <span className="bold azul-marino">Fecha: </span>
                                                        <span>{moment(cell.fecha_hora_inicio).format('DD/MM/YYYY HH:mm')}</span>
                                                        <br />
                                                    </Fragment>
                                                )
                                            }
                                        </div>
                                    )
                                }}
                            >
                                CLASE / CURSO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="formulario"
                                // width="20%"
                                tdStyle={{ whiteSpace: 'normal' }}
                                dataFormat={ (cell) => `${cell.titulo}`}
                            >
                                EJER/EXÁMEN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="formulario"
                                width='10%'
                                dataFormat={ cell => cell.tipo_text}
                            >
                                TIPO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="formulario"
                                width="25%"
                                tdStyle={{ whiteSpace: 'normal' }}
                                dataFormat={(cell, row) => {
                                    return (
                                        cell.tipo == EJERCICIO ? (
                                            <div>
                                                <Fragment>
                                                    <span className="bold azul-marino">Fecha de asignacion: </span>
                                                    <span>{moment(row.creado).format('DD/MM/YYYY HH:mm')}</span>
                                                    <br />
                                                </Fragment>
                                                <Fragment>
                                                    <span className="bold azul-marino">Fecha de entrega: </span>
                                                    <span>{moment(row.fecha_hora_entrega).format('DD/MM/YYYY HH:mm')}</span>
                                                    <br />
                                                </Fragment>
                                            </div>

                                        ) : (
                                            <div>
                                                <Fragment>
                                                    <span className="bold azul-marino">Fecha de asignacion: </span>
                                                    <span>{moment(row.creado).format('DD/MM/YYYY HH:mm')}</span>
                                                    <br />
                                                </Fragment>
                                                <Fragment>
                                                    <span className="bold azul-marino">Fecha de habilitación: </span>
                                                    <span>{moment(row.fecha_hora_examen).format('DD/MM/YYYY HH:mm')}</span>
                                                    <br />
                                                </Fragment>
                                                <Fragment>
                                                    <span className="bold azul-marino">Duración: </span>
                                                    <span>
                                                        <RenderNumber value={row.tiempo_maximo} decimalScale={2} suffix=" horas" />
                                                    </span>
                                                    <br />
                                                </Fragment>
                                            </div>
                                        )
                                    )
                                }}
                            >
                                DATOS FORMULARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="respuesa_formulario"
                                width='10%'
                                dataAlign="right"
                                dataFormat={ (cell, row) => (
                                    cell.enviado ? (
                                        cell.calificado ? (
                                            <div className="d-flex flex-row align-items-center justify-content-end">
                                                <span className="azul-claro">Calificado</span>
                                            </div>

                                        ) : (
                                            <div className="d-flex flex-row align-items-center justify-content-end">
                                                <span className="verde">Enviado</span>
                                            </div>
                                        )
                                    ) : (
                                        <div className="d-flex flex-row align-items-center align-items-center justify-content-end">
                                            {
                                                (row.formulario.tipo == EJERCICIO) ? (
                                                    (moment() <= moment(row.fecha_hora_entrega).add(2, 'd')) && (
                                                        <i className="material-icons rosa blink-danger">notification_important</i>
                                                    )
                                                ) : (
                                                    (moment() <= moment(row.fecha_hora_examen).add(row.tiempo_maximo, 'h')) && (
                                                        <i className="material-icons rosa blink-danger">notification_important</i>
                                                    )
                                                )
                                            }
                                            <span className="mandarina">Pendiente</span>
                                        </div>
                                    )
                                )}
                            >
                                ESTADO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="respuesa_formulario"
                                width='10%'
                                dataFormat={ (cell, row) => (
                                    cell.punteo != null || cell.punteo != undefined ? (
                                        <div className="d-flex flex-row align-items-center">
                                            <span
                                                className={`${cell.enviado ? cell.calificado ? 'azul-claro' : 'verde' : 'mandarina' }`}
                                            >
                                                <RenderNumber value={cell.punteo} decimalScale={2} />
                                            </span>
                                            &nbsp;/&nbsp;
                                            <span
                                                className={`${cell.enviado ? cell.calificado ? 'azul-claro' : 'verde' : 'mandarina' }`}
                                            >
                                                <RenderNumber value={row.formulario.punteo} decimalScale={2} />
                                            </span>

                                        </div>
                                    ) : (
                                        <div className="d-flex flex-row align-items-center">
                                            <span
                                                className='mandarina'
                                            >
                                                <RenderNumber value={0} decimalScale={2} />
                                            </span>
                                            &nbsp;/&nbsp;
                                            <span
                                                className='mandarina'
                                            >
                                                <RenderNumber value={row.formulario.punteo} decimalScale={2} />
                                            </span>
                                        </div>
                                    )
                                )}
                            >
                                PUNTAJE
                            </TableHeaderColumn>
                        </Grid>
                    </Card>
                </div>
            </div>
        )
    }
}
