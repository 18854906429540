import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { renderEditorField, renderField, renderTextArea } from '../../../Utils/renderField';
import { NORMAL } from '../../../../../utility/constants';
import { renderFieldRadio } from '../../../Utils/renderField/renderField';


const renderPreguntas = ({ fields, respuestas }) => (
    <div className="d-flex flex-column">
        <div className="col-md-12 col-12 px-0">
            {fields.map((item, index) => {
                return (
                    <div key={index} style={{ border: "1px solid #707070", borderRadius: "0.75rem" }} className="mb-3 col-12 py-3 px-lg-5">
                        <div className="col-12 d-flex justify-content-between align-items-center mb-2 px-md-0">
                            <h4 className="azul-claro bold">{respuestas ? respuestas[index].pregunta.pregunta : ''}</h4>
                        </div>
                        <div className="row">
                            <div className="col-12 px-0">
                                <div className="col-md-12 col-12 px-0">
                                    <label htmlFor={`${item}.respuesta_normal`} className="m-0 mb-1">Respuesta</label>
                                </div>
                                {
                                    (respuestas) && (
                                        (respuestas[index].pregunta.tipo == NORMAL) ? (
                                            <div className="col-md-12 col-12 px-0">
                                                <Field name={`${item}.respuesta_normal`} component={renderTextArea} type="text" className="form-control" />
                                            </div>
                                        ) : (
                                            <div className="col-md-8 col-12 px-0">
                                                {
                                                    respuestas[index].pregunta.respuestas.map((opcion, _index) => {
                                                        return (
                                                            <div key={_index} className="col-12 mt-2 px-0">
                                                                <div className="d-flex flex-row">
                                                                    <Field
                                                                        name={`${item}.respuesta_multiple`}
                                                                        label=''
                                                                        value={opcion.id}
                                                                        parse={val => +val}
                                                                        type="radio"
                                                                        component={renderFieldRadio}
                                                                    />
                                                                    <label htmlFor="nuevo" className="m-0">
                                                                        {opcion.respuesta}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
);

const EjercicioForm = (props) => {
    const { handleSubmit, item, guardarExamen } = props;
    return (
        <form name="ExamenForm" className="form-validate mb-lg w-100" onSubmit={handleSubmit}>
            <div className="form-group grid-container mx-0">
                <div className="col-12 px-0">
                    <span className="text-sm azul-claro bold">Notas</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="rd-flex flex-column align-items-end px-0 mt-3 px-0">
                    <div className="flex-1 w-100 borde-gris-notas">
                        <Field name="notas_form" height="20rem" disabled component={renderEditorField} />
                    </div>
                </div>

                <div className="col-12 mt-3 px-0">
                    <span className="text-sm azul-claro bold">Ejercicio</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                </div>
                <div className="d-flex flex-column align-items-end px-0 mt-3">
                    <div className="flex-1 w-100">
                        <FieldArray name="respuestas" component={renderPreguntas} respuestas={item.respuestas} />
                    </div>
                </div>

                <div className="d-none d-lg-flex buttons-box mt-3">
                    <Link className="btn btn-secondary mr-3" to="/formularios">Regresar</Link>
                    <button type="submit" className="btn btn-primary mx-3">Finalizar</button>
                    <button type="button" onClick={guardarExamen} className="btn btn-alter1 ml-3">Guardar</button>
                </div>
                <div className="d-flex d-lg-none flex-column buttons-box mt-3">
                    <Link className="btn btn-secondary d-flex flex-1 w-100" to="/formularios">Regresar</Link>
                    <button type="submit" className="btn btn-primary d-flex flex-1 w-100 my-3">Finalizar</button>
                    <button type="button" onClick={guardarExamen} className="btn btn-alter1 d-flex flex-1 w-100">Guardar</button>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'ExamenForm',
    asyncBlurFields: [],
})(EjercicioForm);


