import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { renderField, AsyncSelectField, renderFieldRadio2 } from '../../../Utils/renderField/renderField';
import { api } from '../../../../../utility/api';


const getAulas = (search) => {
    return api.get("aula/cursos_alumnos", { search, filtro_true:true }).
        then((data) => {
            if (data) return data.results;
            return [];
        }).catch(() => {
            return [];
        });
};

const EnvioMensajeForm = props => {
    const { handleSubmit, mensajes, loader, cancelar, profesor } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container mt-3">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="aula" className="m-0">Curso</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="aula"
                                loadOptions={getAulas}
                                type="text"
                                component={AsyncSelectField}
                                valueKey="id"
                                labelKey="nombre"
                                isClearable={false}
                                className="form-control"
                                onCambio={(value) => {
                                    props.setProfesor(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex col-md-6 p-0 align-items-center justify-content-center">
                        <label htmlFor="aula" className="h3 m-0 mr-2">Profesor:</label>
                        <span className="h3 bold azul-claro">{profesor ? profesor.nombres: ''}</span>
                    </div>
                </div>
            </div>
            <div className="form-group grid-container">
                <Grid
                    hover
                    striped
                    data={{ results: mensajes, count: 1 }}
                    loading={loader}
                    pagination={false}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='20%'
                        dataFormat={cell => (
                            <Field
                                label=''
                                type="radio"
                                value={cell}
                                name="mensaje"
                                parse={val => +val}
                                component={renderFieldRadio2}
                            />
                        )}
                    />
                    <TableHeaderColumn
                        dataField="titulo"
                    >
                        Mensaje
                    </TableHeaderColumn>
                </Grid>
            </div>
            <div className="form-group grid-container">
                <div className="d-none d-lg-flex buttons-box mt-5">
                    <button type="button" onClick={cancelar} className="btn btn-secondary mr-3">Cancelar</button>
                    <button type="submit" className="btn btn-primary ml-3">Guardar</button>
                </div>
                <div className="d-flex d-lg-none flex-column mt-5">
                    <button type="button" onClick={cancelar} className="w-100 btn btn-secondary">Cancelar</button>
                    <button type="submit" className="w-100 btn btn-primary">Guardar</button>
                </div>
            </div>
        </form>
    )
};

export default reduxForm({
    form: 'EnvioMensajeForm',
    validate: data => {
        return validate(data, {
            'aula': validators.exists()('Campo requerido.'),
            'mensaje': validators.exists()('Campo requerido.'),
        })
    }
})(EnvioMensajeForm);
