import React, { useState, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, AsyncSelectField, SelectField, renderFilePicker } from '../../../Utils/renderField';
import { TIPOS_ARCHIVOS, ENLACE, ARCHIVO } from '../../../../../utility/constants';
import CardArchivo from '../../../Utils/Cards/CardArchivo';


let ArchivosForm = (props) => {
    const { handleSubmit, nivel, idNivel } = props;
    return (
        <form name="ArchivosForm" onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12 mb-4 px-md-5">
                    <span className="text-sm azul-claro bold">{`${nivel.asignatura} Nivel ${nivel.nombre}`}</span>
                    <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0 px-5 archivo-group">
                        <div className="col-12 p-0 ">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="tipo" className="m-0">Tipo de carga</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field
                                    name="tipo"
                                    component={SelectField}
                                    options={TIPOS_ARCHIVOS}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        {
                            props.tipo == ENLACE ? (
                                <div className="bg-celeste py-3 mx-md-4 mx-1">
                                    <div className="col-12 p-0  px-5">
                                        <div className="col-12 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="nombre_enlace" className="m-0">Nombre</label>
                                            </div>
                                            <div className="col-md-12  form-group mb-1">
                                                <Field name="nombre_enlace" component={renderField} type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-12 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="enlace" className="m-0">Url</label>
                                            </div>
                                            <div className="col-md-12  form-group mb-1">
                                                <Field name="enlace" component={renderField} type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="buttons-box mt-3">
                                        <button type="button" onClick={props.cargarEnlace} className="btn btn-dark">Agregar enlace</button>
                                    </div>
                                </div>
                            ) : (
                                    <div className="mx-md-4 mx-1">
                                        <div className="col-12 p-0 mt-3 pt-2">
                                            <div className="col-md-12 form-group ">
                                                <Field
                                                    name="archivo"
                                                    multiple={true}
                                                    component={renderFilePicker}
                                                    setFile={props.cargarArchivos}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                        <div className="d-md-flex d-none buttons-box mt-5">
                            <Link className="btn btn-secondary mr-5" to={`/biblioteca_archivos/${idNivel}`}>Cancelar</Link>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>

                    </div>
                    <div className="col-md-6 p-0 " style={{ height: "60vh", overflowY: "auto" }}>
                        <div className="row col-12">
                            {
                                (props.archivos) && (
                                    props.archivos.map((item, index) => (
                                        <div className="col-md-3 col-6 mt-3" key={index}>
                                            <CardArchivo
                                                index={index}
                                                nombre={item.nombre}
                                                tipo={item.tipo}
                                                extension={item.extension}
                                                eliminar={props.eliminarArchivo}
                                            // seleccionado={true}
                                            />
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                    <div className="col-md-4 col-12">
                        <Link className="btn btn-secondary btn-block" to={`/biblioteca_archivos/${idNivel}`}>Cancelar</Link>
                    </div>
                    <div className="col-md-4 col-12 mt-md-0 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Guardar</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector('ArchivosForm');

ArchivosForm = reduxForm({
    form: 'ArchivosForm',
    initialValues: {
        tipo: ARCHIVO,
        archivos: [],
    },
    asyncBlurFields: [],
})(ArchivosForm);

const mstp = state => {
    const tipo = selector(state, 'tipo');
    const archivos = selector(state, 'archivos');

    return {
        tipo,
        archivos,
    }
};

export default connect(mstp, null)(ArchivosForm);
