import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import EnvioForm from './EnvioForm';

export default class EnvioMensajes extends Component {

    state = {
        profesor: null
    }

    componentWillMount(){
        this.props.obtenerMensajes();
    };

    setProfesor = (value) => {
        this.setState({profesor: value.profesor});
    }

    onSubmit = (values) => {
        const { enviarMensaje, cancelar } = this.props;
        const data = {...values, aula: values.aula.id};
        enviarMensaje(data, cancelar);
    };

    render() {
        const { loader } = this.props;
        return (
            <LoadMask loading={loader} blur>
                <EnvioForm
                    {...this.props}
                    onSubmit={this.onSubmit}
                    profesor={this.state.profesor}
                    setProfesor={this.setProfesor}
                />
            </LoadMask>
        )
    }
}
