import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/alumnos/cursos';
import { actions as actions2 } from '../../../../../redux/modules/alumnos/formularios';
import DetalleClaseAlumno from './DetalleClase';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.cursosAlumno,
    };
};

const md2p = {
    ...actions,
    actions2 : actions2
};

export default connect(ms2p, md2p)(DetalleClaseAlumno);
