import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderPasswordField } from '../../Utils/renderField';
import {Link, Redirect} from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';

const LoginForm = (props) => {
    const { handleSubmit, idCurso, onFacebookLogin, onGoogleSuccess, onGoogleFailure } = props;
    return (
        <form name="loginForm" className="form-validate form-login mb-lg" onSubmit={handleSubmit}>

            <div className="form-group has-feedback w-100">
                <label className="d-none d-md-block" htmlFor="email">Email</label>
                <Field name="email" label="Usuario" component={renderField} type="email" className="form-control" placeholder="Email" />
            </div>
            <div className="form-group has-feedback w-100">
                <label className="d-none d-md-block" htmlFor="password">Contraseña</label>
                <Field
                    name="password"
                    label="Contraseña"
                    component={renderPasswordField}
                    type={`${props.ver_password ? "text" : "password"}`}
                    className="form-control"
                    ver_password={props.ver_password}
                    change_ver_password={props.change_ver_password}
                    placeholder="Contraseña"
                />
            </div>
            <div className="col-12 px-4 mb-3">
                <button type="submit" className="btn btn-primary btn-block">Login</button>
            </div>
            <div className="col-12 px-0  px-md-4">
                <div className="row">
                    <div className="col-md-6 col-6 mb-3 pl-lg-3 pr-lg-1 px-0">
                        <FacebookLogin
                            appId="640317750244037" //Real de Sidioms
                            autoLoad={false}
                            fields="first_name,email,picture,last_name"
                            callback={onFacebookLogin}
                            textButton=" Facebook"
                            icon="fa-facebook d-none d-lg-block"
                            language="es_ES"
                            cssClass="btn btn-facebook btn-block"
                        />
                    </div>
                    <div className="col-md-6 col-6 mb-3 pr-lg-3 pl-lg-1 px-0">
                        <GoogleLogin
                            clientId="806083121026-79bh0lekj4nrj9molbg0m615vv37hehl.apps.googleusercontent.com"
                            onSuccess={onGoogleSuccess}
                            onFailure={onGoogleFailure}
                            cookiePolicy={'single_host_origin'}
                            render={renderProps => (
                                <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-google btn-block"> <i className="d-none d-lg-block fa fa-google"></i> Google</button>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 text-center mt-1 mt-md-3">
                <Link to={idCurso ? `/registro/${idCurso}` : "/registro" } className="puntero">Registrate como alumno aquí</Link>
            </div>
            <div className="col-12 text-center mt-1">
                <Link to="/recuperar_pass" className="puntero">Recuperar contraseña</Link>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            email: validators.exists()('Este campo es requerido'),
            password: validators.exists()('Este campo es requerido'),
        });
    },
})(LoginForm);
