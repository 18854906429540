// imprime un elemento por medio de su
// nombre unico
export const print = name => {
    // clonar el elemento y colocarlo dentro del root print
    const el = document.getElementById("PRINT-" + name);
    const clon = el.cloneNode(true);

    const rootEl = document.getElementById("PRINT-ROOT-CONTAINER");
    rootEl.appendChild(clon);

    // imprimir
    window.print();

    // limpiar elemento en otro hilo despues de imprimir
    // evita re-imprimir si se imprime manualmente la pagina.
    // Comentar para debug de @media print

    window.setTimeout(() => {
        rootEl.innerHTML = ''
    }, 50)
}
