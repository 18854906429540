import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/alumnos/cursos';
import RecuperacionesAlumno from './Recuperaciones';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.cursosAlumno,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(RecuperacionesAlumno);
