import React, { Component, cloneElement } from 'react';
import Chart from "react-google-charts";
import Card from "Utils/Cards/Card"
import DatePicker from 'react-datepicker';
import HeaderFiltros from '../../../Utils/Grid/HeaderFiltros';
import moment from 'moment';

import LoadMask from '../../../Utils/LoadMask/LoadMask';


export default class PuntuacionesClases extends Component {

    componentWillMount() {
        this.props.listarGrafica();
    }


    changeFechaInicio = (value) => {
        const { fecha_fin } = this.props;
        if (value <= fecha_fin) {
            const comprobacion = moment(fecha_fin).subtract(6, 'M')
            if (value >= comprobacion) {
                this.props.changeFechaInicio(value);
            }
        }
    }

    changeFechaFin = (value) => {
        const { fecha_inicio } = this.props;
        if (value >= fecha_inicio) {
            const comprobacion = moment(fecha_inicio).add(6, 'M')
            if (value <= comprobacion) {
                this.props.changeFechaFin(value);
            }
        }
    }

    render() {
        const { loader, data } = this.props; //Constantes

        return (
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicio"
                            onChange={this.changeFechaInicio}
                            selected={this.props.fecha_inicio}
                        />
                    }
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha fin"
                            onChange={this.changeFechaFin}
                            selected={this.props.fecha_fin}
                        />
                    }
                />
                <Card>
                    <div className="d-flex flex-row flex-1 mt-3 mb-5">
                        <LoadMask loading={loader} blur>
                            <Chart
                                width='100%'
                                height={'50vh'}
                                chartType="LineChart"
                                loader={<div>Cargando grafica</div>}
                                data={data}
                                options={{
                                    hAxis: {
                                        title: 'Fechas',
                                        baselineColor: "#1C375E",
                                        titleTextStyle: {
                                            color: '#1C375E',
                                            bold: true,
                                            italic: false
                                        },
                                        textStyle: {
                                            color: "#1C375E",
                                            bold: false,
                                            italic: false
                                        },
                                    },
                                    vAxis: {
                                        title: 'Puntuaciones',
                                        // position: 'top',
                                        baselineColor: "#1C375E",
                                        titleTextStyle: {
                                            color: '#1C375E',
                                            bold: true,
                                            italic: false
                                        },
                                        textStyle: {
                                            color: "#1C375E",
                                            bold: false,
                                            italic: false
                                        },
                                    },
                                    pointSize: 7,
                                    curveType: 'function',
                                    chartArea: { width: '90%', height: '80%' },
                                    colors: ['#0336B5'],
                                    legend: { position: 'none' },
                                }}
                                rootProps={{ 'data-testid': '1' }}
                            />
                        </LoadMask>
                    </div>
                </Card>
            </div>
        )
    }


}

