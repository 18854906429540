import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {push} from "react-router-redux";
import moment from 'moment';
import { DIAS_SEMANA } from '../../../utility/constants';
import _ from "lodash";
import { setMe } from '../cuenta/login';
import { USER_ALUMNO } from '../../../utility/constants';

const LOADER = 'LOADER_FORMS_ALUMNOS';
const DATA = 'DATA_FORMS_ALUMNOS';
const ITEM = 'ITEM_FORMS_ALUMNOS';
const ITEM2 = 'ITEM2_FORMS_ALUMNOS';
const PAGE = 'PAGE_FORMS_ALUMNOS';
const ORDERING = 'ORDERING_FORMS_ALUMNOS';
const SEARCH = 'SEARCH_FORMS_ALUMNOS';
const ESTADO_FORM = 'ESTADO_FORM_FORMS_ALUMNOS';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = (type, data) => ({
    type,
    data
});

const setItem = (type, item) => ({
    type,
    item,
});

const setPage = page => ({
    type,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type,
    search,
});


// ------------------------------------
// Actions
// ------------------------------------

const listar_forms = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().formsAlumno;
    const params = { page };
    params.search = resource.search;
    if (resource.estado_form)
        params.estado = resource.estado_form.value;
    dispatch(setLoader(true));
    api.get('formularios_asignados/formularios_alumnos', params).then((response) => {
        dispatch(setData(DATA, response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`formularios_asignados/${id}`).then((response) => {
        dispatch(setItem(ITEM2, response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerRespuesta = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`form_respuesta/${id}`).then((response) => {
        dispatch(setItem(ITEM, response));

        const blocksFromHtml = htmlToDraft(response.formulario.formulario.notas ? response.formulario.formulario.notas : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        const blocksFromHtml2 = htmlToDraft(response.notas ? response.notas : "");
        const contentState2 = ContentState.createFromBlockArray(blocksFromHtml2.contentBlocks, blocksFromHtml2.entityMap);

        const data = {
            notas_form: EditorState.createWithContent(contentState),
            respuestas: response.respuestas,
            notas: EditorState.createWithContent(contentState2),
        }
        dispatch(initializeForm('FormularioForm', data));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crearObtenerForm = id => async (dispatch) => {
    await api.post('form_respuesta', {formulario: id}).then((response) => {
        dispatch(setItem(ITEM, response));

        const blocksFromHtml = htmlToDraft(response.formulario.formulario.notas ? response.formulario.formulario.notas : "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        const data = {
            notas_form: EditorState.createWithContent(contentState),
            respuestas: response.respuestas,
        }

        dispatch(initializeForm('ExamenForm', data));
        console.log("api")
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const enviarForm = (id, data, ejercicio=false) => (dispatch) => {
    api.put(`form_respuesta/${id}`, {...data, enviado: true}).then((response) => {
        if (ejercicio) {
            NotificationManager.success('Ejercicio enviado', 'Éxito', 3000);
        } else {
            NotificationManager.success('Exámen enviado', 'Éxito', 3000);
        }
        dispatch(push('/formularios'));
    }).catch( error => {
        let mensaje = "Error al guardar";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const guardarEjercicio = (id, data) => (dispatch) => {
    api.put(`form_respuesta/${id}`, data).then((response) => {
        NotificationManager.success('Ejercicio guardado', 'Éxito', 3000);
        dispatch(push('/formularios'));
    }).catch( error => {
        let mensaje = "Error al guardar";
        if (error.detail) {
            mensaje = error.detail;
        }
        NotificationManager.error(mensaje, 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
}

const validarExamen = (data) => (dispatch) => {
    const respuesa_formulario = data.respuesa_formulario
    if (respuesa_formulario.id) {
        // Existe por lo tanto mandarlo
        console.log("imprimimos esto")
        dispatch(push(`/examen/${data.id}/resolver`));
    } else {
        // No existe, mandarlo a la previsualizacion
        dispatch(push(`/examen/${data.id}`));
    }
}

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().formsAlumno.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar_forms());
};

const changeEstadoForm = value => (dispatch) => {
    dispatch(setData(ESTADO_FORM, value));
    dispatch(listar_forms());
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(SEARCH, search));
    dispatch(listar_forms());
};

export const actions = {
    listar_forms,
    leer,
    leerRespuesta,
    enviarForm,
    guardarEjercicio,
    validarExamen,
    crearObtenerForm,
    onSortChange,
    changeEstadoForm,
    searchChange,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM2]: (state, { item }) => {
        return {
            ...state,
            item2: item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [ESTADO_FORM]: (state, { data }) => {
        return {
            ...state,
            estado_form: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    item2: {},
    page: 1,
    ordering: '',
    search: '',
    estado_form: null
};

export default handleActions(reducers, initialState);

