import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Swal from 'sweetalert2';
import ComunicadoForm from './ComunicadoForm';
import { COMUNICADO_ALUMNOS, COMUNICADO_PROFESORES} from '../../../../../utility/constants';

export default class ComunicadoCrear extends Component {
    state = {
        imagen: null,
    };

    setImagen = (imagen) => {
        this.setState({ imagen });
    };

    crearComunicado = (data) => {
        Swal.fire({
            title: '¿Enviar comunicado?',
            text: '¡Este comunicado no se enviara hasta que reciba la aprobación del administrador!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: '¡Sí, enviar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.crear(data, [{ "file": this.state.imagen, "name": "imagen" }])
            }
        });
    };

    componentWillUnmount(){
        this.props.cleanForm();
    }

    render() {
        const { crear, update } = this.props;
        const { updateData, loader_pendientes } = this.props;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <LoadMask loading={loader_pendientes} blur>
                        <ComunicadoForm
                            onSubmit={this.crearComunicado}
                            setImagen={this.setImagen}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
