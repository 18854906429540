import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {Link} from "react-router-dom";
import "./styles.css";

const logo = require("assets/img/logo.png");
const mundo = require("assets/img/Isotipo.png");


class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {dropdownOpen: false};
    }

    toggle = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    };
    render() {
        const { navToggle, logOut, user, title, subtitle, icon } = this.props;

        return (
            <div className="main-navbar-fixed bg-celeste">
                <div className="p-0">
                    <nav className="align-items-stretch flex-md-nowrap p-0 navbar">
                        <div className="w-100 mr-md-4 mr-sm-4 ml-lg-4">
                            <div className="d-flex align-items-center justify-content-between w-100 mt-2 mt-sm-3">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="ml-1"/>
                                    <nav className="nav">
                                        <a  className="nav-link nav-link-icon toggle-sidebar d-sm-inline d-md-none d-lg-none text-center"
                                            onClick={ navToggle } >
                                            <i className="material-icons"></i>
                                        </a>
                                    </nav>
                                    <div className="d-flex justify-content-start align-items-center">
                                        {icon && (
                                            <img
                                                className="ml-sm-2 ml-3 align-center img_azul_display"
                                                src={icon}
                                                alt="Logo"
                                                width="15"
                                            />
                                        )}
                                        <h1 className="ml-sm-2 ml-2 azul-claro">{title} <span className="text-lg regular">{subtitle}</span></h1>
                                    </div>
                                </div>
                                <img src={mundo} alt="" width="30" className="mr-2 d-sm-inline d-md-none d-lg-none"/>
                                <img src={logo} alt="" width="120" className="mr-1 d-xsm-none d-sm-none d-md-inline d-lg-inline"/>
                            </div>
                            <div className="d-flex w-100 bg-azul-claro" style={{height: '3px'}}/>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Navbar;
