import React, { Fragment, Component } from 'react';
import { Async } from 'react-select';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderDatePicker, renderTimeField, renderNumber, AsyncSelectField } from '../../../Utils/renderField';
import { CardSmallEjercicio } from '../../../Utils/Cards/CardEjercicio';
import { EJERCICIO, EXAMEN } from '../../../../../utility/constants';
import SearchInput from '../../../Utils/Grid/SearchInput';

import moment from 'moment';

import { api } from "api";

const getNiveles = (search) => {
    return api.get("asignatura_niveles", { search }).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};


class FormularioAgregarForm extends Component {

    getAlumnos = (search) => {
        const { idAula } = this.props;
        let params = { aula: idAula }
        if (search) {
            params.search = search;
        }
        return api.get("aula/alumnos_asignados", params).
            then((data) => {
                if (data) return data.results;
                return [];
            }).catch(() => {
                return [];
            });
    }

    render() {
        const { handleSubmit, idAula, forms, seleccionar, seleccionado, search, searchChange } = this.props;
        return (
            <form name="FormularioAgregarForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
                <div className="form-group grid-container">
                    <div className="row">
                        <div className="col-md-5 archivo-group">
                            <div className="col-12 mb-4 px-0 mt-2">
                                <span className="text-sm azul-claro bold">Datos Formulario</span>
                                <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                            </div>
                            <div className="col-12 p-0 ">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="tipo" className="m-0">Alumno</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="alumno"
                                        loadOptions={this.getAlumnos}
                                        type="text"
                                        component={AsyncSelectField}
                                        // top={{ top: "5px", position: "inherit" }}
                                        valueKey="id"
                                        labelKey="titulo"
                                        isClearable={true}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            {
                                (seleccionado) && (
                                    <div className="col-12 p-0 ">
                                        {
                                            seleccionado.tipo == EJERCICIO ? (
                                                <div className="col-md-12 col-sm-12">
                                                    <label htmlFor="tipo" className="m-0">Fecha de entrega</label>
                                                </div>
                                            ) : (
                                                    <div className="col-md-12 col-sm-12">
                                                        <label htmlFor="tipo" className="m-0">Fecha de habilitación</label>
                                                    </div>
                                                )
                                        }
                                        <div className="col-md-12 d-flex flex-row  form-group">
                                            <div className="d-flex flex-1">
                                                <Field
                                                    name="fecha"
                                                    component={renderDatePicker}
                                                />
                                            </div>
                                            <div className="d-flex flex-1">
                                                <Field name="hora" component={renderTimeField} />
                                            </div>
                                        </div>
                                    </div>

                                )
                            }
                            {
                                (seleccionado) && (seleccionado.tipo == EXAMEN) && (
                                    <div className="col-12 p-0 ">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="tipo" className="m-0">Duración del exámen</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                name="tiempo_maximo"
                                                component={renderNumber}
                                                suffix=' hora(s)'
                                                type="text"
                                                decimalScale={2}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="d-md-flex d-none buttons-box mt-5 mx-4">
                                <Link to={`/cursos/${idAula}/`} className="btn btn-secondary mr-md-5 mr-3">Cancelar</Link>
                                <button type="submit" className="btn btn-primary">Asignar</button>
                            </div>
                        </div>
                        <div className="col-md-7 p-0 px-md-3">
                            <div className="col-12 mb-4 px-0 mt-2">
                                <span className="text-sm azul-claro bold">Selecciona un exámen o ejercicio</span>
                                <div className="d-flex w-100 bg-azul-claro" style={{ height: '3px' }} />
                            </div>
                            <div className="row px-2 mb-2">
                                <div className="col-lg-6 col-12">
                                    <Async
                                        isClearable={true}
                                        backspaceRemovesValue={false}
                                        value={this.props.nivel}
                                        isSearchable={true}
                                        loadOptions={getNiveles}
                                        getOptionValue={(option) => (option["id"])}
                                        getOptionLabel={(option) => (option["titulo"])}
                                        type="text"
                                        onChange={(e) => this.props.changeNivel(e)}
                                        multi={false}
                                        autoload={false}
                                        cache={false}
                                        className={classNames('react-select-container w-100')}
                                        defaultOptions={true}
                                        placeholder="Filtrar por nivel.."
                                    />
                                </div>
                                <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                                    <SearchInput search={search} searchChange={searchChange} placeholder="Buscar ejercicio o exámen" />
                                </div>
                            </div>
                            <div className="col-md-12 p-0 mt-3 mt-md-0 " style={{ height: "50vh", overflowY: "auto" }}>
                                <div className="row col-12">
                                    {
                                        (forms) && (
                                            forms.map((item, index) => (
                                                <div className="col-md-4 col-6 mt-3" key={index}>
                                                    <CardSmallEjercicio
                                                        index={index}
                                                        item={item}
                                                        nombre={item.titulo}
                                                        tipo={item.tipo}
                                                        seleccionar={seleccionar}
                                                        seleccionado={seleccionado ? seleccionado.id == item.id : false}
                                                    />
                                                </div>
                                            ))
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-md-none d-flex flex-column buttons-box mt-5">
                        <Link to={`/cursos/${idAula}/`} className="w-100 btn btn-secondary mb-3">Cancelar</Link>
                        <button type="submit" className="w-100 btn btn-primary">Asignar</button>
                    </div>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'FormularioAgregarForm',
    initialValues: {
        fecha: moment(),
    },
    asyncBlurFields: [],
    validate: data => {
        return validate(data, {
            alumno: validators.exists()('Campo requerido.'),
            fecha: validators.exists()('Campo requerido.'),
            hora: validators.exists()('Campo requerido.'),
            tiempo_maximo: validators.exists()('Campo requerido.'),
        })
    }
})(FormularioAgregarForm);
