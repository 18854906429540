import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, } from 'redux-form';
import { renderField, renderNumber, SelectField, renderMonthPicker } from '../../../Utils/renderField/renderField';
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import { tipo_tarjeta, DINERO } from "../../../../../utility/constants";

const bankia = require("../../../../../../assets/img/iconos/bankia.png");
const masterCard = require("../../../../../../assets/img/iconos/master-card.png");
const visa = require("../../../../../../assets/img/iconos/visa.png");

const PasarelaForm = props => {
    const { handleSubmit, me, cupon } = props;

    const getTitulo = () => {
        if (me) {
            if (me.pago_pendiente) {
                if (me.pago_pendiente.curso) {
                    return me.pago_pendiente.curso.titulo;
                } else if (me.pago_pendiente.aula) {
                    return me.pago_pendiente.aula.nombre;
                }
            }
        }
        return '';
    };

    const getPrecio = () => {
        if (me) {
            if (me.pago_pendiente) {
                if (me.pago_pendiente.curso) {
                    return me.pago_pendiente.curso.precio_sin_iva;
                } else if (me.pago_pendiente.aula) {
                    return me.pago_pendiente.aula.precio_sin_iva;
                }
            }
        }
        return 0;
    }

    const getIva = () => {
        let iva = false;
        if (me) {
            if (me.pago_pendiente) {
                if (me.pago_pendiente.curso) {
                    iva = me.pago_pendiente.curso.iva;
                } else if (me.pago_pendiente.aula) {
                    iva = me.pago_pendiente.aula.porcentaje_iva ? true : false
                }
            }
        }
        return iva;
    }

    const getPrecioTotal = (descuento) => {
        let total = 0
        if (me) {
            if (me.pago_pendiente) {
                if (me.pago_pendiente.curso) {
                    total = me.pago_pendiente.curso.precio_sin_iva - descuento;
                    if (me.pago_pendiente.curso.iva) {
                        total = total + (total * me.pago_pendiente.curso.porcentaje_iva)
                    }
                } else if (me.pago_pendiente.aula) {
                    total = me.pago_pendiente.aula.precio_sin_iva - descuento;
                    if (me.pago_pendiente.aula.porcentaje_iva) {
                        total = total + (total * me.pago_pendiente.aula.porcentaje_iva)
                    }
                }
            }
        }
        return total;
    }

    const getDescuento = () => {
        let descuento = 0
        const valor_curso = getPrecio();
        if (cupon) {
            if (cupon.tipo_descuento == DINERO) {
                console.log("Dinero")
                descuento = cupon.descuento;
            } else {
                console.log("Porcentaje")
                descuento = valor_curso * (cupon.descuento / 100)
                descuento = Math.round(descuento * 100) / 100;
            }
        }
        return descuento;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-12">
                        <span className="text-sm azul-claro bold">Detalles de la compra</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="col-md-4 col-6 p-0 mt-3">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombres" className="m-0 bold">Nombre</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <label>{me ? me.nombres : ''}</label>
                        </div>
                    </div>
                    <div className="col-md-4 col-6 p-0 mt-3">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombres" className="m-0">Apellido</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <label>{me ? me.apellidos : ''}</label>
                        </div>
                    </div>
                    <div className="col-md-4 col-6 p-0 mt-lg-3">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombres" className="m-0">Facturación</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <label>{me ? me.nif : ''}</label>
                        </div>
                    </div>
                    <div className="col-12 px-0">
                        <div className="d-flex flex-row w-100 flex-1 mt-3" style={{overflowX: "auto"}}>
                            <table className="sd-table w-100">
                                <thead>
                                    <tr>
                                        <th style={{ width: "50%" }} >Curso</th>
                                        <th style={{ width: "50%" }} >Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{getTitulo()}</td>
                                        <td>{<RenderCurrency value={getPrecio()} />}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="d-flex flex-row flex-1 align-items-center">
                                                <Field name="cupon" disabled={cupon ? true : false} placeholder="Ingresa tu cupón" component={renderField} type="text" className="form-control" />
                                                {
                                                    (cupon) && (
                                                        <span className="material-icons verde ml-3">
                                                            check_circle
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <button
                                                type="button"
                                                onClick={() => props.validarCupon(me.pago_pendiente.curso ? me.pago_pendiente.curso.id : null)}
                                                className="btn btn-primary"
                                            >Aplicar cupón</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Descuento
                                        </td>
                                        <td>
                                            <RenderCurrency value={getDescuento()} />
                                        </td>
                                    </tr>
                                    <tr className="bg-azul-marino">
                                        <td style={{ fontSize: "15px" }} className="bg-azul-marino blanco bold">
                                            Total
                                        </td>
                                        <td style={{ fontSize: "15px" }} className="bg-azul-marino blanco bold">
                                            <RenderCurrency value={getPrecioTotal(getDescuento())} className="total-tabla" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {(getIva()) && (
                        <div className="col-12 text-right">
                            <span className="azul-marino text-xsm">* El precio incluye iva</span>
                        </div>
                    )}
                    <div className="col-12 mt-4">
                        <span className="text-sm azul-claro bold">Métodos seguros de pago</span>
                        <div className="d-flex w-100 bg-gris-oscuro" style={{ height: '3px' }} />
                    </div>
                    <div className="row col-12 px-0 my-4">
                        <div className="col-4 px-0 d-flex align-items-center justify-content-center">
                            <img src={masterCard} alt="Master Card" />
                        </div>
                        <div className="col-4 px-0 d-flex align-items-center justify-content-center">
                            <img src={visa} alt="Visa" />
                        </div>
                        <div className="col-4 px-0 d-flex align-items-center justify-content-center">
                            <img className="bankia-logo" src={bankia} alt="Bankia" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-3">
                    <div className="col-md-8 col-12 row">
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <Link className="btn btn-secondary btn-block" to="/">Cancelar</Link>
                        </div>
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <button type="submit" className="btn btn-primary btn-block">Realizar pago seguro</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};


export default reduxForm({
    form: 'PasarelaForm',
    // initialValues: {
    //     cupon: ""
    // },
    validate: data => {
        return validate(data, {
            'numero_tarjeta': validators.exists()('Campo requerido.'),
            'metodo_pago': validators.exists()('Campo requerido.'),
            'mes_anio': validators.exists()('Campo requerido.'),
            'codigo_seguridad': validators.exists()('Campo requerido.'),
        })
    }
})(PasarelaForm);
