import React, { Component, Fragment } from 'react';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Select, { Async } from 'react-select';
import classNames from 'classnames';
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import { api } from 'api';

import _ from 'lodash';
import moment from 'moment';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';

export default class CalificadosGrid extends Component {

    componentWillMount() {
        this.props.listarCalificados();
    }

    getAulas = search => {
        const { me } = this.props;
        let params = { search, profesor: me.id }
        return api.get("aula", params ).then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
        });
    }

    render() {
        const { listarCalificados, searchChange2 } = this.props; //Funciones
        const { data2, loader, page2, search2, aula } = this.props; //Constantes
        return (
            <div className="mt-4 px-1">
                <Header
                    {...this.props}
                    search={search2}
                    searchChange={searchChange2}
                    filtro1={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={aula}
                            isSearchable={true}
                            loadOptions={this.getAulas}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeAula(e, 2)}
                            multi={true}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container ')}
                            defaultOptions={true}
                            placeholder="Filtrar por curso ..."
                        />
                    }
                />
                <Card>
                    <Grid
                        hover striped
                        data={data2}
                        loading={loader}
                        onPageChange={listarCalificados}
                        page={page2}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='10%'
                            dataFormat={standardActions({ ver: '/forms_profesor' })}
                        />
                        <TableHeaderColumn
                            dataField="nombre_form"
                        >
                            EJERCICIO / EXÁMEN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_form"
                        >
                            TIPO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="aula"
                        >
                            CURSO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="alumno"
                            dataFormat={cell => `${cell.id} / ${cell.nombres}`}
                        >
                            ALUMNO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="punteo"
                            // dataAlign="right"
                            dataFormat={(cell, row) => (
                                <div className="d-flex flex-row align-items-center">
                                    <span className='azul-claro'>
                                        <RenderNumber value={cell} decimalScale={2} />
                                    </span>
                                    &nbsp;/&nbsp;
                                    <span className='azul-claro'>
                                        <RenderNumber value={row.puntaje_form} decimalScale={2} />
                                    </span>
                                </div>
                            )}
                            // thStyle={{ marginRight: "2rem" }}
                            // tdStyle={{ marginRight: "2rem" }}
                        >
                            PUNTEO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
