import React, {Component, Fragment} from 'react';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default class Comunicados extends Component{
    componentWillMount() {
        const { listarRecibidosAlumno, page_recibidos } = this.props;
        listarRecibidosAlumno(page_recibidos);
    }

    onPageChangeRecibidosAlumno = (page_recibidos) => {
        this.props.listarRecibidosAlumno(page_recibidos);
    };

    render() {
        // Funciones
        const { listarRecibidosAlumno:
            onPageChangeRecibidosAlumno,
            onSortChangeRecibidosAlumno,
            eliminar,
            searchChangeRecibidosAlumno,
            filtroMesInicioRecibidosAlumno,
            filtroMesFinRecibidosAlumno,
        } = this.props;

        // Constantes
        const {
            data_recibidos_alumnos,
            loader,
            page_recibidos_alumnos,
            search_recibidos_alumnos,
            mes_inicio_filtro_recibidos_alumnos,
            mes_fin_filtro_recibidos_alumnos,
        } = this.props;

        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    {...this.props}
                    searchChange={searchChangeRecibidosAlumno}
                    search={search_recibidos_alumnos}
                    filtro2={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha inicio"
                            onChange={filtroMesInicioRecibidosAlumno}
                            selected={mes_inicio_filtro_recibidos_alumnos}
                        />
                    }
                    filtro1={
                        <DatePicker
                            showPopperArrow={false}
                            isClearable
                            dateFormat="DD/MM/YYYY"
                            placeholderText="Fecha fin"
                            onChange={filtroMesFinRecibidosAlumno}
                            selected={mes_fin_filtro_recibidos_alumnos}
                        />
                    }
                />
                <Card>
                    <Grid hover striped
                        data={data_recibidos_alumnos}
                        loading={loader}
                        onPageChange={this.onPageChangeRecibidosAlumno}
                        onSortChange={onSortChangeRecibidosAlumno}
                        page={page_recibidos_alumnos}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ ver: "comunicado_alumno"})}
                        >
                            ACCIONES
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creado"
                            dataSort
                            dataFormat={(cell) => cell ? moment(cell).format('DD/MM/YYYY') : ""}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="titulo"
                            dataSort
                        >
                            TITULO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="creador"
                            dataFormat={ (cell, row) => {
                                return (
                                    <div>
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.nombres ? `${cell.nombres}` : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                        {
                                            cell && (
                                                <Fragment>
                                                    <span>{cell.tipo_usuario_text ? cell.tipo_usuario_text : ""}</span>
                                                    <br />
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                )
                            }}
                        >
                            USUARIO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
