import React, {Component} from 'react'
import Card from "../../../Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { USER_ALUMNO } from '../../../../../utility/constants';
import moment from 'moment';

const avatar = require("../../../../../../assets/img/avatar.png");

export default class Perfil extends Component{

    componentWillMount() {
        const { leer } = this.props;
        leer();
    }

    render() {
        const { loader, item } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <Header
                    to="/perfil/editar"
                    textBtn="Editar perfil"
                />
                <Card noPadding={true}>
                    <LoadMask loading={loader} blur>
                        <div className="view-perfil-head flex-column flex-lg-row">
                            <div className="d-flex view-perfil-head-img mr-lg-5">
                                <img className="" src={item.avatar ? item.avatar :  avatar} alt="Perfil" />
                            </div>
                            <div className="d-none d-lg-block view-perfil-barra"></div>
                            <div className="d-flex flex-column ml-lg-5 mt-2 mt-lg-0 view-perfil-head-title">
                                <h2 className="blanco bold">{`${item.nombres} ${item.apellidos}`}</h2>
                                <h3 className="blanco regular">{item.email}</h3>
                            </div>
                        </div>
                        <div className="d-flex w-100 justify-content-center my-5">
                            <div className="row col-lg-8 col-12">
                                <div className="col-6">
                                    <span className="h4 bold">Nombres</span>
                                    <span className="m-0">{item.nombres}</span>
                                </div>
                                <div className="col-6">
                                    <span className="h4 bold">Apellidos</span>
                                    <span className="m-0">{item.apellidos}</span>
                                </div>
                                <div className="col-6 mt-3">
                                    <span className="h4 bold">Correo</span>
                                    <span className="m-0">{item.email}</span>
                                </div>
                                <div className="col-6 mt-3">
                                    <span className="h4 bold">Telefono</span>
                                    <span className="m-0">{item.telefono}</span>
                                </div>
                                <div className="col-6 mt-3">
                                    <span className="h4 bold">Dirección</span>
                                    <span className="m-0">{item.direccion}</span>
                                </div>
                                {
                                    (item.tipo_usuario == USER_ALUMNO) && (
                                        <div className="col-6 mt-3">
                                            <span className="h4 bold">Población</span>
                                            <span className="m-0">{item.poblacion}</span>
                                        </div>

                                    )
                                }
                                {
                                    (item.tipo_usuario == USER_ALUMNO) && (
                                        <div className="col-6 mt-3">
                                            <span className="h4 bold">Codigo postal</span>
                                            <span className="m-0">{item.codigo_postal}</span>
                                        </div>
                                    )
                                }
                                {
                                    (item.tipo_usuario == USER_ALUMNO) && (
                                        <div className="col-6 mt-3">
                                            <span className="h4 bold">Horario preferido</span>
                                            <span className="m-0">{item.horario_contacto}</span>
                                        </div>
                                    )
                                }
                                {
                                    (item.tipo_usuario == USER_ALUMNO) ? (
                                        <div className="col-6 mt-3">
                                            <span className="h4 bold">Método de pago</span>
                                            <span className="m-0">{item.metodo_pago_text}</span>
                                        </div>
                                    ) : (
                                        <div className="col-6 mt-3">
                                            <span className="h4 bold">Cuenta de banco</span>
                                            <span className="m-0">{item.no_cuenta_banco}</span>
                                        </div>
                                    )
                                }
                                <div className="col-6 mt-3">
                                    <span className="h4 bold">Facturación (CIF, DNI, NIF)</span>
                                    <span className="m-0">{item.nif}</span>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}
