import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import {setMe, getMe} from "./login";

const LOADER = 'PASARELA_LOADER';
const DATA = 'PASARELA_DATA';
const ITEM = 'PASARELA_ITEM';
const PAGE = 'PASARELA_PAGE';
const CUPON = 'PASARELA_CUPON';
const URL = 'PASARELA_URL';

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setData = (type, data) => ({
    type,
    data,
});

export const setItem = item => ({
    type: ITEM,
    item,
});

export const setPage = page => ({
    type: PAGE,
    page,
});

// ------------------------------------
// Actions
// ------------------------------------

const generarPago = (values) => (dispatch, getStore) => {
    const resource = getStore().pasarela;
    const me = getStore().login.me;
    const params = {};
    if (resource.cupon){
        const cupon = _.find(resource.cupon.cupones, {cupon: values.cupon.toUpperCase()});
        params.cupon = cupon ? cupon.id : null
    }
    dispatch(setLoader(true));
    if (me && me.tiene_identificador) {
        api.get('pasarela/generar_y_validar_pago', params).then((response) => {
            // NotificationManager.success('Pago realizado correctamente', 'Éxito', 3000);
            localStorage.removeItem('cupon');
            localStorage.removeItem('id_curso');
            localStorage.removeItem('tipo_curso');
            dispatch(getMe())
            dispatch(push('/pago_exitoso'));
        }).catch((error) => {
            console.log("error ", error)
            let mensaje = "Error en el pago";
            if (error && error.body && error.body.detail) {
                mensaje = error.body.detail;
            }
            NotificationManager.error(mensaje, 'ERROR');
            dispatch(push('/pago_fallido'));
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    else{
        api.get('pasarela/generar_pago', params).then(async (response) => {
            // NotificationManager.success('Pago realizado correctamente', 'Éxito', 3000);
            localStorage.removeItem('cupon');
            localStorage.removeItem('id_curso');
            localStorage.removeItem('tipo_curso');

            await dispatch(setData(URL, response));

            // hacemos el submit
            document.forms['PagoBankiaForm'].submit();

        }).catch((error) => {
            let mensaje = "Error en el pago";
            if (error && error.body && error.body.detail) {
                mensaje = error.body.detail;
            }
            NotificationManager.error(mensaje, 'ERROR');
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};

const inscribirme = (id) => (dispatch) => {
    console.log("dfdfdf");
    dispatch(setLoader(true));
    api.put('user/pre_inscripcion', { id }).then((response) => {
        dispatch(setMe(response));
    }).catch((error) => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const validarCupon = (idCurso) => (dispatch, getStore) => {
    const form = getStore().form.PasarelaForm;
    let cupon = "";
    if (form && form.values) {
        cupon = form.values.cupon;
    } else{
        cupon = localStorage.getItem('cupon');
    }
    const params = { codigo: cupon };
    if (idCurso)
        params.curso = idCurso
    dispatch(setLoader(true));
    api.get('/cupon/comprobar_cupon/', params).then((response) => {
        if (response.estado) {
            NotificationManager.success('Cupón valido', 'Éxito', 3000);
        } else {
            NotificationManager.error('Cupón no valido', 'ERROR');
        }
        dispatch(setData(CUPON, response.cupon));
    }).catch((error) => {
        NotificationManager.error('Error al validar el cupón', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const resetUrlBankia = () => (dispatch) => {
    dispatch(setData(URL, ""));
}

export const actions = {
    generarPago,
    inscribirme,
    validarCupon,
    resetUrlBankia,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [CUPON]: (state, { data }) => {
        return {
            ...state,
            cupon: data,
        };
    },
    [URL]: (state, { data }) => {
        return {
            ...state,
            url: data,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    cupon: null,
    url: null,
};

export default handleActions(reducers, initialState);
