import React, {Component, Fragment} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card";
import LoadMask from 'Utils/LoadMask/LoadMask';
import moment from 'moment';
import { CUPON_UNICO, CUPON_MULTIPLE, DINERO, CLASE_INDIVIDUAL_CUPONES, CLASE_GRUPAL_CUPONES } from '../../../../../utility/constants';
import { RenderNumber, RenderCurrency } from '../../../Utils/renderField/renderReadField';
import CardForm from '../../../Utils/Cards/CardForm';
import Swal from 'sweetalert2';


export default class VerComunicado extends Component{
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
        }
    }

    render() {
        const { item, loader, me } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <CardForm col={10}>
                    <LoadMask loading={loader} blur>
                        <div className="col-12 mb-3 px-0">
                            <span className="text-sm azul-claro bold">Usuario que envio el comunicado</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                        </div>
                        <div className="col-12 px-0 mb-3">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="d-flex flex-column">
                                        <span className="bold">Nombre del usuario</span>
                                        <span>{item && item.creador && item.creador.nombres && item.creador.tipo_usuario_text ? `${item.creador.nombres} - ${item.creador.tipo_usuario_text}` : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-3 px-0 mb-3">
                            <span className="text-sm azul-claro bold">Mensaje</span>
                            <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                        </div>
                        <div className="col-12 mb-3 px-0 mb-3">
                            <div className="d-flex flex-column">
                                <span className="text-sm bold">Titulo</span>
                                <span className="text-sm">{item && item.titulo ? item.titulo : ""}</span>
                            </div>
                        </div>
                        {
                            item && item.imagen && (
                            <div className="col-12 mb-3 px-0 mb-3">
                                <img src={item && item.imagen ? item.imagen : ""} alt="Imagen" className="img-fluid"/>
                            </div>
                        )}    
                        <div className="col-12 mb-3 px-0 mb-3">
                            <div className="d-flex flex-column">
                                <span className="text-sm bold">Contenido</span>
                                <p className="">{item && item.mensaje ? item.mensaje : ""}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center align-items-center mt-3">
                            <div className="col-md-3 col-12">
                                <Link className="btn btn-secondary btn-block" to="/comunicados_alumno">Regresar</Link>
                            </div>
                        </div>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
