import React from  'react';
import { DatePicker, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'antd/dist/antd.css';


const MontPicker = (props) => {
    const { onChange, disabled, placeholder, value, allowClear} = props;
    return (
        <DatePicker
            onChange={onChange}
            picker="month"
            locale={locale}
            allowClear={allowClear ? allowClear : false}
            disabled={disabled ? disabled : false}
            placeholder={placeholder ? placeholder : 'Seleccionar'}
            className="month-picker"
            format="MMMM/yyyy"
            value={value ? value : null}
        />
    )
}

export default MontPicker;
