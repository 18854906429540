import React, { Fragment} from 'react';
import { TIPOS_EVENTOS } from '../../../../utility/constants';
import _ from "lodash";
import moment from 'moment';

const conTemario = require('../../../../../assets/img/iconos/con-temario2.png');
const sinTemario = require('../../../../../assets/img/iconos/sin-temario2.png');

export function CardEventos(props) {
    const { index, seleccionar, item } = props;
    const tipo = _.find(TIPOS_EVENTOS, {value: item.tipo_evento});
    return (
        <Fragment>
            <div
                className={`py-2 card-evento w-100 mb-3`}
                style={{cursor: 'pointer', height: '4.5rem', borderColor: tipo ? tipo.color: '#FFF'}}
                onClick={() => seleccionar && seleccionar(item, index)}
            >
                <div className="card-evento-borde" style={{backgroundColor: tipo ? tipo.color: '#FFF'}}></div>
                <div className="d-flex flex-3 flex-lg-4 align-items-center justify-content-center ">
                    <span className="bold gris-oscuro px-4 card-evento-titulo">{item.titulo}</span>
                </div>
                <div className="d-flex flex-2 flex-column align-items-start justify-content-center">
                    <span className="bold gris-oscuro card-evento-subtitulo">Fecha:</span>
                    <span className="card-evento-texto">{moment(item.fecha_hora_inicio).format("DD/MM/YYYY")}</span>
                </div>
                <div className="d-flex flex-2 flex-column align-items-start justify-content-center">
                    <span className="bold gris-oscuro card-evento-subtitulo">Hora inicio:</span>
                    <span className="card-evento-texto">{moment(item.fecha_hora_inicio).format("HH:mm")}</span>
                </div>
                <div className="d-flex flex-2 flex-column align-items-start justify-content-center">
                    <span className="bold gris-oscuro card-evento-subtitulo">Hora fin:</span>
                    <span className="card-evento-texto">{moment(item.fecha_hora_fin).format("HH:mm")}</span>
                </div>
                <div className="d-flex flex-1 flex-column align-items-center justify-content-center">
                    {
                        (item) && (item.contenido) ? (
                            <img src={conTemario} style={{width: "2.5rem"}} />
                        ): (
                            <img src={sinTemario} style={{width: "2.5rem"}} />
                        )
                    }
                </div>
            </div>
        </Fragment>

    )
}

export default CardEventos;
