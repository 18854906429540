import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';


class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    render() {
        const { id, ver, editar, eliminar, asignar, row, biblioteca, calificar } = this.props;

        return (
            <div className="d-flex justify-content-center">
                {(ver !== undefined) && (
                    <Link to={`${ver}/${id}/`} className="pr-1" title="Ver" ><i className="material-icons ver-action">visibility</i></Link>
                )}
                {(editar !== undefined) && (
                    <Link className="px-1" to={`${editar}/${id}/editar`} title="Editar" ><i className="material-icons verde editar-action">edit</i></Link>
                )}
                {(calificar !== undefined) && (
                    <Link className="px-1" to={`${calificar}/${id}/calificar`} title="Calificar" ><i className="material-icons verde editar-action">spellcheck</i></Link>
                )}
                {(asignar !== undefined && !row.activar_plataforma) && (
                    <Link className="px-1" to={`${asignar}/${id}/asignar`} title="Asignar" ><i className="material-icons azul-claro asignar-action">perm_contact_calendar</i></Link>
                )}
                {(eliminar !== undefined) && (
                    <a className="px-1" style={{cursor: "pointer", color: "#c4183c"}} title="Eliminar" onClick={this.eliminar(id)}><i className="material-icons azul-marino eliminar-action">clear</i></a>
                )}
                {(biblioteca !== undefined) && (
                    <Link className="pl-1" to={`${biblioteca}/${id}`} title="Biblioteca" ><i className="material-icons azul-claro asignar-action">bookmarks</i></Link>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} {...acciones} row={row}/> )
    };
}
