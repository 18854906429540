import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import moment from 'moment';

export default class AlumnosProfesorGrid extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12 mt-4">
                <Header {...this.props} />
                <Card>
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            isKey
                            width='20%'
                            dataFormat={standardActions({ ver: "alumnos" })}
                        />
                        <TableHeaderColumn
                            dataField="nombres"
                            dataSort
                        >
                            Nombre del Alumno
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                        >
                            Código
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
