import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray, } from 'redux-form';
import {AsyncSelectField, renderField, SelectField, renderCurrency, renderNumber, renderPasswordField, renderTimeField, renderCodigoPostal} from '../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";
import { generos } from "../../../../utility/constants";

let PasoUnoForm = props => {
    const { handleSubmit, setStep, ver_password, change_ver_password, previousStep } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="col-12">
                    <span className="text-sm azul-claro bold">Datos personales</span>
                    <div className="d-flex w-100 bg-gris-oscuro" style={{height: '3px'}}/>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombres" className="m-0">Nombres *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombres" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="apellidos" className="m-0">Apellidos *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="apellidos" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="telefono" className="m-0">Telefono *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="telefono" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion" className="m-0">Dirección *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="poblacion" className="m-0">Población (Ciudad o lugar de vivienda) *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="poblacion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="codigo_postal" className="m-0">Código Postal *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="codigo_postal" component={renderCodigoPostal} type="number" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end px-0 mt-3">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="horario_contacto" className="m-0">Horario Preferido de Llamada *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="horario_contacto" component={renderTimeField} />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nif" className="m-0">Facturación (CIF, DNI O NIE) *</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nif" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12 text-right">
                    <span className="azul-marino text-xsm">* Campos requeridos</span>
                </div>
                <div className="buttons-box mt-3">
                    <div className="col-md-8 col-12 row">
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <button className="btn btn-secondary btn-block" onClick={previousStep}>Regresar</button>
                        </div>
                        <div className="col-md-6 col-12 mb-sm-0 mb-3">
                            <button type="submit" className="btn btn-primary btn-block">Siguiente</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

PasoUnoForm = reduxForm({
    form: 'AlumnoRegistroForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'nombres': validators.exists()('Campo requerido.'),
            'apellidos': validators.exists()('Campo requerido.'),
            'telefono': validators.exists()('Campo requerido.'),
            'direccion': validators.exists()('Campo requerido.'),
            'poblacion': validators.exists()('Campo requerido.'),
            'codigo_postal': validators.exists()('Campo requerido.'),
            'nif': validators.exists()('Campo requerido.'),
            'horario_contacto': validators.exists()('Campo requerido.'),
        })
    }
})(PasoUnoForm);

export default PasoUnoForm;
