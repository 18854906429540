import React, { Fragment} from 'react';


function Card(props) {
    const { noBorder, noPadding } = props;
    return (
        <Fragment>
            <div className={`mb-4 ${noBorder ? '' : ' card-tabla card-small'}`}>
                <div className={`card-content ${noPadding ? ' p-0 ' : ' p-0 px-3 pt-3 '}`}>
                    {props.children}
                </div>
            </div>
        </Fragment>

    )
}

export default Card;
