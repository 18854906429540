import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { actions } from '../../../../../redux/modules/alumnos/formularios';
import PrecargarExamen from './PrecargaExamen';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.formsAlumno,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(withRouter(PrecargarExamen));
