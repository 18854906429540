import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import CardForm from 'Utils/Cards/CardForm';

const iconoKO = require("../../../../../../assets/img/iconos/icono-ko.png");

const pago = "Parece que hubo un error al realizar el pago";
const tarjeta = "Parece que hubo un error al registrar la tarjeta";

export default class VistaKO extends Component {


    render() {
        const url = window.location.href;
        return (
            <div className="mb-4 col-12 mt-4">
                <CardForm>
                    <div className="d-flex flex-column flex-1 w-100 h-100">
                        <div className="d-flex flex-1 align-items-center justify-content-center">
                            <img style={{width: "25%"}} src={iconoKO} alt="OK" />
                        </div>
                        <div className="d-flex flex-1 align-items-center justify-content-center my-5">
                            <h1 className="bold azul-claro text-center">{url.includes("pago_fallido") ? pago : tarjeta}</h1>
                        </div>
                        <div className="d-flex flex-1 align-items-center justify-content-center">
                            <Link className="btn btn-primary" to={url.includes("pago_fallido") ? "/pasarela" : "/mis_tarjetas"} >Volver a intentar</Link>
                        </div>
                    </div>
                </CardForm>
            </div>
        )
    }
}
