import React, {Component, Fragment} from 'react';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";

import moment from 'moment';
import { ARCHIVO } from '../../../../../utility/constants';

const descargar = require("../../../../../../assets/img/iconos/descargar.png");
const link = require("../../../../../../assets/img/iconos/link.png");

export default class FormsGrid extends Component{

    componentWillMount() {
        const id = this.props.match.params.id;
        this.props.leer(id);
        this.props.listarForms(1, id);
    }

    onPageChange = (page) => {
        const id = this.props.match.params.id;
        this.props.listarForms(page, id);
    };

    searchChange = (search) => {
        const id = this.props.match.params.id;
        this.props.searchChange2(search, id);
    };

    eliminar = (id) => {
        const idAlumno = this.props.match.params.id;
        this.props.eliminarForm(id, idAlumno)
    }

    render() {
        const { data2, loader, page2, search2, item } = this.props;
        return(
            <div className="mb-4 col-12 mt-4">
                <h1 className="azul-claro bold">{item.nombres}</h1>
                <div className="mt-4">
                    <Header
                        {...this.props}
                        to="formulario_asignar" textBtn="Enviar nuevo ejercicio o examen"
                        searchChange={this.searchChange}
                        search={search2}
                    />
                    <Card>
                        <Grid
                            hover striped
                            data={data2}
                            loading={loader}
                            onPageChange={this.onPageChange}
                            page={page2}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                width='10%'
                                dataFormat={standardActions({ eliminar: this.eliminar })}
                            />
                            <TableHeaderColumn
                                dataField="formulario"
                                dataFormat={ (cell) => `${cell.titulo}`}
                            >
                                NOMBRE
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="formulario"
                                dataFormat={ cell => cell.tipo_text}
                            >
                                TIPO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="creado"
                                dataFormat={ cell => moment(cell).format("DD/MM/YYYY") }
                            >
                                FECHA DE ASIGNACIÓN
                            </TableHeaderColumn>
                        </Grid>
                    </Card>
                </div>
            </div>
        )
    }
}
