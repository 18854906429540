import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/cursos/creditosAlumno';
import ReporteCreditosAlumno from './CreditosAlumno';


const ms2p = (state) => {
    return {
        ...state.creditosAlumno,
        me: state.login.me
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteCreditosAlumno);
