import React, {Component, Fragment} from 'react';
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";

import moment from 'moment';

export default class AlumnosGrid extends Component{

    componentWillMount() {
        const id = this.props.match.params.id;
        this.props.listarAlumnos(1, id);
    }

    onPageChange = (page) => {
        const id = this.props.match.params.id;
        this.props.listarAlumnos(page, id);
    };

    searchChange = (search) => {
        const id = this.props.match.params.id;
        this.props.searchChange3(search, id);
    };

    render() {
        // const {  } = this.props; //Funciones
        const { data3, loader, page3, search3, item } = this.props; //Constantes
        return(
            <div className="mt-4 px-1">
                <Header
                    {...this.props}
                    searchChange={this.searchChange}
                    search={search3}
                />
                <Card>
                    <Grid
                        hover striped
                        data={data3}
                        loading={loader}
                        onPageChange={this.onPageChange}
                        // onSortChange={onSortChange}
                        page={page3}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            isKey
                            width='10%'
                            dataFormat={standardActions({})}
                        />
                        <TableHeaderColumn
                            dataField="alumno"
                            dataFormat={ cell => cell.nombres}
                        >
                            NOMBRE DEL ALUMNO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="alumno"
                            dataFormat={ cell => cell.id}
                        >
                            CÓDIGO DEl ALUMNO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="alumno"
                            dataAlign="right"
                            thStyle={{paddingRight: '5%'}}
                            tdStyle={{paddingRight: '5%'}}
                            dataFormat={ cell => 0 }
                        >
                            PUNTAJE TOTAL
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
