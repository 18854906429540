import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/alumnos/formularios';
import VerSolucionForm from './VerSolucionForm';


const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.formsAlumno,
        form: state.form.FormularioForm
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(VerSolucionForm);
