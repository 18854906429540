import React, { Component } from 'react';
import Chart from "react-google-charts";
import { Async } from 'react-select';
import classNames from 'classnames';
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import Card from "Utils/Cards/Card"
import MonthPicker from '../../../Utils/MonthPicker';
import HeaderFiltros from '../../../Utils/Grid/HeaderFiltros';
import { USER_ALUMNO } from '../../../../../utility/constants';
import moment from 'moment';

import { api } from "api";
import LoadMask from '../../../Utils/LoadMask/LoadMask';

export default class MiAvance extends Component {

    componentWillMount() {
        this.props.miAvance();
    }

    componentDidUpdate(prevProps) {
        if (this.props.alumno !== prevProps.alumno) {
            this.getCursos('');
        }
    }

    componentWillUnmount() {
        this.props.resetearData();
    }

    getCursos = search => {
        return api.get("aula/cursos_alumnos", { search }).then((data) => {
            if (data) return data.results
            return [];
        }).catch(() => {
            return [];
        });
    };

    changeFechaInicio = (value) => {
        const { fecha_fin } = this.props;
        if (value <= fecha_fin) {
            const comprobacion = moment(fecha_fin).subtract(2, 'y')
            if (value >= comprobacion){
                this.props.changeFechaInicio(value);
            }
        }
    }

    changeFechaFin = (value) => {
        const { fecha_inicio } = this.props;
        if (value >= fecha_inicio) {
            const comprobacion = moment(fecha_inicio).add(2, 'y')
            if (value <= comprobacion){
                this.props.changeFechaFin(value);
            }
        }
    }

    render() {
        const { listarTabla, listarGrafica } = this.props; //Funciones
        const { loader, data, data2 } = this.props; //Constantes

        return (
            <div className="mb-4 col-12 mt-4">
                <HeaderFiltros
                    filtro1={
                        <Async
                            isClearable={true}
                            backspaceRemovesValue={false}
                            value={this.props.aula}
                            isSearchable={true}
                            loadOptions={this.getCursos}
                            getOptionValue={(option) => (option["id"])}
                            getOptionLabel={(option) => (option["nombre"])}
                            type="text"
                            onChange={(e) => this.props.changeAula(e)}
                            multi={false}
                            autoload={false}
                            cache={false}
                            className={classNames('react-select-container w-100')}
                            defaultOptions={true}
                            placeholder="Filtrar por curso.."
                        />
                    }
                    filtro2={
                        <MonthPicker
                            value={this.props.fecha_inicio}
                            onChange={this.changeFechaInicio}
                            placeholder="Fecha inicio"
                        />
                    }
                    filtro3={
                        <MonthPicker
                            value={this.props.fecha_fin}
                            onChange={this.changeFechaFin}
                            placeholder="Fecha inicio"
                        />
                    }
                />
                <Card>
                    <div className="d-flex flex-row flex-1 mt-3 mb-2">
                        <Chart
                            width='100%'
                            height={'50vh'}
                            chartType="LineChart"
                            loader={<div>Cargando grafica</div>}
                            data={data}
                            options={{
                                hAxis: {
                                    title: 'Meses',
                                    baselineColor: "#1C375E",
                                    titleTextStyle: {
                                        color: '#1C375E',
                                        bold: true,
                                        italic: false
                                    },
                                    textStyle: {
                                        color: "#1C375E",
                                        bold: false,
                                        italic: false
                                    },
                                },
                                vAxis: {
                                    title: 'Créditos',
                                    position: 'top',
                                    baselineColor: "#1C375E",
                                    titleTextStyle: {
                                        color: '#1C375E',
                                        bold: true,
                                        italic: false
                                    },
                                    textStyle: {
                                        color: "#1C375E",
                                        bold: false,
                                        italic: false
                                    },
                                    minValue: 0,
                                },
                                pointSize: 7,
                                curveType: 'function',
                                chartArea: { width: '90%', height: '80%' },
                                legend: { position: 'none' },
                            }}
                            rootProps={{ 'data-testid': '2' }}
                        />
                    </div>
                    <div className="my-5 px-lg-3">
                        <Grid
                            hover
                            striped
                            data={{ results: data2, count: 1 }}
                            loading={loader}
                            pagination={false}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                width='5%'
                                dataFormat={cell => ''}
                            />
                            <TableHeaderColumn
                                dataField="nombre"
                            >
                                Curso
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="profesor"
                                dataFormat={cell => cell ? cell.titulo : 'Sin profesor'}
                            >
                                Profesor
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha"
                                dataFormat={cell => moment(cell).format('MMMM/YYYY')}
                            >
                                Fecha
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="creditos"
                            >
                                Créditos
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </Card>
            </div>
        )
    }


}

